import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth-service.service';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  logoutForm: FormGroup;
  showLoader: boolean = false;
  statusMessage: string;

  constructor(private fb: FormBuilder, private authService: AuthService, private storageService: StorageService, private router: Router,) {}

  ngOnInit() {
    this.logoutForm = this.fb.group({
    });
  }

  onSubmit() {
    this.showLoader = true;
    try {
      this.authService
        .userLogout()
        .then((response) => {
          if (response && response.Status == "Success") {
            this.showLoader = false;
            this.router.navigate(['/auth/login']);
          }else{
            this.showLoader = false;
            this.router.navigate(['/']);
          }
        });
      }catch(e) {
        this.showLoader = false;
        console.error('forgotPassword Error: ', e.errorMessage);
        this.statusMessage = "Internal Server Error; Please try again."
      }
  }

  cancel() {
    this.router.navigate(['/']);
  }
}

