export class Roles{
    id: number;
    name: string;
    isDisplayed: boolean;
  }

  
export class User {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    roleName: string;
    isMain: boolean;
    userCulture: string;
    profilePicture: any;

    constructor(email?: string) {
        this.email = email;
    }
}


export interface ApplicationUser {
    Username: string;
    Message:string;
    ViewingPage:string;
    AccessToken:string;
    RefreshToken:string;
  }
  