import { Component } from '@angular/core';

@Component({
  selector: 'app-lockout',
  templateUrl: './lockout.component.html',
  styleUrls: ['./lockout.component.scss']
})
export class LockoutComponent {
  title: string = "Locked out";
  message: string = "This account has been locked out, please try again later.";
}

