import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusMessageComponent } from './status-message/status-message.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordConfirmationComponent } from './forgot-password-confirmation/forgot-password-confirmation.component';
import { LockoutComponent } from './lockout/lockout.component';
import { LogoutComponent } from './logout/logout.component';
import { MessagesModule } from 'primeng/messages';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginWithTwofaComponent } from './login-with-twofa/login-with-twofa.component';
import { LoginWithRecoveryCodeComponent } from './login-with-recovery-code/login-with-recovery-code.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { PasswordChangedSuccessComponent } from './password-changed-success/password-changed-success.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordConfirmationComponent } from './reset-password-confirmation/reset-password-confirmation.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SignedOutComponent } from './signed-out/signed-out.component';
import { MessageModule } from 'primeng/message';
import { AuthenticationComponent } from './authentication.component';
import { LoaderComponent } from './loader/loader.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthHttpInterceptor } from 'src/app/shared/services/auth-http-interceptor.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MessagesModule,
    InputTextModule,
    CheckboxModule,
    DropdownModule,
    ButtonModule,
    MessageModule,
    AuthenticationRoutingModule,    
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true }, 
  ],
  declarations: [
    AuthenticationComponent,
    LoaderComponent,
    StatusMessageComponent,
    ChangePasswordComponent,
    LoginComponent,
    ConfirmEmailComponent,
    ExternalLoginComponent,
    ForgotPasswordComponent,
    ForgotPasswordConfirmationComponent,
    LockoutComponent,
    LogoutComponent,
    LoginWithTwofaComponent,
    LoginWithRecoveryCodeComponent,
    LoggedOutComponent,
    PasswordChangedSuccessComponent,
    RegisterComponent,
    ResetPasswordComponent,
    ResetPasswordConfirmationComponent,
    ResetPasswordSuccessComponent,
    SetPasswordComponent,
    SignedOutComponent
  ]
})
export class AuthenticationModule { }
