import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-with-recovery-code',
  templateUrl: './login-with-recovery-code.component.html',
  styleUrls: ['./login-with-recovery-code.component.scss']
})
export class LoginWithRecoveryCodeComponent implements OnInit {
  recoveryForm: FormGroup;
  errorMessage: string | null = null;
  submitted = false;

  constructor(private fb: FormBuilder, private router: Router) {}

  ngOnInit() {
    this.recoveryForm = this.fb.group({
      recoveryCode: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.recoveryForm.valid) {
      const formData = this.recoveryForm.value;
      console.log('Logging in with recovery code:', formData.recoveryCode);
      // Perform the login logic (e.g., call an authentication service)

      // If login is successful, navigate to the desired route
      // this.router.navigate(['/desired-route']);
    } else {
      this.errorMessage = "Please enter a valid recovery code.";
    }
  }
}
