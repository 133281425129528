import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  public isUserValid: boolean;

  data: any;

  saveJsonData(key: string, data: any): void {
    localStorage.setItem(key, JSON.stringify(data));
  }

  saveData(key: string, data: any): void {
    localStorage.setItem(key, data);
  }

  getData(key: string): any {
    this.data = localStorage.getItem(key);
    return this.data;
  }

  getJsonData(key: string): any {
    this.data = localStorage.getItem(key);

    if (this.data === 'undefined') {
      return this.data;
    }
    return JSON.parse(this.data);
  }

  removeAll(): void {
    localStorage.clear();
  }

  clearAllExceptRememberMe() {
    const rememberMe = localStorage.getItem('rememberMe'); 
    const username = localStorage.getItem('username') || '';
    const password = localStorage.getItem('password') || '';

    localStorage.clear(); // Clear all local storage data
    
    if (rememberMe === 'true') {
      localStorage.setItem('rememberMe', rememberMe);
      localStorage.setItem('username', username);
      localStorage.setItem('password', password);
    }
  }
}
