import { CtlsTaskListingService } from './../../../../ctls-shared/ctls-services/ctls-task-listing.service';
import { CtlsCommentPopoverComponent } from './../ctls-comment-popover/ctls-comment-popover.component';
import { CtlsFilterHeaderService } from './../../../../ctls-shared/ctls-services/ctls-filter-header.service';
import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ConfirmationService, Message } from 'primeng/components/common/api';
import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { Table } from 'primeng/table';
import * as _ from 'lodash';
import { CtlsCommonService } from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import { takeUntil } from 'rxjs/operators';
import { MultiSelect } from 'primeng/multiselect';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-ctls-task-listing-table',
  templateUrl: './ctls-task-listing-table.component.html',
  styleUrls: ['./ctls-task-listing-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CtlsTaskListingTableComponent implements OnChanges, OnDestroy {
  @ViewChildren(CtlsCommentPopoverComponent)
  private popoverlay: QueryList<CtlsCommentPopoverComponent>;
  @ViewChildren('commentTeaxtArea')
  private commentFocus: QueryList<ElementRef>;
  @ViewChild('filterFieldDropdown') filterFieldDropdown: Dropdown;
  @ViewChild('dt6', { read: Table }) table: Table;
  @ViewChild('refMulSel') refMulSel: MultiSelect
  toggleMulSel: boolean = false;
  @ViewChild(Table) tableComponent: Table;
  @Input() selectedRadioOption: number;
  @Input() objTaskListingResponse: any;
  @Input() objModifiedColumnResponse: any;
  @Input() arrModifiedColumnResponse: any[];
  @Input() filterObj: any;
  @Input() resetCompanyFilter: any;
  @Input() objClosedResponse: any;
  @Input() blnCreateTaskpopupClose: any;
  @Input() strSurveyInstance = '';
  @Input() strSurveyName = '';
  @Input() objTaskResponse: any;
  @Input() copyObjModifiedColumnResponse: any;
  @Input() objStatusRresponse: any;
  @Input() blnSearchStatusFlag: any;
  @Input() objSaveResponse: any;
  @Input() sequenceModifiedResponse: any;
  @Input() assignedTask: any;
  @Input() error: any;
  @Output()
  eventFromPresentationTable: EventEmitter<any> = new EventEmitter<any>();

  blnItemArrayFlag = false;
  rowData2 = [];
  surveyGridSub: Subscription;
  selectedFilterVal: any = null;
  cols: any[];
  colsForSearch: any[];
  searchableCols: any[];
  rowData: any[];
  totalRecords: number;
  configRow: number[] = [10, 20, 30, 40, 50];
  showCategoryList = false;
  eventDataLoader: boolean = false;
  selectedCompanyId: string[] = [];
  isMultiSelect = false;
  successMsg = false;
  msgs: Message[] = [];
  // MessageTimeOut = this.sharedService.configuration.MessageTimeOut; // TODO - take from API Response
  MessageTimeOut = 10000;
  genericErrorMessage = 'An error occurred, please contact '; // +    this.sharedService.configuration.ContactUsEmail;
  filterString: any;
  nosearchresults: string = '';
  isDataAvailable = false;
  columnLength: number = 11;
  disabled = true;
  searchPlaceholder: string = 'Select field to search';
  searchText: any[] = [];
  searchField: string;
  searchFieldLabel: string;
  selectedSearchCategory = false;
  totalSelectedRecords: number = 0;
  clearSearchShow = false;
  isSearchApplied = false;
  closeDropDownIcon = true;
  pinnedColBtnEnabled = false;
  copyToClipboardEnabled = false;
  UnselectedIDs: any = [];
  selectedColumns: any[] = [];
  searchableColumns: string[] = ['BA', 'FAM', 'Sample'];
  selectAllCheckBoxValue = false;
  selectedRows = [];
  taskProceed = false;
  currentDate: any;
  checkArray: any[] = [];
  navFilter = {
    ErrorCode: null,
    FilterFields: [],
  };
  multiColSelected: boolean;
  errorRecordSelected: boolean;
  showDialog = false;
  isDeleteEnabled: boolean;
  bulkUpdateTooltip =
    'Please use Copy Grid feature to update the status and/or comments in bulk. Task ID, Task , Status and Comments are default fields and need to be included. Task ID and Task should not be modified.';
  destroy$ = new Subject();
  seqNum: number = 0;
  maxSeqNum: number = 15;
  modifyLimit: number;
  userRole: boolean;
  modifyLimitLabel: number;
  objToolTip = {
    strHasTime: "Time left for SDA breach. Please click to refresh the information.",
    strBan: "SDA not applicable.",
    strOpnInProgssHldBreach: "SDA breached.",
    strResolvedTime: "Task resolved before SDA breached.",
    strClosedTime: "Task closed before SDA breached.",
    strResolvedBreached: "Task resolved after SDA breached.",
    strClosedBreached: "Task closed after SDA breached.",
  }

  IsMultipleFAMBADIV: any;
  collapseData: boolean;

  // crumbText: string = BreadCrumbs.manageMode;
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public route: ActivatedRoute,
    public router: Router,
    private filterService: CtlsFilterHeaderService,
    private commonService: CtlsCommonService,
    private confirmationService: ConfirmationService,
    private ctlsTaskListingService: CtlsTaskListingService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.getToaster();
  }
  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (!!this.assignedTask) {
      if (this.selectedRadioOption == 1) {
        this.modifyLimit = 9;
        this.modifyLimitLabel = 15;
      } else {
        this.modifyLimit = 9;
        this.modifyLimitLabel = 15;
      }
    } else {
      if (this.selectedRadioOption == 1) {
        this.modifyLimit = 9;
        this.modifyLimitLabel = 12;
      } else {
        this.modifyLimit = 10;
        this.modifyLimitLabel = 13;
      }
    }

    if (
      changes.selectedRadioOption &&
      changes.selectedRadioOption.currentValue
    ) {
      this.selectAllCheckBoxValue = false;
      this.selectedFilterVal = null;
      this.resetSearchFilterFields();
    }
    if (
      !!changes.objTaskListingResponse &&
      !changes.objModifiedColumnResponse
    ) {
      this.handleGridFromResponse(this.objTaskListingResponse);

      if (!!this.table.sortField && !!this.table.sortOrder) {
        this.customSort({
          first: 0,
          rows: 100,
          sortField: this.table.sortField,
          sortOrder: this.table.sortOrder,
        });
      }
    }

    if (
      !!changes.objTaskListingResponse &&
      !!changes.objModifiedColumnResponse
    ) {
      this.selectedColumns = this.objModifiedColumnResponse;

      this.handleGridFromResponse(this.objTaskListingResponse);
      if (!!this.table.sortField && !!this.table.sortOrder) {
        this.customSort({
          first: 0,
          rows: 100,
          sortField: this.table.sortField,
          sortOrder: this.table.sortOrder,
        });
      }
    }

    if (
      !!this.objTaskListingResponse &&
      !!changes.objModifiedColumnResponse
    ) {
      this.selectedColumns = this.objModifiedColumnResponse;

      this.handleGridFromResponse(this.objTaskListingResponse);
      if (!!this.table.sortField && !!this.table.sortOrder) {
        this.customSort({
          first: 0,
          rows: 100,
          sortField: this.table.sortField,
          sortOrder: this.table.sortOrder,
        });
      }
    }

    if (window.screen.width <= 700) {
      this.selectedColumns = [...this.cols];
      this.userSelectedColumns(this.cols, false);
    }

    if (changes.objStatusRresponse) {
      if (!!this.objStatusRresponse) {
        if (!!this.objStatusRresponse.Response) {
          this.successMsg = true;
          this.callErrorMsg('success', '', this.objStatusRresponse.Status);
          this.objStatusRresponse = '';
        } else if (!this.objStatusRresponse.Response) {
          this.successMsg = true;
          this.callErrorMsg('error', '', this.objStatusRresponse.Status);
          this.objStatusRresponse = '';
        }
      }
    }
    if (changes.objSaveResponse) {
      if (!!this.objSaveResponse) {
        if (!!this.objSaveResponse.Response) {
          this.successMsg = true;
          this.callErrorMsg('success', '', this.objSaveResponse.Response);
          this.objSaveResponse = '';
        } else if (!this.objSaveResponse.Response) {
          this.successMsg = true;
          this.callErrorMsg('error', '', this.objSaveResponse.Response);
          this.objSaveResponse = '';
        }
      }
    }
    if (
      changes.sequenceModifiedResponse &&
      changes.sequenceModifiedResponse.currentValue
    ) {
      if (
        this.sequenceModifiedResponse.Response &&
        this.sequenceModifiedResponse.Status == 'SUCCESS'
      ) {
        let sequencedId = this.sequenceModifiedResponse.Response;
        if (!!sequencedId && sequencedId.length) {
          sequencedId.map((item) => {
            this.selectedRows.push(
              _.find(this.rowData2, (row) => row.TaskID == item.CTLSTaskListID)
            );
          });
          this.selectRow('', '');
        }
      } else {
        this.successMsg = true;
        this.callErrorMsg('error', '', this.sequenceModifiedResponse.Response);
      }
    }
  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.surveyGridSub && !this.surveyGridSub.closed)
      this.surveyGridSub.unsubscribe();
  }
  /**
   * Get toaster through service
   */
  getToaster() {
    this.commonService
      .getToaster()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (!!res) {
          switch (res.type) {
            case 'Success': {
              if (!!res && !!res.data && !!res.data.message) {
                window.scroll(0, 200);
                this.successMsg = true;
                this.callErrorMsg('success', '', res.data.message, true);
              }
              if (!!res && !!res.data && !!res.data.event) {
                if (res.data.event == 'GET_UPDATED_TASK_LIST') {
                  this.eventFromPresentationTable.emit({
                    operation: 'GET_UPDATED_TASK_LIST',
                  });
                }
              }
              break;
            }
            case 'Error': {
              if (!!res && !!res.data && !!res.data.message) {
                window.scroll(0, 200);
                this.successMsg = true;
                this.callErrorMsg('error', '', res.data.message, true);
              }
              break;
            }
          }
        }
      });
  }
  /**
   * Copy to clipboard
   */
  copyClipboard() {
    this.eventDataLoader = false;
    this.copyToClipboardEnabled = true;
    let objTaskSurveyItems = [];
    this.copyObjModifiedColumnResponse = _.reject(this.copyObjModifiedColumnResponse, (item) => {
      return (
        item && item.field && item.field === 'TaskID'
      );
    });
    if (!!this.selectedRows && this.selectedRows.length) {
      objTaskSurveyItems = !!this.selectedRows ? this.selectedRows : null;
    } else {
      objTaskSurveyItems =
        !!this.objTaskListingResponse && !!this.objTaskListingResponse.Items
          ? this.objTaskListingResponse.Items
          : null;
    }
    if (!objTaskSurveyItems) {
      this.eventDataLoader = true;
      this.successMsg = true;
      this.callErrorMsg('error', '', 'There is no data in grid to copy');
      return;
    }

    if (!!this.assignedTask) {
      var objModifiedItemResponse = this.gridDataBasedOnCurrentColumn(
        objTaskSurveyItems,
        [
          ...['TaskID', 'Task', 'Status', 'ResolveWithin', 'Comments', 'SurveyID'],
          ...this.arrModifiedColumnResponse,
        ]
      );

      var objGrid = {
        header: [
          ...[
            { field: 'TaskID', header: 'Task ID' },
            { field: 'Task', header: 'Task' },
            { field: 'Status', header: 'Status' },
            { field: "ResolveWithin", header: "Resolve Within" },
            { field: 'Comments', header: 'Comments' },
            { field: 'SurveyID', header: 'Survey ID' }
          ],
          ...this.copyObjModifiedColumnResponse,
        ],
        items: objModifiedItemResponse,
        blnChangeStatusToActiveDeactive: false,
      };
    } else {
      var objModifiedItemResponse = this.gridDataBasedOnCurrentColumn(
        objTaskSurveyItems,
        [
          ...['TaskID', 'Task', 'SurveyID'],
          ...this.arrModifiedColumnResponse,
        ]
      );
      var objGrid = {
        header: [
          ...[
            { field: 'TaskID', header: 'Task ID' },
            { field: 'Task', header: 'Task' },
            { field: 'SurveyID', header: 'Survey ID' },
          ],
          ...this.copyObjModifiedColumnResponse,
        ],
        items: objModifiedItemResponse,
        blnChangeStatusToActiveDeactive: false,
      };
    }
    this.successMsg = true;
    this.copyToClipboardEnabled = false;
    this.commonService
      .copy2DToClipboard(objGrid)
      .then((res) => {
        if (!!res) {
          this.eventDataLoader = true;
          this.successMsg = true;
          this.callErrorMsg(
            'success',
            '',
            objModifiedItemResponse.length + ' row(s) copied successfully.'
          );
          objModifiedItemResponse = [];
        } else {
          this.eventDataLoader = true;
          this.successMsg = true;

          this.callErrorMsg('error', '', 'Failed to copy.');
        }
      })
      .catch((error) => {
        this.eventDataLoader = true;
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Failed to copy.');
      });
  }

  /**
   * select grid data based on  current column
   */
  gridDataBasedOnCurrentColumn(
    objTaskListingResponse,
    arrModifiedColumnResponse
  ) {
    let objModifiedItemResponse = [];
    objTaskListingResponse.forEach((item) => {
      let objCopyItemResponse = {};
      arrModifiedColumnResponse.forEach((element) => {
        let result = _.pickBy(item, (value, key) => {
          if (element === 'TaskID') {
            return key === 'CTLSTaskID';
          }
          if (element === 'Task') {
            return key === 'TaskTitle';
          }
          return key === element;
        });
        objCopyItemResponse = { ...objCopyItemResponse, ...result };
      });
      objModifiedItemResponse.push(objCopyItemResponse);
    });
    return objModifiedItemResponse;
  }

  /**
   * Custom Sort => triggered from onLazyLoad event
   * @param event: LazyLoadEvent
   * event.first = First row offset
   * event.rows = Number of rows per page
   * event.sortField = Field name to sort with
   * event.sortOrder = Sort order as number, 1 for asc and -1 for dec
   * filters: FilterMetadata object having field as key and filter value, filter matchMode as value
   */
  customSort(event: any) {
    if (!!this.rowData) {
      if (!!event.sortField) {
        this.pinnedColBtnEnabled = true;
      }
      let result;
      this.rowData.sort((a, b) => {
        if (!a[event.sortField] && !!b[event.sortField]) {
          result = -1;
        } else if (!!a[event.sortField] && !b[event.sortField]) {
          result = 1;
        } else if (!a[event.sortField] && !b[event.sortField]) {
          result = 0;
        } else if (event.sortField.endsWith('Date')) {
          if (
            typeof a[event.sortField] === 'string' &&
            typeof b[event.sortField] === 'string'
          ) {
            var dateA = new Date(a[event.sortField]);
            var dateB = new Date(b[event.sortField]);
            result = dateA.getTime() - dateB.getTime();
          }
        } else if (
          typeof a[event.sortField] === 'string' &&
          typeof b[event.sortField] === 'string'
        ) {
          result = a[event.sortField].localeCompare(b[event.sortField]);
        } else {
          result =
            a[event.sortField] < b[event.sortField]
              ? -1
              : a[event.sortField] > b[event.sortField]
                ? 1
                : 0;
        }
        return event.sortOrder * result;
      });
      this.rowData2 = this.rowData.slice(event.first, event.first + event.rows);
    }
  }
  /**
   * Request for Survey Data => Emits event to Container
   * operation: GET_TASK_LIST
   * @param filterObj
   */
  requestForTaskList(filterObj) {
    this.eventFromPresentationTable.emit({
      operation: 'GET_TASK_LIST',
      data: filterObj,
      searchArray: this.searchText,
    });
  }
  /**
   * All response from get api
   * @param rawResponse
   */
  private handleGridFromResponse(rawResponse: any) {
    this.seqNum = 0;
    this.nosearchresults = '';
    this.showCategoryList = false;
    if (!!rawResponse) {
      this.isDeleteEnabled = !!rawResponse.IsDeleteEnable;
      this.totalRecords = !!rawResponse.Items ? rawResponse.Items.length : 0;
      this.userRole = rawResponse.UserRole ? rawResponse.UserRole : null;
      this.IsMultipleFAMBADIV = rawResponse.IsMultipleFAMBADIV ? rawResponse.IsMultipleFAMBADIV : null;
      this.eventDataLoader = true;
      this.copyToClipboardEnabled = false;
      const colHeaderData = rawResponse.ItemsHeader.modifiedHeaders
        ? rawResponse.ItemsHeader.modifiedHeadersHeaders
        : rawResponse.ItemsHeader.Headers;
      const ctlsGlobalFilterObject = JSON.parse(
        localStorage.getItem('ctlsGlobalFilterObject')
      );
      const filterKeys = this.filterService.filterObjForGrid;
      let colHeaderDataFoSearch = colHeaderData;
      filterKeys.forEach((element) => {
        colHeaderDataFoSearch = _.reject(colHeaderDataFoSearch, (item) => {
          return element.toLowerCase() === item.field.toLowerCase();
        });
      });
      if (ctlsGlobalFilterObject) {
      }
      this.cols = colHeaderData;
      this.cols = _.reject(this.cols, (item) => {
        return (
          item.field === 'TaskTitle' ||
          // item.field === 'TaskID' ||
          item.field === 'IsActive' ||
          item.field === 'Comments' ||
          item.field === 'SurveyID' ||
          item.field === 'Status' ||
          item.field === 'Assignee' ||
          item.field === 'ModifiedBy' ||
          item.field === 'ResolveWithin'
        );
      });
      this.colsForSearch = _.orderBy(
        colHeaderDataFoSearch,
        ['header'],
        ['asc']
      );
      if (!this.assignedTask) {
        this.colsForSearch = _.reject(this.colsForSearch, (item) => {
          return (
            item.field === 'TaskTitle' ||
            item.field === 'TaskID' ||
            item.field === 'IsActive' ||
            item.field === 'Comments' ||
            item.field === 'SurveyID' ||
            item.field === 'Status' ||
            item.field === 'Assignee' ||
            item.field === 'ModifiedBy'


          );
        });
        this.cols = _.reject(this.cols, (item) => {
          return (
            item.field === 'TaskTitle' ||
            // item.field === 'TaskID' ||
            item.field === 'IsActive' ||
            item.field === 'Comments' ||
            item.field === 'SurveyID' ||
            item.field === 'Status' ||
            item.field === 'Assignee' ||
            item.field === 'ModifiedBy'
          );
        });
      }
      this.rowData = rawResponse.Items;
      this.selectAllCheckBoxValue = false;
      this.selectedRows = [];
      this.rowData.map((item) => {
        item.isSelected = false;
        if (!!item.NPSScore && item.NPSScore >= 0 && item.NPSScore <= 6) {
          item.detractor = true;
        } else if (item.NPSScore >= 7 && item.NPSScore <= 8) {
          item.neutral = true;
        } else if (item.NPSScore >= 9 && item.NPSScore <= 10) {
          item.promotor = true;
        } else if (item.NPSScore == 0) {
          item.detractor = true;
        }
      });
      this.rowData.forEach((element) => {
        // for inline edit comment and status
        element.InitialStatus = element.CurrentStatus;
        element.currentComment = '';
        element.commentRequired = false;
        element.DymanicStatusResponse.push(element.CurrentStatus);
        if (
          typeof element.Sequence === 'number' &&
          element.Sequence < this.maxSeqNum &&
          !rawResponse.IsFiltered
        )
          this.seqNum += 1;
      });
      if (rawResponse.IsFiltered) {
        this.seqNum = rawResponse.sequencedLength ?
          rawResponse.sequencedLength : 0;
      }
      this.currentDate = new Date();
      this.rowData2 = [];
      if (!!this.rowData && this.rowData.length) {
        this.rowData2 = this.rowData.slice(0, 100);
      }
      
      if (
        (this.getSelectedKeys(this.filterObj.filterFields).length != 0 ||
          this.searchText.length != 0) &&
        this.totalRecords <= 0
      ) {
        this.nosearchresults = "No matching search result/s found.";
        this.isDataAvailable = false;
      } else if (this.totalRecords <= 0) {
        this.nosearchresults = 'No records available.';
        this.isDataAvailable = false;
      } else {
        this.showCategoryList = true;
        this.isDataAvailable = true;
        this.isDataAvailable = false;
        this.successMsg = true;
      }
    } else {
      if(this.error) {
        this.nosearchresults = 'An error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail
      }
    }
  }


  /**
  * returns array of keys consisting of only filtered values
  */
  getSelectedKeys(object) {
    let filterKeysArr = Object.keys(object).filter(key => {
      if ((key === "fromDate" || key === "toDate") && object['periodType'] === "M") {
        return false
      }
      if (key === 'periodType') {
        if (object[key] === "M") {
          return false
        }
      }

      if (!!object[key] &&
        object[key] != null &&
        object[key] != undefined &&
        object[key].length != 0) return true

      return false;
    });

    return filterKeysArr;
  }

  /**
   * Call Default Message => to show a default message
   */
  callDefaultMessage() {
    this.successMsg = true;
    this.callErrorMsg('error', '', this.genericErrorMessage);
    window.scrollTo(0, 0);
  }

  /**
   * Clear Message => triggered from HTML Button Click
   */
  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }

  /**
   * Reset Search Filter Fields
   */
  private resetSearchFilterFields() {
    this.filterObj.filterFields.surveyID = '';
    this.filterObj.filterFields.contactName = '';
    this.filterObj.filterFields.orderNo = '';
    this.filterObj.filterFields.nPSScore = '';
    this.filterObj.filterFields.nPSComment = '';
    this.filterObj.filterFields.influenceLevel = '';
    this.filterObj.filterFields.emailAddress = '';
    this.filterObj.filterFields.objective = '';
    this.filterObj.filterFields.event = '';
    this.filterObj.filterFields.category = '';
    this.filterObj.filterFields.distributorshipManagerSales = '';
    this.filterObj.filterFields.distributorshipManagerService = '';
    this.filterObj.filterFields.modifiedBy = '';
    this.filterObj.filterFields.comments = '';
    this.filterObj.filterFields.taskCount = '';
    this.filterObj.filterFields.cTLSEnabledDate = '';
    this.filterObj.filterFields.cTLSModifiedDate = '';
    this.filterObj.filterFields.feedbackSubmitDate = '';
    //addhere
    this.filterObj.filterFields.TaskTitle = '';
    this.filterObj.filterFields.Status = '';
    this.filterObj.filterFields.TaskID = '';
    this.filterObj.filterFields.Priority = '';
    this.filterObj.filterFields.RecommendedAction = '';
    this.filterObj.filterFields.Branch = '';
    this.filterObj.filterFields.Zone = '';
    this.filterObj.filterFields.Touchpoint = '';
    this.filterObj.filterFields.SalesEngineer = '';
    this.filterObj.filterFields.ServiceTechnician = '';
    this.filterObj.filterFields.Distributor = '';
    this.filterObj.filterFields.DistributorSegments = '';
    this.filterObj.filterFields.DistributorAccountNumbers = '';
    this.filterObj.filterFields.FAM = '';
    this.filterObj.filterFields.modifiedDate = '';
    this.filterObj.filterFields.surveyInstance = '';
    this.filterObj.filterFields.Assignee = '';
    this.filterObj.filterFields.TransferedToName = '';
    this.filterObj.filterFields.SubCategory = '';
    this.navFilter.FilterFields = [];
    this.filterString = '';
    this.filterFieldDropdown.value = 'Select Field';
    this.selectedSearchCategory = false;
    this.searchText = [];
    this.closeDropDownIcon = false;
    this.searchPlaceholder = 'Select field to search';
  }

  /**
   * Reset Filter => triggered from HTML Click
   * @param keyValue
   */
  resetFilter(keyValue) {
    for (let i = 0; i < this.searchText.length; i++) {
      if (this.searchText[i].searchField === keyValue) {
        this.searchText.splice(i, 1);
      }
    }
    for (let i = 0; i < this.navFilter.FilterFields.length; i++) {
      if (this.navFilter.FilterFields[i].FilterName === keyValue) {
        this.navFilter.FilterFields.splice(i, 1);
      }
    }
    switch (keyValue) {
      case 'CTLSEnabledDate':
        this.filterObj.filterFields.cTLSEnabledDate = '';
        break;
      case 'CTLSModifiedDate':
        this.filterObj.filterFields.cTLSModifiedDate = '';
        break;
      case 'ModifiedBy':
        this.filterObj.filterFields.modifiedBy = '';
        break;
      case 'Status':
        this.filterObj.filterFields.Status = '';
        break;
      case 'Comments':
        this.filterObj.filterFields.comments = '';
        break;
      case 'TaskCount':
        this.filterObj.filterFields.taskCount = '';
        break;
      case 'NextStep':
        break;
      case 'Objective':
        this.filterObj.filterFields.objective = '';
        break;
      case 'Category':
        this.filterObj.filterFields.category = '';
        break;
      case 'Event':
        this.filterObj.filterFields.event = '';
        break;
      case 'SurveyID':
        this.filterObj.filterFields.surveyID = '';
        break;
      case 'ContactName':
        this.filterObj.filterFields.contactName = '';
        break;
      case 'OrderNo':
        this.filterObj.filterFields.orderNo = '';
        break;
      case 'NPSScore':
        this.filterObj.filterFields.nPSScore = '';
        break;
      case 'InfluenceLevel':
        this.filterObj.filterFields.influenceLevel = '';
        break;
      case 'EmailAddress':
        this.filterObj.filterFields.emailAddress = '';
        break;
      case 'FeedbackSubmitDate':
        this.filterObj.filterFields.feedbackSubmitDate = '';
        break;
      case 'NPSComment':
        this.filterObj.filterFields.nPSComment = '';
        break;
      case 'DistributorshipManagerSales':
        this.filterObj.filterFields.distributorshipManagerSales = '';
        break;
      case 'DistributorshipManagerService':
        this.filterObj.filterFields.distributorshipManagerService = '';
        break;
      //addhere
      case 'TaskTitle':
        this.filterObj.filterFields.TaskTitle = '';
        break;
      case 'Status':
        this.filterObj.filterFields.Status = '';
        break;
      case 'TaskID':
        this.filterObj.filterFields.TaskID = '';
        break;
      case 'Priority':
        this.filterObj.filterFields.Priority = '';
        break;
      case 'RecommendedAction':
        this.filterObj.filterFields.RecommendedAction = '';
        break;
      case 'BrandName':
        this.filterObj.filterFields.BrandName = '';
        break;
      case 'Branch':
        this.filterObj.filterFields.Branch = '';
        break;
      case 'Zone':
        this.filterObj.filterFields.Zone = '';
        break;
      case 'SalesEngineer':
        this.filterObj.filterFields.SalesEngineer = '';
        break;
      case 'ServiceTechnician':
        this.filterObj.filterFields.ServiceTechnician = '';
        break;
      case 'Distributor':
        this.filterObj.filterFields.Distributor = '';
        break;
      case 'DistributorSegments':
        this.filterObj.filterFields.DistributorSegments = '';
        break;
      case 'DistributorAccountNo':
        this.filterObj.filterFields.DistributorAccountNumbers = '';
        break;
      case 'FAM':
        this.filterObj.filterFields.FAM = '';
        break;
      case 'Touchpoint':
        this.filterObj.filterFields.Touchpoint = '';
        break;
      case 'ModifiedDate':
        this.filterObj.filterFields.modifiedDate = '';
        break;
      case 'SurveyInstance':
        this.filterObj.filterFields.surveyInstance = '';
        break;
      case 'Assignee':
        this.filterObj.filterFields.Assignee = '';
        break;
      case 'TransferTo':
        this.filterObj.filterFields.TransferedToName = '';
        break;
      case 'SubCategory':
      this.filterObj.filterFields.SubCategory = '';
      break;
    }
    if (this.searchText.length == 0) {
      this.clearSearchShow = false;
    } else {
      this.clearSearchShow = true;
    }
    if (this.searchText.length == 0) {
      this.isSearchApplied = false;
    }
    this.isMultiSelect = false;
    this.UnselectedIDs = [];
    this.totalSelectedRecords = 0;
    this.filterObj.PageNumber = 1;
    // this.resetSearchFilterFields();
    this.requestForTaskList(this.filterObj);
  }

  /**
   * Reset Search Filter => triggered from HTML Click
   */
  resetSearchfilter() {
    this.resetSearchFilterFields();
    this.isMultiSelect = false;
    this.UnselectedIDs = [];
    this.filterObj.PageNumber = 1;
    this.requestForTaskList(this.filterObj);
    this.disabled = true;
    this.closeDropDownIcon = false;
  }

  /**
   * Searchable Column Change
   * @param event
   */
  onSearchableColumnChange(event) {
    if (event.value != null) {
      this.closeDropDownIcon = true;
      let colName = event.value.field;
      this.searchField = event.value.field;
      this.searchFieldLabel = event.value.header;
      if (event.value.header) {
        this.searchPlaceholder = 'Search by ' + event.value.header;
      } else {
        this.searchPlaceholder = 'Search by text';
      }
    } else {
      this.searchPlaceholder = 'Select field to search';
    }
    this.disabled = false;
  }

  resetCompany() {
    this.selectedCompanyId = [];
    this.totalSelectedRecords = 0;
    this.isMultiSelect = false;
  }

  /**
   * Clear Search Filter => triggered from HTML Click
   */
  clearSearchfilter() {
    if (!this.selectedFilterVal) {
      this.filterString = '';
    }
    this.resetclearSearchfilter();
    this.filterObj.PageNumber = 1;
    this.navFilter.ErrorCode = [];
    this.navFilter.FilterFields = [];
    this.isMultiSelect = false;
    this.selectedCompanyId = [];
    this.UnselectedIDs = [];
    this.totalSelectedRecords = 0;
    // this.filterObj.ErrorCodes = [];
    this.closeDropDownIcon = false;
    this.requestForTaskList(this.filterObj);
    this.clearSearchShow = false;
  }

  private resetclearSearchfilter() {
   
    this.filterObj.filterFields.surveyID = '';
    this.filterObj.filterFields.contactName = '';
    this.filterObj.filterFields.orderNo = '';
    this.filterObj.filterFields.nPSScore = '';
    this.filterObj.filterFields.nPSComment = '';
    this.filterObj.filterFields.influenceLevel = '';
    this.filterObj.filterFields.emailAddress = '';
    this.filterObj.filterFields.objective = '';
    this.filterObj.filterFields.event = '';
    this.filterObj.filterFields.category = '';
    this.filterObj.filterFields.distributorshipManagerSales = '';
    this.filterObj.filterFields.distributorshipManagerService = '';
    this.filterObj.filterFields.modifiedBy = '';
    this.filterObj.filterFields.comments = '';
    this.filterObj.filterFields.taskCount = '';
    this.filterObj.filterFields.cTLSEnabledDate = '';
    this.filterObj.filterFields.cTLSModifiedDate = '';
    this.filterObj.filterFields.feedbackSubmitDate = '';
    this.filterObj.filterFields.SubCategory = '';
    //addhere
    this.filterObj.filterFields.TaskTitle = '';
    this.filterObj.filterFields.Status = '';
    this.filterObj.filterFields.TaskID = '';
    this.filterObj.filterFields.Priority = '';
    this.filterObj.filterFields.RecommendedAction = '';
    this.filterObj.filterFields.Branch = '';
    this.filterObj.filterFields.Zone = '';
    this.filterObj.filterFields.Touchpoint = '';
    this.filterObj.filterFields.SalesEngineer = '';
    this.filterObj.filterFields.ServiceTechnician = '';
    this.filterObj.filterFields.Distributor = '';
    this.filterObj.filterFields.DistributorSegments = '';
    this.filterObj.filterFields.DistributorAccountNumbers = '';
    this.filterObj.filterFields.FAM = '';
    this.filterObj.filterFields.modifiedDate = '';
    this.filterObj.filterFields.surveyInstance = '';
    this.filterObj.filterFields.Assignee = '';
    this.filterObj.filterFields.TransferedToName = '';
    this.navFilter.FilterFields = [];
    // this.navFilter.FilterText = '';
    this.filterString = '';
    this.filterFieldDropdown.value = 'Select Field';
    this.selectedSearchCategory = false;
    this.searchText = [];
    // this.selectAllrecords = false;
    this.searchPlaceholder = 'Select field to search';
  }

  /**
   * Error Message Display
   * severity: info/success/warn/error
   * @param type: string => severity
   * @param summary: string
   * @param detail: string => Message to show
   */
  callErrorMsg(type, summary = '', detail = '', blnClearTasterService = false) {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    if (blnClearTasterService) {
      this.commonService.clearToaster();
    }
    setTimeout(function () {
      document.getElementById('ClearMsg').click();
    }, this.MessageTimeOut);
  }

  callErrorMsgTime(type, summary = '', detail = '', TimeOut) {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () {
      document.getElementById('ClearMsg').click();
    }, this.MessageTimeOut);
  }
  /* Validate Date */
  isValidDate(date) {
    let d = new Date(date);
    return d.getTime() === d.getTime()
  }
  /**
   * Search By Filter Text
   * @param event: any
   * @param isClick: boolean
   */
  searchByFilterText(event, isClick) {
   
    if (event && (event.which == '13' || isClick)) {

      if (this.searchField == 'ModifiedDate') {
        if (!this.isValidDate(event)) {
          this.successMsg = true;
          this.callErrorMsg(
            'error',
            '',
            `This given modified date field is not valid, kindly use the date format as like 08-Jun-2022`
          );
          return;
        }
      }



      this.isSearchApplied = true;
      this.isMultiSelect = false;
      this.searchData(event, isClick);
      this.disabled = true;
    }
  }

  /**
   * Triggered from @function searchByFilterText()
   * @param event: any
   * @param isClick: boolean
   */
  private searchData(event, isClick) {
    let textSearch;
    let hasRecordNav = false;
    let matched = false;
    if ((event && event.which == '13') || isClick) {
      if (isClick) textSearch = event;
      else textSearch = event.target.value;
      const searchObj = {
        FilterName: this.searchField,
        FilterText: textSearch,
      };
      this.navFilter.FilterFields.forEach((key, i) => {
        if (this.searchField === this.navFilter.FilterFields[i].FilterName)
          this.navFilter.FilterFields[i].FilterText = textSearch;
        hasRecordNav = true;
      });
      if (!hasRecordNav) {
        this.navFilter.FilterFields.push(searchObj);
      }
      switch (this.searchField) {
        case 'CTLSEnabledDate':
          this.filterObj.filterFields.cTLSEnabledDate = textSearch;
          break;
        case 'CTLSModifiedDate':
          this.filterObj.filterFields.cTLSModifiedDate = textSearch;
          break;
        case 'ModifiedBy':
          this.filterObj.filterFields.modifiedBy = textSearch;
          break;
        case 'Comments':
          this.filterObj.filterFields.comments = textSearch;
          break;
        case 'TaskCount':
          this.filterObj.filterFields.taskCount = textSearch;
          break;
        case 'NextStep':
          break;
        case 'Objective':
          this.filterObj.filterFields.objective = textSearch;
          break;
        case 'Category':
          this.filterObj.filterFields.category = textSearch;
          break;
        case 'Event':
          this.filterObj.filterFields.event = textSearch;
          break;
        case 'SurveyID':
          this.filterObj.filterFields.surveyID = textSearch;
          break;
        case 'ContactName':
          this.filterObj.filterFields.contactName = textSearch;
          break;
        case 'OrderNo':
          this.filterObj.filterFields.orderNo = textSearch;
          break;
        case 'NPSScore':
          this.filterObj.filterFields.nPSScore = textSearch;
          break;
        case 'InfluenceLevel':
          this.filterObj.filterFields.influenceLevel = textSearch;
          break;
        case 'EmailAddress':
          this.filterObj.filterFields.emailAddress = textSearch;
          break;
        case 'FeedbackSubmitDate':
          this.filterObj.filterFields.feedbackSubmitDate = textSearch;
          break;
        case 'NPSComment':
          this.filterObj.filterFields.nPSComment = textSearch;
          break;
        case 'DistributorshipManagerSales':
          this.filterObj.filterFields.distributorshipManagerSales = textSearch;
          break;
        case 'SubCategory':
          this.filterObj.filterFields.SubCategory = textSearch;
          break;
        case 'DistributorshipManagerService':
          this.filterObj.filterFields.distributorshipManagerService =
            textSearch;
          break;
        //addhere
        case 'TaskTitle':
          this.filterObj.filterFields.TaskTitle = textSearch;
          break;
        case 'Status':
          this.filterObj.filterFields.Status = textSearch;
          break;
        case 'TaskID':
          this.filterObj.filterFields.TaskID = textSearch;
          break;
        case 'Priority':
          this.filterObj.filterFields.Priority = textSearch;
          break;
        case 'RecommendedAction':
          this.filterObj.filterFields.RecommendedAction = textSearch;
          break;
        case 'Branch':
          this.filterObj.filterFields.Branch = textSearch;
          break;
        case 'Zone':
          this.filterObj.filterFields.Zone = textSearch;
          break;
        case 'SalesEngineer':
          this.filterObj.filterFields.SalesEngineer = textSearch;
          break;
        case 'ServiceTechnician':
          this.filterObj.filterFields.ServiceTechnician = textSearch;
          break;
        case 'Distributor':
          this.filterObj.filterFields.Distributor = textSearch;
          break;
        case 'DistributorSegments':
          this.filterObj.filterFields.DistributorSegments = textSearch;
          break;
        case 'DistributorAccountNo':
          this.filterObj.filterFields.DistributorAccountNumbers = textSearch;
          break;
        case 'FAM':
          this.filterObj.filterFields.FAM = textSearch;
          break;
        case 'Touchpoint':
          this.filterObj.filterFields.Touchpoint = textSearch;
          break;
        case 'ModifiedDate':
          this.filterObj.filterFields.modifiedDate = textSearch;
          break;
        case 'SurveyInstance':
          this.filterObj.filterFields.surveyInstance = textSearch;
          break;
        case 'Assignee':
          this.filterObj.filterFields.Assignee = textSearch;
          break;
        case 'SubCategory':
          this.filterObj.filterFields.SubCategory = textSearch;
          break;
        case 'TransferTo':
          this.filterObj.filterFields.TransferedToName = textSearch;
          break;
        default:
          break;
      }

      this.searchText.forEach((key, i) => {
        if (this.searchText[i].searchField === this.searchField) {
          this.searchText[i].searchText = textSearch;
          matched = true;
        }
      });
      if (!matched) {
        this.searchText.push({
          searchText: textSearch,
          searchField: this.searchField,
          searchFieldLabel: this.searchFieldLabel,
        });
      }
      this.selectedSearchCategory = true;
      this.filterObj.PageNumber = 1;

      this.clearSearchShow = true;
      this.requestForTaskList(this.filterObj);
      this.filterString = '';
      this.closeDropDownIcon = false;
      this.searchPlaceholder = 'Select field to search';
      this.filterFieldDropdown.value = 'Select Field';
      this.selectedFilterVal = '';
    }
  }

  /**
   * Clear Dropdown Text => triggered from HTML
   */
  clearDropdownText() {
    if (!this.selectedFilterVal) {
      this.filterString = '';
    }
  }

  /**
   * User Selected Columns For Modify Columns
   * @param list
   */
  userSelectedColumns(list, triggerSave = true) {
    if (list.value) {
      this.columnLength = list.value.length + 3;
    }

    const ModifyColumnsSelected = this.selectedColumns
      .map((ele) => {
        if (ele && ele.field)
          return ele.field;
      })
      .join(',');
    if (!!this.assignedTask) {
      var dataModifyColumns = {
        GridName: 'CTLS_TASK_LISTING',
        SubCategoryCode: this.selectedRadioOption === 1 ? 'TRANS' : 'DIST',
        ColumnNames: ModifyColumnsSelected,
      };
    } else {
      var dataModifyColumns = {
        GridName: 'CTLS_TASK_LISTING_UNASSIGNED',
        SubCategoryCode: this.selectedRadioOption === 1 ? 'TRANS' : 'DIST',
        ColumnNames: ModifyColumnsSelected,
      };
    }
    if (triggerSave) {
      this.eventFromPresentationTable.emit({
        operation: 'MODIFY_COLUMN',
        data: dataModifyColumns,
      });
    }
  }

  /**
   * checkbox selection handling
   */

  selectAll(checkValue) {
    if (checkValue) {
      this.rowData.map((item) => {
        item.isSelected = true;
      });
      this.selectedRows = this.rowData;
      this.taskProceed = true;
    } else {
      this.selectAllCheckBoxValue = false;
      this.rowData.map((item) => {
        item.isSelected = false;
      });
      this.selectedRows = [];
      this.taskProceed = false;
    }
  }
  /**
   * Select checkbox in grid row
   * @param event
   * @param rowData
   */
  selectRow(event, rowData) {
    if (this.rowData.length != this.selectedRows.length) {
      this.selectAllCheckBoxValue = false;
    } else {
      this.selectAllCheckBoxValue = true;
    }
  }
  /**
   * open popup
   *  Creat task
   * */
  createTask(objRowData = null) {
    if (!!objRowData) {
      this.eventFromPresentationTable.emit({
        operation: 'CREATE_TASK',
        data: {
          operation: 'FROM_GRID',
          objData: objRowData,
        },
      });
    }
  }

  /**
   * open popup
   *  Bulk update
   * */
  showBulkUpdate() {
    this.eventFromPresentationTable.emit({
      operation: 'BULK_UPDATE',
    });
  }

  /**
   * Delete Task/s with Confirmation
   * @param itemsArray => will be an array of TaskIds
   */
  deleteTask(items) {
    let itemsArray = [];
    let deleteRows = [];
    if (!!items.length) {
      itemsArray.push(items[0].TaskID);
      deleteRows = items;
    }
    if (!!!items.length && !!this.selectedRows.length) {
      itemsArray = this.selectedRows.map((i) => i.TaskID);
      deleteRows = this.selectedRows;
    }
    this.confirmationService.confirm({
      header: 'Confirmation',
      message:
        itemsArray.length > 1
          ? 'Are you sure you want to delete the selected task/s?'
          : 'Are you sure you want to delete the task?',
      accept: () => {
        let isDeletable = true;
        let nonDeletable = _.filter(deleteRows, ['IsTaskDeletable', false]);
        isDeletable = nonDeletable.length ? false : true;
        if (!isDeletable) {
          if (deleteRows.length == 1) {
            this.successMsg = true;
            this.callErrorMsg(
              'error',
              '',
              `This task cannot be deleted, as there is a response pending from ${this.selectedRadioOption === 1 ? 'customer' : 'distributor'
              }.`
            );
          } else {
            this.successMsg = true;
            this.callErrorMsg(
              'error',
              '',
              `Selected task/s cannot be deleted, as there are responses pending from ${this.selectedRadioOption === 1 ? 'customer' : 'distributor'
              }.`
            );
          }
        } else {
          this.eventFromPresentationTable.emit({
            operation: 'DELETE_TASKS',
            data: itemsArray,
          });
        }
      },
      reject: () => { },
    });
  }

  showSurveyDetails(objRowData = null) {
    // this.showDialog = true;
    if (!!objRowData) {
      this.eventFromPresentationTable.emit({
        operation: 'VIEW_SURVEY_DETAILS',
        data: {
          operation: 'FROM_GRID',
          objData: objRowData.SurveyID,
        },
      });
    }
  }
  /**
   * To show popup
   * @param overlayTemp
   * @param event
   */
  onMouseEnter(overlayTemp, event, rowData) {
    rowData.currentComment = '';
    this.ctlsTaskListingService.setOnHoverOfComment(true);
    this.popoverlay.toArray()[overlayTemp].show(event);
    setTimeout(() => {
      // for auto focus
      this.commentFocus.toArray()[overlayTemp].nativeElement.focus();
    }, 500);
  }
  /**
   * To Hide popup
   * @param overlayTemp
   * @param event
   */
  onMouseLeave(overlayTemp, event, currentComment, rowData) {
    if (rowData.commentRequired) {
      if (rowData.currentComment === '') {
        //reset status to previous
        rowData.CurrentStatus = rowData.InitialStatus;
      }
    }
    if (rowData.currentComment) {
      const reqObj = {
        CTLSTaskListID: rowData.TaskID,
        Comment: rowData.currentComment,
        StatusID: rowData.CurrentStatus.CTLSStatusID,
      };
      this.updateTaskCommentAndStatus(reqObj);
    }
    rowData.currentComment = '';
    rowData.commentRequired = false;
    this.ctlsTaskListingService.setOnHoverOfComment(false);
    this.popoverlay.toArray()[overlayTemp].hide();
  }
  /**
   * on change of status
   * @param event
   * @param rowData
   */
  onChangeStatus(event, rowData, op2) {
    this.onMouseEnter(op2, event, rowData);
    rowData.commentRequired = true;
  }
  /**
   * Save api for inline edit comment and status
   * @param reqObj
   */
  updateTaskCommentAndStatus(reqObj) {
    this.ctlsTaskListingService
      .UpdateTaskCommentAndStatus(reqObj)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.Response) {
          this.requestForTaskList(this.filterObj);
        }
      });
  }
  /**
   * Edit task
   * @param rowData
   */
  editTask(rowData) {
    this.router.navigate(['ctls/task-listing/edit'], {
      queryParams: {
        TaskID: rowData.TaskID,
        selectedRadioOption: this.selectedRadioOption
      },
      skipLocationChange: true,
    });
  }
  viewTask(rowData) { }

  /**
   * move the selected row up or down the sequence => triggered from html
   */
  sequenceRows(seqType) {

    if (!!this.selectedRows && this.selectedRows.length) {
      let seqArray = [];
      seqArray = _.filter(this.selectedRows, (item) => (typeof item.Sequence === 'number'));
      if (this.selectedRows.length <= 15) {
        if (
          this.selectedRows.length <= 15 &&
          (seqArray.length === this.selectedRows.length ||
            seqArray.length === 0)
        ) {
          let seqObj = {
            seqType: seqType,
            selectedRows: this.selectedRows
          }
          let index = null;
          let isInvalidOperation = false;
          let lastSequencedIndex = _.findLastIndex(this.rowData, (item) => (typeof item.Sequence === 'number'));

          this.selectedRows.map((item) => {
            index = _.findIndex(this.rowData, item);
            let isSequenced = typeof item.Sequence === 'number' ? true : false;

            if (isSequenced) {
              if (seqType === 'DOWN' && index == lastSequencedIndex)
                isInvalidOperation = true
              else if (seqType === 'UP' && index == 0)
                isInvalidOperation = true
            }
          });

          if (!isInvalidOperation) {
            this.eventFromPresentationTable.emit({
              operation: 'MODIFY_ROW_SEQUENCE',
              data: seqObj,
            });
          }
        }
      } else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'You can select maximum 15 records for pinning');
      }
    }
  }
  /**
   * move all sequenced to the top => triggered from html
   */
  getPinnedRecords() {
    if (this.pinnedColBtnEnabled) {
      this.eventFromPresentationTable.emit({
        operation: 'GET_PINNED_TASK_LIST',
      });
      this.table.sortOrder = 0;
      this.table.sortField = '';
      this.table.reset();
      this.pinnedColBtnEnabled = false;
    }
  }

  /**
    * Unpin the Task => triggered from html
    */
  unpinCurrentTask(TaskID) {
    if (!!TaskID) {
      this.eventFromPresentationTable.emit({
        operation: 'UNPIN_TASK',
        data: TaskID
      })
    }
  }
  refreshClockTime(TaskID, isvalid) {
    if (!isvalid) return;

    this.eventFromPresentationTable.emit({
      operation: 'UPDATE_CLOCK',
      data: TaskID
    })
  }


  styleObject(key): Object {
    switch (key) {
      case 'RecommendedAction':
        return { width: '8%' };

      case 'DistributorshipManagerSales':
        return { width: '8%' };

      case 'DistributorshipManagerService':
        return { width: '8%' };

      case 'NPSScore':
        return { width: '54px' };

      case 'CustomerAccountNo':
        return { width: '6%' };

      case 'InfluenceLevel':
        return { width: '72px' };

      case 'Assignee':
        return { width: '6%' };

      case 'ModifiedBy':
        return { width: '6%' };

      case 'TaskID':
        return { width: '6%' };

      case 'Priority':
        return { width: '75px' };

      case 'CreatedDate':
        return { width: '6%' };

      case 'ModifiedDate':
        return { width: '6%' };

      case 'Brand':
        return { width: '6%' };

      case 'DIV':
        return { width: '6%' };

      case 'CustomerName':
        return { width: '6%' };

      case 'OrderNo':
        return { width: '6%' };

      case 'Branch':
        return { width: '6%' };
      case 'Zone':
        return { width: '6%' };

      case 'ServiceTechnician':
        return { width: '6%' };

      case 'SalesEngineer':
        return { width: '6%' };

      case 'Touchpoint':
        return { width: '6%' };

      case 'FAM':
        return { width: '6%' };

      case 'BA':
        return { width: '6%' };

      case 'TransferTo':
        return { width: '6%' };
      case 'ContactName':
        return { width: '6%' };

      case 'DistributorSegment':
        return { width: '7%' };

      case 'Distributor':
        return { width: '6%' };
      case 'DistributorAccountNo':
        return { width: '8%' };
      case 'SubCategory':
        return { width: '8%' };
      case 'SurveyInstance':
        return { width: '6%' };


          case 'DistributorAccountNo':
          return { width: '100px' };

      default:
        return {};
    }
  }

  multipleAssign(objRowData?) {
    if (!!objRowData) {
      this.eventFromPresentationTable.emit({
        operation: 'CREATE_ASSIGN',
        data: {
          operation: 'FROM_GRID',
          objData: objRowData,
          userRole: this.userRole,
          IsMultipleFAMBADIV: this.IsMultipleFAMBADIV
        },
      });
    } else {
      this.eventFromPresentationTable.emit({
        operation: 'CREATE_ASSIGN',
        data: {
          operation: 'FROM_BUTTON_CLICK',
          objData: this.selectedRows,
          userRole: this.userRole,
          IsMultipleFAMBADIV: this.IsMultipleFAMBADIV

        },
      });
    }
  }


  showAccountDetails(item) {
    if (this.selectedRadioOption == 1) {
      this.router.navigate(['ctls/360/customers'], {
        queryParams: {
          EntityID
            : item.EntityId
          ,
        },
        // skipLocationChange: true,
      });
    }
    else {
      this.router.navigate(['ctls/360/distributors'], {
        queryParams: {
          EntityID: item.EntityId,
          isDistributor: true
        },
        // skipLocationChange: true,
      });
    }
  }
toggleView() {
    this.collapseData = !this.collapseData;
  }
 
}
