import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password-confirmation',
  templateUrl: './forgot-password-confirmation.component.html',
  styleUrls: ['./forgot-password-confirmation.component.scss']
})
export class ForgotPasswordConfirmationComponent implements OnInit {
  title: string = "Forgot Password";

  constructor() { }

  ngOnInit(): void {
    // Any initialization logic if needed
  }
}

