import { Component, Output, EventEmitter } from '@angular/core';
import { HeaderService } from '../../services/header.service';


@Component({
  selector: 'app-header-ul-item',
  templateUrl: './header-ul-item.component.html',
  styleUrls: ['./header-ul-item.component.scss'],
  inputs: ['data']
})
export class HeaderUlItemComponent {
  public data: any;
  public hideElement: boolean = true;
  public jsonIndex: any;
  landingPageCode = 'TD';
  @Output() changeDefaultDashboard = new EventEmitter();
  constructor(private headerService: HeaderService) { 
    // this.landingPageCode = this.headerService.LandingPageCode;
    var userInfo = JSON.parse(localStorage.getItem('userProfileonLoad'));
    this.landingPageCode = userInfo.defaultViewUrl? userInfo.defaultViewUrl:'TD';

  }

  showHeaderText(item: any) {
    if (item.hasOwnProperty('menuHeader')) {
      return item.menuHeader;
    }
    return item.headerText;
  }

  hasRights(item, index) {
    if (item.hasRights == true) {
      this.jsonIndex = index;
    }
  }

  hasRightsForzone(item, inputindex) {
    for (let index = 0; index < item.length; index++) {
      const element = item[index];
      if (element.hasRights == true) {
        this.jsonIndex = inputindex;
      }
    }
  }

  saveDefaultView(id){
    var rd1 = <HTMLElement>document.getElementById('TD');
    if(rd1 != null) rd1.classList.remove('active');
    var rd2 = <HTMLElement>document.getElementById('FTD');
    if(rd2 != null) rd2.classList.remove('active');
    var rd3 = <HTMLElement>document.getElementById('DTD');
    if(rd3 != null)rd3.classList.remove('active');
    var rd4 = <HTMLElement>document.getElementById('DD');
    if(rd4 != null)rd4.classList.remove('active');
    var rd5 = <HTMLElement>document.getElementById('CTL');
    if(rd5 != null)rd5.classList.remove('active');
    var clickedItem = <HTMLElement>document.getElementById(id);
    if(clickedItem != null) clickedItem.classList.add('active');
    this.headerService.SaveDefaultLandingPage(id).then(res=>{
      if(res.Status=="Success" && res.Response){
        //Success
        this.changeDefaultDashboard.emit(id);
      }
    });
  }
}

