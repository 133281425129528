import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FIELDS } from './shared/constants/FIELDS';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showMainLayout: boolean = true;
  constructor(
    public router: Router
  ) {

  }

  async ngOnInit() {
    // this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     // Check the URL path to determine which layout to show
    //     this.showMainLayout = !event.url.startsWith('/auth');
    //   }
    // });
    const current_url = window.location.pathname;
    if(!(localStorage.getItem(FIELDS.ACCESS_TOKEN)) && (!current_url.includes('auth'))){
      this.router.navigate(['/auth/login']);
    }
  }
  
}
