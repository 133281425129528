import { forEach } from '@angular/router/src/utils/collection';
import {
  Component,
  OnInit,
  Input,
  Renderer,
  Renderer2,
  ViewChild,
  HostListener,
  ElementRef,
  SimpleChanges
} from '@angular/core';
import { PARAMETERS } from '@angular/core/src/util/decorators';
import { ActivatedRoute } from '@angular/router';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { FilterService } from 'src/app/shared/services/filter.service';
import { HeaderService } from 'src/app/shared/services/header.service';

import { Message } from 'primeng/components/common/api';
import { SharedService } from 'src/app/shared/services/shared.service';
import { from, BehaviorSubject, Subscription, Subject } from 'rxjs';

import { DialogService } from 'primeng/api';

import { DashboardWidgetService } from 'src/app/components/dashboard/service/dashboard-widget.service';
import { StartSurveyComponent } from 'src/app/components/surveys/survey-library/start-survey/start-survey.component';

import { PermissionsService } from './../../../app.service';
import { AddTemplateComponent } from '../../../components/surveys/add-template/add-template.component';
import { SessionStorageService } from './../../../components/surveys/services/session-storage.service';
import { CreateSurveyService } from '../../../components/surveys/survey-library/services/create-survey.service';
import { ExportChartsService } from '../../services/export-charts.service';
import * as cloneDeep from 'lodash/cloneDeep';
import * as _ from 'lodash';
// import * as data from './header';
import { interval } from 'rxjs';
import { ChartDataParsing } from '../../utils/chart-data-parsing.util';
import { ExportChartModel, ExportSlideData } from '../../models/export-chart.model';
import { filter, takeUntil } from 'rxjs/operators';
import { IMAGE_EXTRACTION } from '../../enums/export-state-enum';
import * as OnlineHelpJson from 'src/app/shared/constants/online-help.config';

import { HideOtherOpenDrilldownsService } from '../../../Modules/Distributor_Survey/Services/hide-other-open-drilldowns.service';
import { LostbusinessCustomerService } from '../../../components/dashboard/service/lostbusiness-customer.service';
import { CtlsCommonService } from 'src/app/Modules/ctls/ctls-shared/ctls-services';
import { CONSTANTS } from 'src/app/Modules/ctls/ctls-shared/ctls-constants/ctls-common-constants';
import * as MASTER_DATA_CONST from 'src/app/Modules/falcon/falcon-shared/falcon-constants/falcon-common-constants';
import { CtlsNotificationService } from 'src/app/Modules/ctls/ctls-shared/ctls-services/ctls-notification.service';

import { UserManagementService } from 'src/app/components/falcon-setup/user-management/services/user-management.service';
import { ChartDataService } from 'src/app/components/dashboard/service/chart-data-service.service';
import { enterView } from '@angular/core/src/render3/instructions';
import { environment } from 'src/environments/environment';
import { RelationshipFilterService } from '../../services/filter-relationship.service';
import { StorageService } from '../../services/storage.service';
const data = require('./header');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  private imagepath = environment.imagepath;
  isDashboardView: boolean = true;
  isTrendAnalysisView: boolean = false;
  private LostCountSubject: Subscription;
  isFeedBackActive: boolean = false;
  private hmSlonBirdClickSubjectResponse: Subscription;
  strCustomerMappingPermission = CONSTANTS.CustomerMapping.permissionConstant;
  strConfiguarationPermission = CONSTANTS.SurveyConfiguration.permissionConstant;
  strAssigneeMappingPermission = CONSTANTS.AssigneeMapping.permissionConstant;
  strClosureTemplateListingPermission = CONSTANTS.TaskClosureTemplateListing.permissionConstant;
  strTaskSurveyFeedbackPermission = CONSTANTS.TaskSurveyFeedback.permissionConstant;
  strTaskListingPermission = CONSTANTS.TaskListing.permissionConstant;
  strVocListingPermission = CONSTANTS.VocListing.permissionConstant;
  strSdaListingPermission = CONSTANTS.SDAList.permissionConstant;
  strTPListingPermission = CONSTANTS.TPList.permissionConstant;
  strNotificationListingPermission = CONSTANTS.NotificationMgmt.permissionConstant;
  strUsageManagementListingPermission = CONSTANTS.UsageMgmt.permissionConstant;
  strRuleCriteriaListPermission = CONSTANTS.RuleCriteriaList.permissionConstant;
  strCategoryMasterPermission = CONSTANTS.CategoryMaster.permissionConstant;
  strPriorityMasterPermission = CONSTANTS.PriorityMaster.permissionConstant;
  strRecommendedActionListingPermission = CONSTANTS.RecommendedActionListing.permissionConstant;
  strRuleSetListingPermission = CONSTANTS.RuleSetListing.permissionConstant;
  strRuleAssignmentPermission = CONSTANTS.RuleAssignment.permissionConstant
  strOperationalHoursPermission = CONSTANTS.OperationalHoursDays.permissionConstant;
  // strCustomerPermission = CONSTANTS.customerList.permissionConstant;
  strCustomerPermission = CONSTANTS.dashboardCustomer.permissionConstant;
  strDistributorPermission = CONSTANTS.dashboardDistributor.permissionConstant;





  strDesignationMasterPermission = CONSTANTS.DesignationMaster.permissionConstant;

  strUnavailabilityListPermission = CONSTANTS.UnavailabilityList.permissionConstant;
  strUsageManagementPermission = CONSTANTS.UsageManagement.permissionConstant;

  strConfiguarationUrl = CONSTANTS.SurveyConfiguration.url;
  strAssigneeMappingUrl = CONSTANTS.AssigneeMapping.url;
  strClosureTemplateListingUrl = CONSTANTS.TaskClosureTemplateListing.url;
  strTaskSurveyFeedbackUrl = CONSTANTS.TaskSurveyFeedback.url;
  strTaskListingUrl = CONSTANTS.TaskListing.url;
  strVocListingUrl = CONSTANTS.VocListing.url;
  strSdaListingUrl = CONSTANTS.SDAList.url;
  strTPListingUrl = CONSTANTS.TPList.url;
  configTP = CONSTANTS.TPList;

  strCustomersUrl = CONSTANTS.dashboardCustomer.url;
  strDistributorsUrl = CONSTANTS.dashboardDistributor.url;


  strNotificationListingUrl = CONSTANTS.NotificationMgmt.url;
  strUsageManagementListingUrl = CONSTANTS.UsageMgmt.url;
  strRuleCriteriaListUrl = CONSTANTS.RuleCriteriaList.url;
  strCategoryMasterUrl = CONSTANTS.CategoryMaster.url;
  strCustomerMappingUrl = CONSTANTS.CustomerMapping.url;
  strPriorityMasterUrl = CONSTANTS.PriorityMaster.url;
  strRecommendedActionListingUrl = CONSTANTS.RecommendedActionListing.url;
  strRuleSetListingUrl = CONSTANTS.RuleSetListing.url;
  strUnavailabilityListUrl = CONSTANTS.UnavailabilityList.url;
  strRuleAssignmentUrl = CONSTANTS.RuleAssignment.url;
  strDesignationMasterUrl = CONSTANTS.DesignationMaster.url;
  strUsageManagementUrl = CONSTANTS.UsageManagement.url;
  strOperationalHoursUrl = CONSTANTS.OperationalHoursDays.url;
  destroy$ = new Subject();
  notificationResponse: any;
  notificationCount: any;
  current_url: string;
  hideDiv = false;
  seletedCategory: any;
  // userData: any = {
  //   'FirstName': '',
  //   'LastName': '',
  //   'EmailId': '',
  //   'ReportingFAM': '',
  //   'Country': '',
  //   'Roles': [],
  //   'UserID': '',
  //   'PhotoUrl': '',
  //   'SelectedRoles': []
  // };

  constructor(
    private ctlsNotificationService: CtlsNotificationService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public dialogService: DialogService,
    public userManagementService: UserManagementService,
    private _createSurveyServce: CreateSurveyService,
    private render: Renderer,
    private renderer: Renderer2,
    private appService: PermissionsService,
    private headerService: HeaderService,
    private _activeRoute: ActivatedRoute,
    private filterService: FilterService,
    private filterRService: RelationshipFilterService,
    private dashboardWidgetService: DashboardWidgetService,
    private sessionService: SessionStorageService,
    private sharedService: SharedService,
    private exportChartService: ExportChartsService,
    private hideOtherOpenDrilldownsService: HideOtherOpenDrilldownsService,
    private lbService: LostbusinessCustomerService,
    private ctlsCommonService: CtlsCommonService,
    private chartDataService: ChartDataService,
    private elementRef: ElementRef,
    private storageService: StorageService
  ) {
    this.receivedCount = 0;
    this.headerService.selectLiElement.subscribe(data => {
      this.removeIndex();
    });

    this.globFilterSub = this.filterService.globalFilterBehaviousSubject.subscribe(val => {
      this.globalFilterData = val;
      // this.seletedCategory = this.globalFilterData.SubCategory;
    });
    this.globFilterRSub = this.filterRService.globalFilterBehaviousSubject.subscribe(val1 => {
      // this.globalFilterData = val;
      this.seletedCategory = val1.SubCategory;
    });
    this.globFilterSelectedSub = this.filterService.globalFilterSelectedFilterSubject.subscribe(val => {
      this.globFilterSelectedData = val;
      this.seletedCategory = this.globFilterSelectedData.SubCategory;
    });
    this.dashboardStateSub = this.sharedService.dashboardStateSubject.subscribe(res => {
      this.currentDashboardView = res;
      if (res.toLowerCase() === this.sharedService.TRENDLINE_VIEW.toLowerCase()) {
        this.allowChartExports = true;
      }
    });
    this.sharedService.allowChartExportingSubject.subscribe(update => {
      this.allowChartExports = update;
    });
    this.sharedService.errorCountSubject.subscribe(apiCallflag => {
      if (apiCallflag) {
        this.DisplayErrorCount();
      }
    });

    // this.sharedService.imgExtractStatusSubject.pipe(filter(update => update === IMAGE_EXTRACTION.READY)).subscribe(update => {
    this.sharedService.imgExtractStatusSubject.pipe(filter(update => update === IMAGE_EXTRACTION.READY || update === IMAGE_EXTRACTION.FAILED)).subscribe(update => {
      if (update === IMAGE_EXTRACTION.READY) {
        this.exportChartsToReports('ppt');
      } else if (update === IMAGE_EXTRACTION.FAILED) {
        // this.exportFailed();
        this.successMsgForDownload = true;
        this.callErrorMsg('error', '', 'An error occurred, please contact customerloyalty@atlascopco.com');
      }
    });

    // need to wait till permission set done
    this.sessionService.sessionPermissionSet.subscribe(dada => {
      if (dada === 'done') {
        if (!this.url) {
          this.url = window.location.pathname;
        }
        this.appService.CheckUserPermissionByKey('VIEW_DASHBOARD').then(data => {
          this.ViewDashboard = data ? true : false;
          this.checkPermission();
        }).catch(() => {
          this.ViewDashboard = false;
          this.checkPermission();
        });
      }
    });

    this.sLHmLoadOnlineHelpSubject = this.sharedService.sLHmLoadOnlineHelpSubject.subscribe(isLoad => {
      if (isLoad !== null) {
        this.showOnlineHelpIcon(isLoad);
      }
    });

    this.LostBusinessErrorMsg = 'Only ' + this.convertToKformate(this.MaxLBRecordCount) + ' records can be downloaded at a time.<br />Please change the period and try downloading again';
    this.Lostmsgs.push({ severity: 'error', summary: '', detail: this.LostBusinessErrorMsg });

    this.LostCountSubject = this.lbService.LostCountSubject.subscribe(cnt => {
      if (cnt !== null) {
        this.ResetLostBusinessWidgets();
      }
    });
    this.hmSlonBirdClickSubjectResponse = this.sharedService.hmSlonBirdClickSubjectResponse.subscribe(event => {
      if (event !== null) {
        this.isFeedBackActive = event;
      }
    });

    this.sharedService.countNotificationSubject.subscribe(data => {
      this.parseCountNotification(data);
    });

    this.sharedService.mgsNotificationSubject.subscribe(data => {
      this.parseMsgNotification(data);
    });
  }
  // [x: string]: number;
  @Input() displayName: any;
  @Input() profileimagurl: any;
  @Input() defaultView: any = null;
  @Input() default_view: any = null;

  public url: any;
  @ViewChild('menulist') menulist: any;
  // items: MenuItem[];
  // contains all json
  public jsonData; // = data.default.jsonData;
  private headerJson = data.default.jsonData;
  public visibilitySubMenu: any = false;
  public visibilitySubMenu1: any = false;
  public visibilitySubMenu2: any = false;
  public visibilitySubMenu3: any = false;
  public selectedIdx: any;
  public showCFMenuPersimission: any;

  selectedValue: string = 'val1';
  tabIndex: any = 1;
  viewNPSCancellation: boolean = false;
  loading: any = false;
  msgs: any = [];
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  genericErrorMessage = 'An Error occurred, please contact ' + this.sharedService.configuration.ContactUsEmail;
  successMsg: any = false;
  // errInPptxExport: boolean = false;
  ShowError: boolean;
  ShowErrorResponse: boolean = false;
  ErrorCount: number = -1;
  imageUrl: string;
  ErrorTimer: boolean = false;
  ShowImage: boolean = true;
  excelreport: number;
  boolViewSelectorComponentLoader: boolean = false;
  parameter: any = {
    StartDate: null,
    EndDate: null,
    PeriodType: null,
    BACode: null,
    DivCode: null,
    BLCode: null,
    Country: null,
    FAMCode: null,
    BrandName: null,
    Zone: null,
    Branch: null,
    SalesEngineer: null,
    ServiceTechnician: null,
    Touchpoint: null,
    Continent: null,
    Region: null,
    Influencer: null,
    RequiredInSurveyLanguage: 'true',
    ReportType: '',
    BasePeriodStartDate: null,
    BasePeriodEndDate: null,
    CompPeriodStartDate: null,
    CompPeriodEndDate: null,
    LostBusinessSheets: '' // Send selected lostbusiness sheet
  };
  firstMenuList: number;
  secondMenuList: number;

  firstLevelPermissionCheck: boolean[] = [];
  secondLevelPermissionCheck: boolean[] = [];
  permissioncheckStart: boolean;
  isCenterChecked: boolean = true;
  isDDChecked: boolean = false;
  isSAChecked: boolean =  false;
  showRightMenuDiv: boolean = false;
  viewSelectorSubmitSuccess = false;
  topIconDisplay: string = 'none';
  widgetLeftOrderList = new Array();
  widgetNpsOrderList = new Array();
  widgetCenterOrderList = new Array();
  widgetDDOrderList = new Array();
  widgetSAOrderList = new Array();
  ViewDashboard = false;
  successMsgForDownload: any = false;
  globFilterSub: Subscription;
  globFilterRSub: Subscription;
  globFilterSelectedSub: Subscription;
  dashboardStateSub: Subscription;

  globalFilterData: any;
  globFilterSelectedData: any;
  currentDashboardView: any;
  allowChartExports: boolean = false;

  readyCharts: { value: string, label: string }[] = [];
  selectedChartsForExport: string[] = [];
  receivedCount: any;
  showBusyIndicator: boolean = false;
  defaultLandingPageUrl = '/';
  showDownloadPanel: boolean = false;
  results: any[] = [];
  XLList: string[] = [];
  XLListlength: number;
  count: number;
  checked: boolean = false;
  pptExportBlockUI: boolean = false;
  private sLHmLoadOnlineHelpSubject: Subscription;
  isShowFalconImg = false;

  // for online help integration
  isShowOnlineHelpIcon = false;
  HelpnavDetails = OnlineHelpJson.OnlineHelp;
  IsXLSSelected = true;
  DwnloadXLSClass = '';

  // lost business
  Lostmsgs: any = [];
  LostBusinessXLList: string[] = [];
  LostBusinessSuccessMsg = false;
  isShowLostBusinessSubMenu = false;
  btnXSLDownloadDisabled = false;
  cntBasePeriod = 0; // for Base period
  cntComparisonPeriod = 0; // for Comparison period
  cntContinuedToDoBusiness = 0; // for Continued to do business
  cntDidnotcontinuetodobusiness = 0; // for Stopped doing transactions
  totalLostCount = 0;
  MaxLBRecordCount = this.sharedService.configuration.MaxLBRecordCount;
  LostBusinessErrorMsg = '';
  boolLoadLostBusinessSM = false;
  IsLBCMVisible = false;
  IsLBWidgetDisable = false;
  xlsLBOWidth = 75;
  // defaultView = null;


  private boolLeafNodePermission: boolean = false;
  public errorCountPermissionCheck: boolean = false;
  public dataDownloadPermissionCheck: boolean = false;

  // this.sharedService.imgExtractStatusSubject.pipe(filter(update => update === IMAGE_EXTRACTION.READY)).subscribe(update => {
  //this.sharedService.imgExtractStatusSubject.pipe(filter(update => update === IMAGE_EXTRACTION.READY || update === IMAGE_EXTRACTION.FAILED)).subscribe(update => {
  //  if(update === IMAGE_EXTRACTION.READY) {
  //    this.exportChartsToReports('ppt');
  //  } else if (update === IMAGE_EXTRACTION.FAILED) {
  //    // this.exportFailed();
  //    this.successMsgForDownload = true;
  //    this.callErrorMsg('error', '', 'An error occurred, please contact customerloyalty@atlascopco.com');
  //  }
  //});

  //// need to wait till permission set done
  //this.sessionService.sessionPermissionSet.subscribe(dada => {
  //  this.appService.CheckUserPermissionByKey("VIEW_DASHBOARD").then(data => {
  //    this.ViewDashboard = data ? true : false;
  //    this.checkPermission();
  //  }).catch(() => {
  //    this.ViewDashboard = false;
  //    this.checkPermission();
  //  })
  //})

  //this.sLHmLoadOnlineHelpSubject = this.sharedService.sLHmLoadOnlineHelpSubject.subscribe(isLoad => {
  //  if (isLoad !== null) {
  //    this.showOnlineHelpIcon(isLoad);
  //  }
  //});

  //private boolLeafNodePermission: boolean = false;
  //errorCountPermissionCheck: boolean = false;
  //private dataDownloadPermissionCheck: boolean = false; //for transactional data download
  private dataDownloadDistPermissionCheck: boolean = false; //for distributor data download

  objNotifn = {
    Task: [],
    Survey: [],
    Rule: [],
    Template: []
  }
  notificationSubscription = new Subscription();
  ngOnDestroy() {
    this.globFilterSub.unsubscribe();
    this.globFilterRSub ? this.globFilterRSub.unsubscribe(): '';
    this.dashboardStateSub.unsubscribe();
    this.sLHmLoadOnlineHelpSubject.unsubscribe();
  }

  selectItem(index): void {
    this.selectedIdx = index;
  }

  removeIndex() {
    this.selectedIdx = -1;
  }

  showModal() {
    const ref = this.dialogService.open(AddTemplateComponent, {
      // data: {
      //   id: '51gF3'
      // },s
      header: 'Survey Template Details',
      width: '70%',
      height: '70%'
    });

    ref.onClose.subscribe(() => {
      // alert('No need to reload')
      // alert('yooooooooooooooo')
    });
  }

  showCreateSurveyModal() {
    let skipIntro = false;
    this._createSurveyServce.userAccessGetDetails().then(res => {
      if (res != null && res.Status === 'Success' && res.Response.length > 0) {
        res.Response[0].Preferences.forEach(element => {
          const pref = element.split('=');
          if (
            pref.length > 1 &&
            pref[1] === 'true' &&
            pref[0] === 'CREATE_TRANS_SURVEY_INTRO'
          ) {
            skipIntro = true;
          }
        });
      }

      if (!skipIntro) {
        const ref = this.dialogService.open(StartSurveyComponent, {
          header: 'Survey Details',
          width: '70%',
          height: '70%'
        });
      } else {
        this.router.navigate(['/surveys/create-survey']);
      }
    });
  }

  // highlight menuitem based on active route
  public menuClick(event: any) {

    this.removeIndex();

    event.preventDefault();
    var text = event.target.innerText.trim().toUpperCase();
    if (text !== 'DASHBOARD' && text !== 'FALCON SETUP' && text !== 'SURVEY SETUP' && text !== 'FEEDBACK ZONE') {

      const anchorTags = this.menulist.nativeElement.querySelectorAll('a');
      for (let j = 0; j <= anchorTags.length; j++) {
        if (anchorTags[j]) {
          if (anchorTags[j].classList.contains('active')) {
            this.renderer.removeClass(anchorTags[j], 'active');
          }
        }
      }
      const listTags = this.menulist.nativeElement.querySelectorAll('li');
      for (let i = 0; i <= listTags.length; i++) {
        if (listTags[i]) {
          if (listTags[i].classList.contains('active')) {
            this.renderer.removeClass(listTags[i], 'active');
          }
        }
      }
      this.render.setElementClass(event.currentTarget, 'active', true);
      this.render.setElementClass(event.target, 'active', true);
    }

    this.visibilitySubMenu = false;
    this.visibilitySubMenu1 = false;
    this.visibilitySubMenu2 = false;
    this.visibilitySubMenu3 = false;
  }

  handleDashbordViewClick(event: any) {
    this.headerService.saveDefaultDashboardView(event).then((res) => {
      let url = event === 'CTLS_DASHBOARD' ? 'dashboard' : 'trend-analysis';
      this.jsonData[0].submenuData[5].routerLink = `/ctls/${url}`;
      this.isDashboardView = event === 'CTLS_DASHBOARD' ? true : false;
      this.isTrendAnalysisView = event === 'TREND_ANALYSIS' ? true : false;
      this.router.navigate([`/ctls/${url}`]);
    });
  }

  getDefaultScreenData() {    
    /* this.default_view = localStorage.getItem('default_view')
    if (!this.default_view) {
      this.headerService.GetDefaultScreen().then((res) => {
        let default_view = res.Response.DefaultView;
        this.default_view = res.Response.DefaultView; */
        // localStorage.setItem('default_view',this.default_view);
        // this.isDashboardView = default_view === "CTLS_DASHBOARD" ? true : false;
        // this.isTrendAnalysisView = default_view === "TREND_ANALYSIS" ? true : false;
        // let url = default_view === 'CTLS_DASHBOARD' ? 'dashboard' : 'trend-analysis';
        // if (this.jsonData && this.jsonData[0])
        //   this.jsonData[0].submenuData[5].routerLink = `/ctls/${url}`;
      // })
    // } else {
      this.isDashboardView = this.default_view === "CTLS_DASHBOARD" ? true : false;
      this.isTrendAnalysisView = this.default_view === "TREND_ANALYSIS" ? true : false;
      let url = this.default_view === 'CTLS_DASHBOARD' ? 'dashboard' : 'trend-analysis';
      if (this.jsonData && this.jsonData[0])
        this.jsonData[0].submenuData[5].routerLink = `/ctls/${url}`;
    // }
    /* let viewsParsed = JSON.parse(localStorage.getItem('views'));
    if(viewsParsed && viewsParsed.length > 0) {
      this.defaultView = viewsParsed;
    }else {
      await this.headerService.getDefaultDashboardView().subscribe((res: any) => {
        if (res && res.Response)
          this.defaultView = res.Response
        localStorage.setItem('views', JSON.stringify(this.defaultView))
      })
    } */
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.default_view && changes.default_view.currentValue) {
      this.getDefaultScreenData();
    }
    if (changes && changes.defaultView && changes.defaultView.currentValue) {
      this.setDefaultLandingRoute();
    }
  }

  async ngOnInit() {
    this.showBusyIndicator = true;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.current_url = this.router.url;
      }
    });
    // this.GetNotificationsMessage()
    this.GetNotificationsCount()
    // this.getDefaultScreenData();
    this.sharedService.openNotifications();

    // this.setDefaultLandingRoute();
    this.topIconDisplay = 'none';
    this.imageUrl = this.imagepath + 'thunderstorm3.gif';
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.url = event.urlAfterRedirects;
        this.setDefaultLandingRoute();
        this.ShowOnlineHelpMode(this.url);
      }
    });
    this.GlobalFilterRecords();

    this.DisplayErrorCount();
    setInterval(() => {
      if (this.ErrorTimer) {

        this.DisplayErrorCount();
      }
    }, 300000);

    /*  Events to update catagory-wise lists, based on shared service. */
    this.sharedService.widgetLeftLocationSubject.subscribe(widgetOrderlistSubject => {
      Array.isArray(widgetOrderlistSubject) ? this.widgetLeftOrderList = cloneDeep(widgetOrderlistSubject) : null;
      this.chartExportValidation(this.widgetLeftOrderList);
    });

    this.sharedService.widgetNpsLocationSubject.subscribe(widgetOrderlistSubject => {
      Array.isArray(widgetOrderlistSubject) ? this.widgetNpsOrderList = cloneDeep(widgetOrderlistSubject) : null;
    });

    this.sharedService.widgetCenterLocationSubject.subscribe(widgetOrderlistSubject => {
      Array.isArray(widgetOrderlistSubject) ? this.widgetCenterOrderList = cloneDeep(widgetOrderlistSubject) : null;
      if(this.widgetCenterOrderList && this.widgetCenterOrderList.length > 0 )
        this.setDefaultview();
    });

    this.sharedService.widgetDDLocationSubject.subscribe(widgetOrderlistSubject => {
      Array.isArray(widgetOrderlistSubject) ? this.widgetDDOrderList = cloneDeep(widgetOrderlistSubject) : null;
      this.chartExportValidation(this.widgetDDOrderList);
      if(this.widgetDDOrderList && this.widgetDDOrderList.length > 0 )
        this.setDefaultview();
    });

    this.sharedService.widgetSALocationSubject.subscribe(widgetOrderlistSubject => {
      Array.isArray(widgetOrderlistSubject) ? this.widgetSAOrderList = cloneDeep(widgetOrderlistSubject) : null;
      this.chartExportValidation(this.widgetSAOrderList);
      if(this.widgetSAOrderList && this.widgetSAOrderList.length > 0 )
        this.setDefaultview();
    });
  }

  private parseMsgNotification(data) {

    if (!!data) {
      if (data.Status == "SUCCESS") {
        if (!!data.Response.Task) {
          this.objNotifn.Task = data.Response.Task;
        } else {
          this.objNotifn.Task = [];
        }
        if (!!data.Response.Survey) {
          this.objNotifn.Survey = data.Response.Survey;
        } else {
          this.objNotifn.Survey = [];
        }
        if (!!data.Response.Template) {
          this.objNotifn.Template = data.Response.Template;
        } else {
          this.objNotifn.Template = [];
        }
        if (!!data.Response.Rule) {
          this.objNotifn.Rule = data.Response.Rule;
        } else {
          this.objNotifn.Rule = [];
        }
        this.notificationResponse = this.objNotifn;
        this.showBusyIndicator = !this.showBusyIndicator;
        console.log("this.showBusyIndicator", this.showBusyIndicator)

      }
    } else {
      this.objNotifn.Task = [];
      this.objNotifn.Survey = [];
      this.objNotifn.Template = [];
      this.objNotifn.Rule = [];
    }
    console.log("this.showBusyIndicator", this.showBusyIndicator)
  }
  private parseCountNotification(data) {
    this.notificationCount = data.Count ? data.Count : "0";
  }
  private GetNotificationsMessage() {
    this.showBusyIndicator = true;
    this.notificationSubscription = this.ctlsNotificationService
      .getNotificationMessages()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.parseMsgNotification(data);

      })
  }
  private GetNotificationsCount() {
    console.log("From Header")
    this.ctlsNotificationService
      .GetNotificationsCount()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.parseCountNotification(data);
        //this.notificationCount = data.Count;
      })
  }

  chartExportValidation(widgetOrderList: any[]) {
    for (let i = 0; i < widgetOrderList.length; i++) {
      const item = widgetOrderList[i];
      // if (ChartDataParsing.chartsToExport.includes(item.WidgetCode.toLowerCase())) {
      //   if (item.IsVisible && item.IsDefaultView) {
      //     ChartDataParsing.chartsCurrentlyVisible.push(JSON.parse(JSON.stringify(item)));
      //     ChartDataParsing.chartsCurrentlyVisible = _.uniqBy(ChartDataParsing.chartsCurrentlyVisible, 'WidgetCode');
      //   }
      // }
    }
    // if (widgetOrderList.length > 0)
    //   this.dropDownPopulation();

    // this.sharedService.updateChartExportSubject('TEST');
    // this.readyCharts = ChartDataParsing.getLoadedChartListForExport();
  }
  // dropDownPopulation() {
  //   this.downloadPanelMouseEnter()
  //   let loaded: ExportChartModel[] = this.exportChartService.getLoadedChartList();
  //   this.readyCharts = [];
  //   if (Array.isArray(loaded) && loaded.length > 0) {
  //     for (let i = 0; i < loaded.length; i++) {
  //       const arrayItem = loaded[i];
  //       this.readyCharts.push({
  //         label: loaded[i]['DisplayName']
  //         , value: loaded[i].ChartName
  //       });
  //     }
  //   }
  //   // this.sharedService.updateChartExportSubject('TEST');
  //   // this.readyCharts = ChartDataParsing.getLoadedChartListForExport();
  // }

  downloadPanelMouseEnter() {
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('');
    this.showDownloadPanel = true;
    this.showRightMenuDiv = false;
    this.DownloadXSLWidgetWidthSet();
  }

  downloadPanelMouseLeave() {
    this.showDownloadPanel = false;
  }
  downloadPanelClick(event) {
    //  if(!(event && event.srcElement && event.srcElement.offsetParent &&  event.srcElement.offsetParent.classList.contains('download-detail'))){

    //   if(!this.showDownloadPanel){
    //    this.hideOtherOpenDrilldownsService.drillDownClickEvent('');
    //    this.showDownloadPanel = true;
    //    this.showRightMenuDiv = false;
    //    this.DownloadXSLWidgetWidthSet();
    //    } else{
    //      this.showDownloadPanel = false;
    //    }

    //  }  
    if (!(event && event.target && event.target.nodeName === 'BUTTON')) {
      this.hideOtherOpenDrilldownsService.drillDownClickEvent('');
      this.showDownloadPanel = true;
      this.showRightMenuDiv = false;
      this.DownloadXSLWidgetWidthSet();


    }
    else {
      this.showDownloadPanel = false;
    }


  }
  closeDownloadPannel() {
    this.showDownloadPanel = false;
  }

  /*  Utility function which search for visible component.*/
  searchRecordFromList(widgetCode, list = []) {

    // for lost business visibility
    if (widgetCode === 'LBCM' && list.length > 0) {
      this.IsLBCMVisible = list.find(e => e.WidgetCode === 'LBCM').IsVisible;
      if (this.IsLBCMVisible) {
        this.IsLBWidgetDisable = false;
      } else {
        this.IsLBWidgetDisable = true;
        if (this.XLList.includes('LostBusiness')) {
          this.ResetLostBusinessWidgets();
        }
      }
    }

    if (list.length <= 0) {
      return { IsApplicable: false };
    } else {
      return list.filter(item => (item.WidgetCode == widgetCode))[0];
    }
  }

  /*  Default view cheque from db. */
  setDefaultview() {
    this.isCenterChecked = this.widgetCenterOrderList.every(item => {
      return item.IsDefaultView == true;
    });
    if(this.widgetSAOrderList.length > 0)
      this.isSAChecked = this.widgetSAOrderList.every(item => {
        return item.IsDefaultView == true;
      });
    else
      this.isSAChecked = false
    if(this.widgetDDOrderList.length > 0)
      this.isDDChecked = this.widgetDDOrderList.every(item => {
        return item.IsDefaultView == true;
      });
    else
      this.isDDChecked = false

    if(this.isCenterChecked) {
      this.isDDChecked = false;
      this.isSAChecked =  false
    } else {
      if(this.isDDChecked){
        this.isCenterChecked = false;
        this.isSAChecked =  false
      }   
      if(this.isSAChecked){
        this.isCenterChecked = false;
        this.isDDChecked = false;
      }
        
    }
  }

  setDefaultViewParameter(listSet) {
    if (listSet.length > 0) {
      listSet.map(val => {
        val.IsDefaultView = true;
      });
    }
  }

  unSetDefaultViewParameter(listSet) {
    if (listSet.length > 0) {
      listSet.map(val => {
        val.IsDefaultView = false;
      });
    }
  }

  // For global filter
  GlobalFilterRecords() {
    this.filterService.getUserFilter().then(() => {
      this.GetData();
    }).catch(e => {
      // this.optOutRule = null;
      this.successMsg = true;
      this.callErrorMsg('error', '', this.genericErrorMessage);
    });
  }

  GetData() {
    const res = this.filterService.filterObj;
    this.parameter = {
      StartDate: res.StartDate === '' ? null : res.StartDate,
      EndDate: res.EndDate === '' ? null : res.EndDate,
      PeriodType: res.PeriodType,
      BACode: res.BACode == null ? null : res.BACode.replace(/[|]/g, ','),
      DivCode: res.DivCode == null ? null : res.DivCode.replace(/[|]/g, ','),
      BLCode: res.BLCode == null ? null : res.BLCode.replace(/[|]/g, ','),
      Country: res.Country == null ? null : res.Country.replace(/[|]/g, ','),
      FAMCode: res.FAMCode == null ? null : res.FAMCode.replace(/[|]/g, ','),
      BrandName:
        res.BrandName == null ? null : res.BrandName.replace(/[|]/g, ','),
      Zone: res.Zone == null ? null : res.Zone.replace(/[|]/g, ','),
      Branch: res.Branch == null ? null : res.Branch.replace(/[|]/g, ','),
      SalesEngineer:
        res.SalesEngineer == null
          ? null
          : res.SalesEngineer.replace(/[|]/g, ','),
      ServiceTechnician:
        res.ServiceTechnician == null
          ? null
          : res.ServiceTechnician.replace(/[|]/g, ','),
      Touchpoint:
        res.Touchpoint == null ? null : res.Touchpoint.replace(/[|]/g, ','),
      Continent:
        res.Continent == null ? null : res.Continent.replace(/[|]/g, ','),
      Region: res.Region == null ? null : res.Region.replace(/[|]/g, ','),
      Influencer:
        res.Influencer == null ? null : res.Region.replace(/[|]/g, ','),
      RequiredInSurveyLanguage: 'true',
      ReportType: ''
    };
    this.exportData(this.parameter);
    // this.responseRateExport(this.parameter, paramModel);

  }

  // For export feature
  exportData(event) {
    let fileName;

    if (event === 'NPS') {
      fileName = 'NPS';
      this.parameter.ReportType = 'NPS';
      this.loading = true;
      this.responseRateExport(this.parameter, fileName);
    } else if (event === 'RESPONSE') {
      fileName = 'ResponseRate';
      this.parameter.ReportType = 'RESPONSE';
      this.loading = true;
      this.responseRateExport(this.parameter, fileName);
    } else if (event === 'TOP5') {
      fileName = 'Top5';
      this.parameter.ReportType = 'TOP5';
      this.loading = true;
      this.responseRateExport(this.parameter, fileName);
    } else if (event === 'NPSCancellation') {
      fileName = 'ClickCancel';
      this.parameter.ReportType = 'NPSCancellation';
      this.loading = true;
      this.responseRateExport(this.parameter, fileName);
    } else if (event === 'LostBusiness') {
      fileName = 'Lost_Business_Download';
      this.parameter.ReportType = 'LostBusiness';
      this.loading = true;
      this.responseRateExport(this.parameter, fileName);
    } else if (event.toString().indexOf('CHARTS_') > -1) {
      // this.sharedService.updateChartExportSubject(event.toString());
      this.showDownloadPanel = false;
      setTimeout(() => {
        this.sharedService.updateChartExportSubject(event.toString());
        // this.exportChartsToReports(event.toString().split('_')[1]);
      }, 300);
    }
  }

  updateExportList() {
    this.exportChartService.setExportList(this.selectedChartsForExport);
  }

  exportChartsToReports(exportFormat: string) {
    // let payload = ChartDataParsing.assembleExportPayload(this.globalFilterData['StartDate'], this.globalFilterData['EndDate'], exportFormat, this.selectedChartsForExport);

    let payload = this.exportChartService.assembleBlobUploadPayload(this.globFilterSelectedData, this.globalFilterData, exportFormat);
    let result = [];
    let context = this;
    this.loading = true;

    this.receivedCount = 0;
    this.results = [];
    // check if widget without image to export is being ExportSlideData, if yes this.assembleFinalExportPayload
    if (Object.keys(payload).length === 0 && payload.constructor === Object) {
      this.assembleFinalExportPayload([]);
    } else {
      for (const key in payload) {
        const element = payload[key];
        this.receivedCount = this.receivedCount + element.length;
      }

      for (const key in payload) {
        // if (payload.hasOwnProperty(key)) {
        const element = payload[key];
        this.headerService.saveImagesForExport(element).subscribe(i => {
          this.assembleFinalExportPayload(i['Response']);
        });
        // }
      }
    }

    // for (const key in payload) {
    //   const element = payload[key];
    //   this.receivedCount = this.receivedCount + element.length;
    // }

    // for (const key in payload) {
    //   // if (payload.hasOwnProperty(key)) {
    //   const element = payload[key];
    //   this.headerService.saveImagesForExport(element).subscribe(i => {
    //     this.assembleFinalExportPayload(i['Response']);
    //   });
    //   // }
    // }
    this.selectedChartsForExport = [];
    this.loading = false;
  }

  assembleFinalExportPayload(response: any[]) {
    this.results = [...this.results, ...response];
    if (this.receivedCount === this.results.length) {
      // send image blob links
      let payload = this.exportChartService.assembleFinalExportPayload(this.results);
      if (payload.length > 0) {
        // this.pptExportBlockUI = true;
        this.pptExport(payload);
      }
    }
  }

  private pptExport = (payload: any) => {
    this.headerService.createChartExports(payload).subscribe(res => {
      this.pptExportBlockUI = false;
      if (res['Status'] === 'Success' && res['Response'].length > 0) {
        this.downloadPptxFile(res['Response']);
      } else if (res['Status'] === 'Failure') {
        console.error('Failed PPTX export');
        this.successMsgForDownload = true;
        this.callErrorMsg('error', '', 'An error occurred, please contact customerloyalty@atlascopco.com');
      }
    });
  }

  private downloadPptxFile = (url: string) => {
    let a = document.createElement('A');
    a['href'] = url;
    a['download'] = url.substr(url.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }


  Checkbox(event, value = '') {
    this.count = this.XLList.length;
    if (this.count == 0) {
      this.checked = false;
    } else {
      this.checked = true;
    }
    if (value === 'LostBusiness') {
      if (this.XLList.includes(value)) {
        this.isShowLostBusinessSubMenu = true;
        this.getLBCountlist();
      } else {
        this.isShowLostBusinessSubMenu = false;
        this.boolLoadLostBusinessSM = false;
        this.LostBusinessXLList = [];
      }
    }
    this.CheckXLSDownloadDisabled();
  }

  downloadExcel() {
    this.XLListlength = this.XLList.length;
    for (var i = 0; i < this.XLList.length; i++) {
      if (this.XLList[i] == 'NPS') {
        this.loading = true;
        this.exportData('NPS');
      }
      if (this.XLList[i] == 'RESPONSE') {
        this.loading = true;
        this.exportData('RESPONSE');
      }
      if (this.XLList[i] == 'TOP5') {
        this.loading = true;
        this.exportData('TOP5');
      }
      if (this.XLList[i] == 'NPSCancellation') {
        this.loading = true;
        this.exportData('NPSCancellation');
      }
      if (this.XLList[i] == 'LostBusiness') {
        this.loading = true;
        this.exportData('LostBusiness');
      }
      // console.log(this.XLListlength);
      // console.log(this.XLList[i]);
      // if (this.excelreport == 1) {
      //   this.exportData('NPS');
      // }
      // if(this.excelreport == 2){
      //   this.exportData('RESPONSE');
      // }
      // if(this.excelreport == 3){
      //   this.exportData('TOP5');
      // }
      // if(this.excelreport == 4){
      //   this.exportData('NPSCancellation');
      // }
    }
    this.showDownloadPanel = false;
  }


  responseRateExport(parameter, fileName) {
    const param = this.filterService.filterObj;
    param['RequiredInSurveyLanguage'] = this.parameter.RequiredInSurveyLanguage;
    param['ReportType'] = this.parameter.ReportType;

    if (this.XLList.includes('LostBusiness') && fileName === 'Lost_Business_Download') {
      const param1 = this.lbService.filterObj1;
      param['BasePeriodStartDate'] = param1.startBasePeriod;
      param['BasePeriodEndDate'] = param1.endBasePeriod;
      param['CompPeriodStartDate'] = this.headerService.CompPeriodStartDate;
      param['CompPeriodEndDate'] = this.headerService.CompPeriodEndDate;
      let sheetlist = _.cloneDeep(this.LostBusinessXLList.sort());
      for (var sh in sheetlist) {
        if (sheetlist[sh] === 'BasePeriod') {
          sheetlist[sh] = 'Base Period';
        } else if (sheetlist[sh] === 'ComparisonPeriod') {
          sheetlist[sh] = 'Comparison Period';
        } else if (sheetlist[sh] === 'ContinuedToDoBusiness') {
          sheetlist[sh] = 'Continued to do business';
        } else if (sheetlist[sh] === 'Didnotcontinuetodobusiness') {
          sheetlist[sh] = 'Did not continue to do business';
        }
      }
      param['LostBusinessSheets'] = sheetlist.map(e => e).join(',');
    }

    this.headerService
      .responseRateExportExcel(param)
      .then(rawData => {
        // code for time out or unauthorize
        if (rawData.size === 0 && rawData.type === 'text/plain') {
          this.storageService.clearAllExceptRememberMe();
          window.location.href = './auth/login';
          console.log('header export excel logout');
          return;
        }

        const rawResponse: any = rawData;
        if (rawData.size > 0) {
          this.converBlobtToFile(rawResponse, fileName + '_' + this.datetimestamp() + '.xlsx');

        } else {

          this.successMsgForDownload = true;
          this.callErrorMsg('error', '', this.genericErrorMessage);
        }
        this.loading = false;
      })
      .catch(e => {
        this.loading = false;
        this.successMsgForDownload = true;
        this.callErrorMsg('error', '', this.genericErrorMessage);
      });
  }

  converBlobtToFile(theBlob: Blob, fileName: string) {
    if (navigator.appVersion.toString().indexOf('.NET') > 0) {
      window.navigator.msSaveBlob(theBlob, fileName);
    } else {
      const file: any = new Blob([theBlob], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }
  }

  datetimestamp() {
    var today = new Date();
    var sToday = (((today.getMonth() + 1) < 10 ? '0' : '') + (today.getMonth() + 1)).toString();
    sToday += ((today.getDate() < 10 ? '0' : '') + today.getDate()).toString();
    sToday += today.getFullYear().toString() + '_';
    sToday += (today.getHours() < 10 ? '0' : '') + today.getHours().toString();
    sToday += (today.getMinutes() < 10 ? '0' : '') + today.getMinutes().toString();
    return sToday;
  }

  // severity: info/success/warn/error
  callErrorMsg(type, summary = '', detail = '') {
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    window.scrollTo(0, 0);
    setTimeout(function () {
      document.getElementById('ClearMsg') && document.getElementById('ClearMsg').click();
    }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    if (this.successMsg) {
      this.successMsg = false;
    }
    if (this.successMsgForDownload) {
      this.successMsgForDownload = false;
    }
  }

  checkhasMenuList(json: any, index, index1) {
    const len = json.length;

    for (let j = 0; j < len; j++) {
      const element = json[j];
      if (this.firstMenuList === -1) {
        this.firstMenuList = j;
      }
      if (element.hasOwnProperty('menuList')) {
        this.secondMenuList = j;
        this.secondLevelPermissionCheck.length = 0;
        this.secondLevelPermissionCheck = [];
        this.checkhasMenuList(element.menuList, index, index1);
      }

      if (element.hasOwnProperty('pvkey')) {
        let res = this.getPermission(element);
        if (this.secondMenuList === -1) {
          // 1nd level menu list
          this.firstLevelPermissionCheck.push(res);
          this.headerJson[index]['submenuData'][index1]['menuList'][j]['hasRights'] = res;
          if (res) {
            this.boolLeafNodePermission = res;
          }

        } else {
          this.secondLevelPermissionCheck.push(res);
          // 2nd level menu list
          this.headerJson[index]['submenuData'][index1]['menuList'][
            this.secondMenuList
          ]['menuList'][j]['hasRights'] = res;
          if (res) {
            this.boolLeafNodePermission = res;
          }
        }
      }

      if (this.secondMenuList === -1) {
        if ((j + 1) == (len)) {
          // this.headerJson[index]["hasRights"] = boolLeafNodePermission;
          this.headerJson[index]['submenuData'][index1]['hasRights'] = this.checkArrayisTruthy(this.firstLevelPermissionCheck);
        }
      } else {
        if ((j + 1) == (len)) {
          // this.headerJson[index]["hasRights"] = true;
          this.headerJson[index]['submenuData'][index1]['hasRights'] = true;

          let truthy = this.checkArrayisTruthy(this.secondLevelPermissionCheck);
          this.headerJson[index]['submenuData'][index1]['menuList'][this.secondMenuList]['hasRights'] = truthy;
        }
      }
      this.headerJson[index]['hasRights'] = this.boolLeafNodePermission;

    }

  }

  checkArrayisTruthy(arr) {
    for (let index = 0; index < arr.length; index++) {
      if (arr[index]) {
        return true;
      }
    }
    return false;
  }

  checkPermission() {
    this.showCFMenuPersimission = true;
    this.appService.CheckUserPermissionByKey('VIEW_DATA_ERROR_TRACKING').then(data => {
      if (data) {
        this.errorCountPermissionCheck = true;
      } else {
        this.errorCountPermissionCheck = false;
      }
    }).catch(err => {
      this.errorCountPermissionCheck = false;
    });

    this.appService.CheckUserPermissionByKey('VIEW_CANCELCLICK_REPORT').then(data => {
      if (data) {
        this.viewNPSCancellation = true;
      } else {
        this.viewNPSCancellation = false;
      }
    }).catch(err => {
      this.viewNPSCancellation = false;
    });

    this.appService.CheckUserPermissionByKey('DOWNLOAD_DATA_DASHBOARD').then(data => {
      if (data) {
        this.dataDownloadPermissionCheck = true;
      } else {
        this.dataDownloadPermissionCheck = false;
      }
    }).catch(err => {
      this.dataDownloadPermissionCheck = false;
    });


    this.appService.CheckUserPermissionByKey('DOWNLOAD_DATA_DIST_DASHBOARD').then(data => {
      if (data)
        this.dataDownloadDistPermissionCheck = true;
      else
        this.dataDownloadDistPermissionCheck = false;
    }).catch(err => {
      this.dataDownloadDistPermissionCheck = false;
    })
    const ViewMenu = {
      topheader: '360 VIEW',
      icon: 'fa-repeat',
      mainmenuImageurl: this.imagepath + '360_main_icon.png',

      submenuData: [
        {
          pvkey: 'CTLS_360',
          headerText: "360 View",
          submenuImageurl: this.imagepath + 'ctls-360-logo-mm.png',
          routerLink: ['/ctls/360'],
          headerMenuName: '',
          submenuDesc: this.getViewPermitedMenu(),


        },
      ],
    };
    // const ViewMenu = {
    //   topheader: '360 View',
    //   icon: 'fa-repeat',
    //   mainmenuImageurl: '../images/customer-service.png',
    //   submenuData: [
    //     {
    //       pvkey: 'CTLS_CUSTOMER_360',
    //       headerText: '360 View',
    //       submenuImageurl: '../images/configuration.png',
    //       routerLink: ['ctls/360'],
    //       headerMenuName: '',
    //       // submenuDesc: this.getViewMenu(),


    //     },
    //   ],
    // };
    const ctlsMenu = {
      topheader: 'CLOSE THE LOOP',
      icon: 'fa-repeat',
      mainmenuImageurl: this.imagepath + 'customer-service.png',
      submenuData: [
        {
          pvkey: 'CTLS_SURVEY_CONFIGURATION',
          headerText: 'Set Up',
          submenuImageurl: this.imagepath + 'configuration.png',
          routerLink: ['/ctls/setup'],
          headerMenuName: '',
          submenuDesc: this.getCtlsSetupPermitedMenu(),
        },
        {
          pvkey: 'CTLS_TASK_SURVEY_FEEDBACK',
          headerText: 'Task Management',
          submenuImageurl: this.imagepath + 'list-text.svg',
          routerLink: ['/ctls/task-feedback'],
          headerMenuName: '',
          submenuDesc: this.getCtlsFeedbackPermitedMenu(),
        },
        {
          pvkey: 'CTLS_MASTERS',
          headerText: 'Masters',
          submenuImageurl: this.imagepath + 'masters-ico.svg',
          routerLink: ['/ctls/masters'],
          headerMenuName: '',
          submenuDesc: this.getCtlsMastersPermitedMenu(),
        },
        {
          pvkey: 'CTLS_CONFIGURATION',
          headerText: 'Configuration',
          submenuImageurl: this.imagepath + 'configuration-ico.svg',
          routerLink: ['/ctls/configurations'],
          headerMenuName: '',
          submenuDesc: this.getCtlsConfigurationsPermitedMenu(),
        },
      ],
    };

    const masterScreenMenu = {
      topheader: 'MASTER SCREEN',
      icon: 'fa-database',
      submenuData: [
        {
          pvkey: 'MSTR_MASTER',
          headerText: 'Master Data Setup',
          submenuImageurl: this.imagepath + 'database-icon-img.png',
          routerLink: ['falcon/setup/sales-channel-mapping'],
          headerMenuName: '',
          submenuDesc: this.getMasterDataPermitedMenu(),
        },
      ]
    };

    // line no 1207 ViewMenu add this varible it will display 360 view menu
    //this.headerJson = [...this.headerJson, ctlsMenu, masterScreenMenu];

    /* Enable 360 menu if any one of sub menu exist */
    if (this.getViewPermitedMenu().length > 0) {
      // this.headerJson = [...this.headerJson, ctlsMenu, ViewMenu];
      const newHeaderJson = this.headerJson.filter(element => {
        if (element.topheader.includes('CLOSE THE LOOP') || element.topheader.includes('360 VIEW')) {
          return false;
        }
        return true;
      });
      newHeaderJson.push(ctlsMenu, ViewMenu);
      this.headerJson = newHeaderJson;
    } else {
      //this.headerJson = [...this.headerJson, ctlsMenu];
      const newHeaderJson = this.headerJson.filter(element => {
        if (element.topheader.includes('CLOSE THE LOOP')) {
          return false;
        }
        return true;
      });
      newHeaderJson.push(ctlsMenu);
      this.headerJson = newHeaderJson;
    }
    if (this.getMasterDataPermitedMenu().length) {
      //this.headerJson = [...this.headerJson, masterScreenMenu];
      const newHeaderJson = this.headerJson.filter(element => {
        if (element.topheader.includes('MASTER SCREEN')) {
          return false;
        }
        return true;
      });
      newHeaderJson.push(masterScreenMenu);
      this.headerJson = newHeaderJson;
    }

    const len = this.headerJson.length;
    for (let index1 = 0; index1 < len; index1++) {
      const element = this.headerJson[index1];
      const subMenuData = element['submenuData'];
      const submenulen = subMenuData.length;
      this.boolLeafNodePermission = false;
      this.headerJson[index1].links = index1 === 0 ? ['/ctls/trend-analysis'] : [];
      for (let index2 = 0; index2 < submenulen; index2++) {
        const submenuData = subMenuData[index2];
        if (submenuData.routerLink) {
          if (submenuData.routerLink instanceof Array) {
            if (submenuData.routerLink[0].startsWith('/'))
              this.headerJson[index1].links.push(submenuData.routerLink[0]);
            else
              this.headerJson[index1].links.push(`/${submenuData.routerLink[0]}`);
          } else {
            if (submenuData.routerLink.startsWith('/'))
              this.headerJson[index1].links.push(submenuData.routerLink);
            else
              this.headerJson[index1].links.push(`/${submenuData.routerLink}`);
          }
        }
        if (submenuData.menuList) {
          submenuData.menuList.forEach((item) => {
            if (item.routerLink) {
              if (item.routerLink instanceof Array) {
                if (item.routerLink[0].startsWith('/'))
                  this.headerJson[index1].links.push(item.routerLink[0]);
                else
                  this.headerJson[index1].links.push(`/${item.routerLink[0]}`);
              } else {
                if (item.routerLink.startsWith('/'))
                  this.headerJson[index1].links.push(item.routerLink);
                else
                  this.headerJson[index1].links.push(`/${item.routerLink}`);
              }
            }
            if (item.menuList) {
              item.menuList.forEach((item1) => {
                if (item1.routerLink) {
                  if (item1.routerLink.startsWith('/'))
                    this.headerJson[index1].links.push(item1.routerLink);
                  else
                    this.headerJson[index1].links.push(`/${item1.routerLink}`);
                }
              });
            }
          });
        }
        if (submenuData.submenuDesc instanceof Array) {
          if (submenuData.submenuDesc) {
            submenuData.submenuDesc.forEach((item1) => {
              if (item1.routerLink) {
                if (item1.routerLink.startsWith('/'))
                  this.headerJson[index1].links.push(item1.routerLink);
                else
                  this.headerJson[index1].links.push(`/${item1.routerLink}`);
              }
            });
            if (this.headerJson[index1].topheader === '360 VIEW') {
              this.headerJson[index1].links.push('/ctls/360/customers?isDistributor=true')
            }
          }
        }
        if (submenuData.hasOwnProperty('menuList')) {
          this.firstMenuList = -1;
          this.secondMenuList = -1;
          this.permissioncheckStart = true;
          this.firstLevelPermissionCheck.length = 0;
          this.secondLevelPermissionCheck.length = 0;
          this.checkhasMenuList(submenuData.menuList, index1, index2);
        } else {
          let res = this.getPermission(submenuData);
          this.headerJson[index1]['submenuData'][index2][
            'hasRights'
          ] = res;
          if (res) {
            this.headerJson[index1]['hasRights'] = true;
          }
        }
        this.jsonData = this.headerJson;

      }
    }
    this.jsonData = this.headerJson;
    let default_view = localStorage.getItem('dashboard_default_view');
    if (default_view == null) {
      localStorage.setItem('dashboard_default_view', 'dashboard');
      default_view = 'dashboard';
    }
    this.jsonData[0].submenuData[5].routerLink = `/ctls/${default_view}`;
  }

  getPermission(json: any) {
    if (json.hasOwnProperty('pvkey')) {
      return this.appService.getPermissionValue(json.pvkey);
    }
    return true;
  }

  getPermissionWithKey(pvkey) {
    return this.appService.getPermissionValue(pvkey);
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  DisplayErrorCount() {
    this.headerService.GetErrorCount().then(rawData => {
      this.topIconDisplay = 'block';
      this.ErrorTimer = true;

      if (rawData && rawData.Response === null) {
        this.ShowError = true;
        this.ShowErrorResponse = true;
      } else if (this.ErrorCount <= 1 && rawData && rawData.Response != null) {
        this.ShowError = (this.ErrorCount !== rawData.Response) ? false : true;
        this.ErrorCount = rawData.Response;
        this.ShowErrorResponse = false;

      } else if (rawData && this.ErrorCount != rawData.Response) {
        // this.ShowError = false;

        this.ErrorCount = rawData.Response;
        this.ShowError = (this.ErrorCount == 0) ? true : false;
        this.ShowErrorResponse = false;
      } else if (rawData && this.ErrorCount == rawData.Response) {
        this.ShowError = true;
        this.ErrorCount = rawData.Response;
        this.ShowErrorResponse = false;

      } else if (rawData && this.ErrorCount == 0) {
        this.ShowError = true;
        this.ErrorCount = rawData.Response;
        this.ShowErrorResponse = false;
      }
      if (!this.ShowError) {
        this.imageUrl = this.imagepath + 'thunderstorm2.gif';
        this.StartCountdown(3);
      } else {
        this.imageUrl = this.imagepath + 'thunderstorm3_static.gif';
      }


    });
  }

  DisplayBlinkingImage() {
    // wait for 3 secs or 3 blinks
    this.imageUrl = this.imagepath + 'thunderstorm3_static.gif';
  }

  StartCountdown(seconds: number) {
    var counter = seconds;
    var interval = setInterval(() => {
      counter--;
      if (counter < 0) {
        this.DisplayBlinkingImage();
        clearInterval(interval);
      }
    }, 1000);
  }

  DisplayErrorWithtimer() {
    this.ErrorTimer = false;
  }

  /*  Performs select / deselect operations */
  widgetLeftSelect(widgetCode) {
    this.widgetLeftOrderList.map((item) => {
      if (item.WidgetCode == widgetCode) {
        item.IsVisible ? item.IsVisible = false : item.IsVisible = true;
      }
    });
  }

  /*  Performs select / deselect operations */
  widgetNpsSelect(widgetCode) {
    this.widgetNpsOrderList.map((item) => {
      if (item.WidgetCode == widgetCode) {
        item.IsVisible ? item.IsVisible = false : item.IsVisible = true;
      }
    });
  }

  /*  Performs select / deselect operations */
  widgetCenterSelect(widgetCode) {
    this.widgetCenterOrderList.map((item) => {
      if (item.WidgetCode == widgetCode) {
        item.IsVisible ? item.IsVisible = false : item.IsVisible = true;
      }
    });
  }

  /*  Performs select / deselect operations */
  widgetDDSelect(widgetCode) {
    this.widgetDDOrderList.map((item) => {
      if (item.WidgetCode == widgetCode) {
        item.IsVisible ? item.IsVisible = false : item.IsVisible = true;
      }
    });
  }

  widgetSASelect(widgetCode) {
    this.widgetSAOrderList.map((item) => {
      if (item.WidgetCode == widgetCode) {
        item.IsVisible ? item.IsVisible = false : item.IsVisible = true;
      }
     
    });
  }

  submitViewSelector() {
    /*  Combine all lists into one. */
    let widgetListToSend = [...this.widgetLeftOrderList, ...this.widgetNpsOrderList, ...this.widgetCenterOrderList, ...this.widgetDDOrderList,...this.widgetSAOrderList];
    this.boolViewSelectorComponentLoader = true;
    // api call to save update list
    this.dashboardWidgetService.postAddUpdateWidgetLocation(widgetListToSend).then(res => {
      this.sharedService.changeWidgetSavedOrderList('saved');
      this.boolViewSelectorComponentLoader = false;
      this.viewSelectorSubmitSuccess = true;
      this.showRightMenuDiv = false;
    }).catch(e => {
      this.successMsg = true;
      this.boolViewSelectorComponentLoader = false;
      this.callErrorMsg('error', '', this.genericErrorMessage);
    });

    this.showRightMenuDiv = false;

  }

  /*  Checkbox value change event to check for ticked value. */
  onChange(checkBox) {
    if (checkBox == 'Center') {
      if(this.isCenterChecked){
        this.isDDChecked = false; 
        this.isSAChecked = false
      } else {
        if(this.isDDChecked) {
          this.isDDChecked = true;
          this.isSAChecked = false;
        }
          
        if(this.isSAChecked) {
          this.isSAChecked = true
          this.isDDChecked = false;
        }
      }
      this.setDefaultViewParameter(this.widgetCenterOrderList);
      this.unSetDefaultViewParameter(this.widgetDDOrderList);
      this.unSetDefaultViewParameter(this.widgetSAOrderList);
    } else if (checkBox == 'DD') {
      if(this.isDDChecked){
        this.isCenterChecked = false; 
        this.isSAChecked = false
      } else {
        if(this.isCenterChecked){
          this.isCenterChecked = true;
          this.isSAChecked = false
        }
          
        if(this.isSAChecked) {
          this.isSAChecked = true;
          this.isCenterChecked = false;
        }
          
      }
      this.unSetDefaultViewParameter(this.widgetCenterOrderList);
      this.setDefaultViewParameter(this.widgetDDOrderList);
      this.unSetDefaultViewParameter(this.widgetSAOrderList);
    } else if (checkBox == 'SA') {
      if(this.isSAChecked){
        this.isDDChecked = false; 
        this.isCenterChecked = false
      } else {
        if(this.isCenterChecked) {
          this.isCenterChecked = true;
          this.isDDChecked = false;
        }
          
        if(this.isDDChecked) {
          this.isDDChecked = true;
          this.isCenterChecked = false;
        }
          
      }
      this.setDefaultViewParameter(this.widgetSAOrderList);
      this.unSetDefaultViewParameter(this.widgetDDOrderList);
      this.unSetDefaultViewParameter(this.widgetCenterOrderList);
    }
  }

  clickedRightmenu() {
    this.showRightMenuDiv ? this.showRightMenuDiv = false : this.showRightMenuDiv = true;
    this.showDownloadPanel = false;
    this.hideDiv = false;
  }

  setDefaultLandingRoute() {
    this.headerService.LandingPageCode = this.headerService.LandingPageCode ? this.headerService.LandingPageCode : 'TD'
    console.log('here landing page ', this.headerService.LandingPageCode)
    if (this.defaultView && this.defaultView.length > 0) {
      this.defaultView.forEach(element => {
        console.log('result')
        if (element.ScreenKey === this.headerService.LandingPageCode) {
          console.log('key check')
          this.defaultLandingPageUrl = element.ScreenURL
          if (this.default_view && this.headerService.LandingPageCode === 'CTL') {
            let url = this.default_view === 'CTLS_DASHBOARD' ? 'dashboard' : 'trend-analysis';
            this.defaultLandingPageUrl = `/ctls/${url}`;
          }
        }
      });
    }
    console.log('landing page url', this.defaultLandingPageUrl)
  }

  widgetToDownloadEvent(event) {
    // this.sharedService.updateChartExportSubject(event.toString());
    this.showDownloadPanel = false;
    this.pptExportBlockUI = true;
    setTimeout(() => {
      this.sharedService.updateChartExportSubject(event);
      // this.exportChartsToReports(event.toString().split('_')[1]);
    }, 300);
    this.downloadPanelMouseLeave();
    setTimeout(() => {
      this.pptExportBlockUI = false;
    }, 90000);
  }

  changeTab(index) {
    this.tabIndex = index;
  }

  handleChange(e) {
    // XLS = index e=1
    // PPT = index e=0
    if (e.index == 0) {
      this.IsXLSSelected = false;
    } else {
      this.IsXLSSelected = true;
      this.DownloadXSLWidgetWidthSet();
    }
  }

  // for Online help integration and feedback
  showOnlineHelp() {
    this.isFeedBackActive = false;
    this.sharedService.clickHeaderFalconBird(true);
  }

  showOnlineHelpIcon(isLoad) {
    this.isShowFalconImg = isLoad;
  }

  // for online help integration
  ShowOnlineHelpMode(url) {
    this.sharedService.isOnlineHelpMode = false;
    document.getElementById('BodyTag-Falcon').className = '';
    let helpAvailable = this.HelpnavDetails.find(e => e.pageUrl === url);
    if (helpAvailable !== undefined) {
      this.isShowOnlineHelpIcon = true;
    } else {
      this.isShowOnlineHelpIcon = false;
    }
  }

  ChangeHelpMode() {
    if (this.sharedService.isOnlineHelpMode) {
      this.sharedService.isOnlineHelpMode = false;
      document.getElementById('BodyTag-Falcon').className = '';
    } else {
      this.sharedService.isOnlineHelpMode = true;
      document.getElementById('BodyTag-Falcon').className = 'body-helpMode';
    }
  }

  onMyViewHover() {
    this.hideOtherOpenDrilldownsService.drillDownClickEvent('');
    this.hideDiv = false;
  }

  // lost business
  CheckXLSDownloadDisabled() {
    if (this.isShowLostBusinessSubMenu && this.LostBusinessXLList.length == 0) {
      this.btnXSLDownloadDisabled = true;
    } else {
      this.btnXSLDownloadDisabled = false;
      this.LostBusinessSuccessMsg = false;
    }
  }

  CheckboxLostBusiness() {
    this.CheckXLSDownloadDisabled();
    this.totalLostCount = 0;
    if (this.LostBusinessXLList.includes('BasePeriod')) {
      this.totalLostCount += this.cntBasePeriod;
    }
    if (this.LostBusinessXLList.includes('ComparisonPeriod')) {
      this.totalLostCount += this.cntComparisonPeriod;
    }
    if (this.LostBusinessXLList.includes('ContinuedToDoBusiness')) {
      this.totalLostCount += this.cntContinuedToDoBusiness;
    }
    if (this.LostBusinessXLList.includes('Didnotcontinuetodobusiness')) {
      this.totalLostCount += this.cntDidnotcontinuetodobusiness;
    }
    if (this.totalLostCount > this.MaxLBRecordCount) {
      this.btnXSLDownloadDisabled = true;
      this.LostBusinessSuccessMsg = true;
    } else {
      this.LostBusinessSuccessMsg = false;
    }
  }

  updateLogoLink(menuId) {
    this.headerService.LandingPageCode = menuId;
    this.setDefaultLandingRoute();
  }

  convertToKformate(value = 0) {
    if (value == 0 || value < 1000) {
      return value.toString();
    } else {
      let rem = value % 1000;
      if (rem == 0) {
        return (value / 1000).toString() + 'K';
      } else {
        return (value / 1000).toFixed(1).toString() + 'K';
      }
    }
  }

  getLBCountlist() {
    this.boolLoadLostBusinessSM = true;
    this.totalLostCount = 0;
    this.headerService.getLBCountlist().subscribe(res => {
      if (res == null) {
        this.successMsgForDownload = true;
        this.callErrorMsg('error', '', this.genericErrorMessage);
        this.boolLoadLostBusinessSM = false;
        this.isShowLostBusinessSubMenu = false;
        this.setDefaultBLCount();
        return;
      }
      if (res.Status === 'Success') {
        this.boolLoadLostBusinessSM = false;
        this.cntBasePeriod = res.Response.BasePeriodCount;
        this.cntComparisonPeriod = res.Response.ComparisonPeriodCount;
        this.cntContinuedToDoBusiness = res.Response.ContinuedtodoBusinessPeriodCount;
        this.cntDidnotcontinuetodobusiness = res.Response.StoppedDoingTransactionsCount;
      } else {
        this.successMsgForDownload = true;
        let errorMsg = '';
        if (res.Errors != null && res.Errors.length > 0) {
          errorMsg = res.Errors.map(e => e.Message).join(', ');
        } else {
          errorMsg = this.genericErrorMessage;
        }
        this.boolLoadLostBusinessSM = false;
        this.isShowLostBusinessSubMenu = false;
        this.callErrorMsg('error', '', errorMsg);
        this.setDefaultBLCount();
      }
    }, err => {
      this.setDefaultBLCount();
      this.boolLoadLostBusinessSM = false;
      this.successMsgForDownload = true;
      this.isShowLostBusinessSubMenu = false;
      this.callErrorMsg('error', '', this.genericErrorMessage);
    });
  }

  setDefaultBLCount() {
    this.cntBasePeriod = 0;
    this.cntComparisonPeriod = 0;
    this.cntContinuedToDoBusiness = 0;
    this.cntDidnotcontinuetodobusiness = 0;
    this.totalLostCount = 0;
  }

  ResetLostBusinessWidgets() {
    this.LostBusinessXLList = [];
    this.totalLostCount = 0;
    this.isShowLostBusinessSubMenu = false;
    this.LostBusinessSuccessMsg = false;
    this.XLList = this.XLList.filter(e => e !== 'LostBusiness');
    this.count = this.XLList.length;
    this.boolLoadLostBusinessSM = false;
    if (this.count === 0) {
      this.checked = false;
    } else {
      this.checked = true;
    }
  }

  DownloadXSLWidgetWidthSet(): void {
    if (this.IsXLSSelected) {
      const xlsCount = document.querySelectorAll('#divID_download_xls .dwnl_xls_widget').length;
      this.DwnloadXLSClass = 'class_dwnl_xls_' + xlsCount.toString();
      if (xlsCount < 5) {
        this.xlsLBOWidth = 100;
      } else {
        this.xlsLBOWidth = 75;
      }
    }
  }

  /**
   * get permitted menu list for ctls config. setup module
   * @returns menu list
   */
  getCtlsSetupPermitedMenu() {
    let arrKeyMenulist = [];

    if (this.ctlsCommonService.allowPermission(this.strSdaListingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strSdaListingUrl, name: 'SDA Setup' });
    }
    if (this.ctlsCommonService.allowPermission(this.strRuleSetListingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strRuleSetListingUrl, name: 'Rule Setup' });
    }
    if (this.ctlsCommonService.allowPermission(this.strRuleAssignmentPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strRuleAssignmentUrl, name: 'Rule Assignment' });
    }
    if (this.ctlsCommonService.allowPermission(this.strAssigneeMappingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strAssigneeMappingUrl, name: 'Assignee Mapping' });
    }
    if (this.ctlsCommonService.allowPermission(this.strConfiguarationPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strConfiguarationUrl, name: 'CTLS Enable / Disable' });
    }
    if (this.ctlsCommonService.allowPermission(this.strOperationalHoursPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strOperationalHoursUrl, name: 'Operational Hours/Days' });
    }
    if (this.ctlsCommonService.allowPermission(this.strClosureTemplateListingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strClosureTemplateListingUrl, name: 'Closure Template Listing' });
    }
    if (this.ctlsCommonService.allowPermission(this.strTPListingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strTPListingUrl, name: this.configTP.strSpan2 });
    }

    return arrKeyMenulist
  }

  /**
   * get permitted menum list for ctls feedback module
   * @returns menulist
   */
  getViewPermitedMenu() {
    let arrKeyMenulist = [];
    if (this.ctlsCommonService.allowPermission(this.strCustomerPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strCustomersUrl, name: 'Customers' });
    }
    if (this.ctlsCommonService.allowPermission(this.strDistributorPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strDistributorsUrl, name: 'Distributors' });
    }
    return arrKeyMenulist
  }
  /**
   * get permitted menum list for ctls feedback module
   * @returns menulist
   */
  getCtlsFeedbackPermitedMenu() {
    console.log("strTaskListingPermission", this.strTaskListingPermission, this.strTaskSurveyFeedbackPermission);

    let arrKeyMenulist = [];
    if (this.ctlsCommonService.allowPermission(this.strTaskListingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strTaskListingUrl, name: 'Task Listing' });
    }
    if (this.ctlsCommonService.allowPermission(this.strTaskSurveyFeedbackPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strTaskSurveyFeedbackUrl, name: 'Survey Feedback Listing' });
    }
    if (this.ctlsCommonService.allowPermission(this.strVocListingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strVocListingUrl, name: 'Voice of Customer' });
    }
    return arrKeyMenulist
  }
  /**
   * get permitted menum list for ctls masters module
   * @returns menulist
   */
  getCtlsMastersPermitedMenu() {
    let arrKeyMenulist = [];
    if (this.ctlsCommonService.allowPermission(this.strPriorityMasterPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strPriorityMasterUrl, name: 'Priority' });
    }
    if (this.ctlsCommonService.allowPermission(this.strCategoryMasterPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strCategoryMasterUrl, name: 'Category' });
    }
    if (this.ctlsCommonService.allowPermission(this.strDesignationMasterPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strDesignationMasterUrl, name: 'Designation' });
    }
    if (this.ctlsCommonService.allowPermission(this.strRuleCriteriaListPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strRuleCriteriaListUrl, name: 'Rule Criteria' });
    }
    if (this.ctlsCommonService.allowPermission(this.strRecommendedActionListingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strRecommendedActionListingUrl, name: 'Recommended Action' });
    }

    return arrKeyMenulist
  }
  /**
   * get permitted menum list for ctls configurations module
   * @returns menulist
   */
  getCtlsConfigurationsPermitedMenu() {
    let arrKeyMenulist = [];

    arrKeyMenulist.push({ routerLink: '/' + this.strCustomerMappingUrl, name: 'Customer Mapping' });
    if (this.ctlsCommonService.allowPermission(this.strCustomerMappingPermission)) {
    }
    if (this.ctlsCommonService.allowPermission(this.strUsageManagementListingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strUsageManagementListingUrl, name: 'Usage Management' });
    }
    if (this.ctlsCommonService.allowPermission(this.strUsageManagementPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strUsageManagementUrl, name: 'Usage Management' });
    }
    if (this.ctlsCommonService.allowPermission(this.strNotificationListingPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strNotificationListingUrl, name: 'Notification Management' });
    }
    if (this.ctlsCommonService.allowPermission(this.strUnavailabilityListPermission)) {
      arrKeyMenulist.push({ routerLink: '/' + this.strUnavailabilityListUrl, name: 'Unavailability Management' });
    }


    return arrKeyMenulist
  }
  onViewSelected(event) {
    if (event) {
      this.boolViewSelectorComponentLoader = event.boolLoader ? event.boolLoader : false;
      this.showRightMenuDiv = event.showRightMenuDiv ? event.showRightMenuDiv : false;
      this.hideDiv = event.hideDiv ? event.hideDiv : false;
    }
  }
  refreshWidget(globalRefresh = true) {
    if (globalRefresh) {
      this.chartDataService.setRefreshWidgetData({ globalRefresh: true });
    }
  }

  getMasterDataPermitedMenu() {
    let arrKeyMenulist = [];
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.BusinessAreaMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.BusinessAreaMaster.url, name: MASTER_DATA_CONST.CONSTANTS.BusinessAreaMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.DivisionMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.DivisionMaster.url, name: MASTER_DATA_CONST.CONSTANTS.DivisionMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.BusinessLineMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.BusinessLineMaster.url, name: MASTER_DATA_CONST.CONSTANTS.BusinessLineMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.RegionMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.RegionMaster.url, name: MASTER_DATA_CONST.CONSTANTS.RegionMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.TimezoneMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.TimezoneMaster.url, name: MASTER_DATA_CONST.CONSTANTS.TimezoneMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.LanguageMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.LanguageMaster.url, name: MASTER_DATA_CONST.CONSTANTS.LanguageMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.InfluencerMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.InfluencerMaster.url, name: MASTER_DATA_CONST.CONSTANTS.InfluencerMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.InfluenceLevelMapping.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.InfluenceLevelMapping.url, name: MASTER_DATA_CONST.CONSTANTS.InfluenceLevelMapping.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.TouchpointMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.TouchpointMaster.url, name: MASTER_DATA_CONST.CONSTANTS.TouchpointMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.ServiceTypeMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.ServiceTypeMaster.url, name: MASTER_DATA_CONST.CONSTANTS.ServiceTypeMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.SalesChannelMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.SalesChannelMaster.url, name: MASTER_DATA_CONST.CONSTANTS.SalesChannelMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.SalesChannelMapping.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.SalesChannelMapping.url, name: MASTER_DATA_CONST.CONSTANTS.SalesChannelMapping.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.SalutationMaster.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.SalutationMaster.url, name: MASTER_DATA_CONST.CONSTANTS.SalutationMaster.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.SalutationGenderMapping.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.SalutationGenderMapping.url, name: MASTER_DATA_CONST.CONSTANTS.SalutationGenderMapping.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.MarketSubsector.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.MarketSubsector.url, name: MASTER_DATA_CONST.CONSTANTS.MarketSubsector.strTitle });
    }
    if (this.ctlsCommonService.allowPermission(MASTER_DATA_CONST.CONSTANTS.postCodeGrouping.managePermissionConstant)) {
      arrKeyMenulist.push({ routerLink: '/' + MASTER_DATA_CONST.CONSTANTS.postCodeGrouping.url, name: MASTER_DATA_CONST.CONSTANTS.postCodeGrouping.strTitle });
    }
    return arrKeyMenulist;
  }
  getDetailsElLength(elName) {
    let len = 0;
    if (this.elementRef.nativeElement && this.elementRef.nativeElement.querySelectorAll(elName).length &&
      this.elementRef.nativeElement.querySelectorAll(elName)[0].children.length) {
      len = this.elementRef.nativeElement.querySelectorAll(elName)[0].children.length;
    }
    if (len <= 9) {
      return 'box-normal';
    } else if (len == 10) {
      return 'box-ten';
    } else if (len > 10) {
      return 'box-large';
    } 
    return '';
  }
  onClickNotification(event) {
    if (event.showNotification) {
    this.GetNotificationsMessage();
    } else {
      this.notificationSubscription.unsubscribe();
    }
  }
}

