import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { of, Subject } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsVocEditScreenService {
  private editWidth$ = new Subject<any>();
  private drag$ = new Subject<any>();
  constructor(private http: HttpClient, private sharedService: SharedService) { }
  setWidth(data) {
    this.editWidth$.next(data);
  }
  getWidth(): Observable<any> {
    return this.editWidth$.asObservable();
  }
  setDrag(data) {
    this.drag$.next(data);
  }
  getDrag(): Observable<any> {
    return this.drag$.asObservable();
  }
  /**
   * Get assignee dropdowns
   */
  GetAssignee(params): Observable<any> {
    params.BA = !params.BA ? "" : params.BA;
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskForSurveyFeedback/GetAssignee`,
      params
    );
  }
  /**
   * Get new assignee dropdowns
   */
  GetFAMBAUsers(): Observable<any> {
    return this.http.get<any>(`${environment.baseUrlCTLS}/EditTask/GetFAMBAUsers`);
  }
  /**
   * resend email for closure
   */
  resend(data): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/ResendClosureTemplate`,
      {
        params: {
          cTLSEditTaskClosureTemplateID: data.cTLSEditTaskClosureTemplateID,
          brandID: data.brandID,
        },
      }
    );
  }
  /**
   * Get CommentHistory
   */
  GetCommentHistory(data): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/GetVOCCommentHistory?VOCTaskListID=${data.VOCTaskListID}&&skipCount=${data.skipCount}&&takeCount=${data.takeCount}`);
  }
  /** Translate data
   * @param dataObj
   */
  getTranslateWithGoogle(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/Translate/TranslateWithGoogle`,
      dataObj
    );
  }
  /**Service for save edit voc
   * @param dataObj
   */
  SaveTaskManagementDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/SaveVOCTaskManagementDetails`,
      dataObj
    );
  }
  /**Service for save edit transfer voc
   * @param dataObj
   */
  saveTransferTaskDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/VOCSendMail`,
      dataObj
    );
  }
 /**
   * Resend TaskDetails for save
   */
  getResendTransferTaskDetails(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/VOCReSendMail?VOCTaskListID=${dataObj.VOCTaskListID}&VOCTaskManagmentID=${dataObj.VOCTaskManagmentID}`,
      dataObj
    );
  }
  /**
   * Get VocDetails fetch after each save
   */
  GetTaskDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/GetTaskDetails?CTLSTaskListID=${dataObj.CTLSTaskListID}`,
      dataObj
    );
  }

  GetAllEnabledAssignees(dataObj): Observable<any> {
    let FAMID = dataObj.FAMID ? dataObj.FAMID : null,
      BAID = dataObj.BAID ? dataObj.BAID : null;
    return this.http.get<any>(`${environment.baseUrlCTLS}/TaskListing/VOCGetAssigneeDetails?FAM=${FAMID}&BA=${BAID}`);
  }
  /**Service for getting templates name for dropdown
   * @param dataObj
   */
  GetTemplateNames(params): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/GetTemplateNames`,
      {
        params: {
          BAID: params.BAID,
          FAMID: params.FAMID,
          brandID: params.BrandID,
          subCategory: params.SurveyType,
        },
      }
    );
  }
  /**Service for getting GetTaskDetailsByTaskID
   * @param id
   */
  GetTaskDetailsByTaskID(id): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetVOCTransactinalTaskDetailsByTaskID?VOCTaskListID=${id}`
    );
  }

  /**Service for getting GetVocDetailsByVocID
   * @param id
   */
  GetImgUrl(id): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/DownloadAttachment?cTLSTaskFilesListID=${id}`,
      {}
    );
  }

  /**Service for getting GetVocDetailsByVocID
   * @param id
   */
  GetAttachments(id): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/GetVOCAttachments?VOCTaskManagementID=${id}`);
  }

  /**Service for getting GetVocDetailsByVocID
   * @param id
   */
  GetFileUrl(id): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/VOCDownloadAttachment?VOCTaskFilesListID=${id}`,
      {}
    );
  }

  /**Service for update  edit voc last entere comment for the login user
  * @param dataObj
  */
  getFiles(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/EditTask/GetVOCFilesforEditing?VOCTaskManagementID=${dataObj.VOCTaskManagementID}`);
  }

  /**Service for update  edit voc last entere comment for the login user
* @param dataObj
*/
  UpdateComment(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/EditTask/UpdateVOCComment`,
      dataObj
    );
  }

  
  getMasterData(): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/BusinessFilters/GetMasterFilter`);
  }
  getCustomerList(req): Observable<any> {
    return this.http.post<any>(`${environment.baseUrlCTLS}/Customer360/GetAccountListVOC`, req);
  }


  /**Service for upload audio to GCP
   * @param data
   */
  uploadAudioToGCP(data) {
    let url = `${environment.baseUrl}api/Translation/UploadToCloud`;
    return this.http.post(url, data).
      toPromise()
      .then((res: any) => res)
      .then(data => { 
        return data;
      }).catch(error => {
        return error;
      });
  }

    /**Service for upload from Azure URL to GCP
   * @param data
   */
    uploadAzureToGCP(data) {
      let url = `${environment.baseUrl}api/Translation/UploadStreamToCloud`;
      return this.http.post(url, data).
        toPromise()
        .then((res: any) => res)
        .then(data => { 
          return data;
        }).catch(error => {
          return error;
        });
    }

  /**Service for upload audio to Azure
   * @param data
   */
  uploadAudioToAzure(data) {
    let url = `${environment.baseUrl}api/Translation/UploadAudioToBlob`;
    return this.http.post(url, data).
      toPromise()
      .then((res: any) => res)
      .then(data => { return data;
      }).catch(error => {
        return error;
      });
  }

  /**Service for audio to audio translation
   * @param data
   */
    translateAudioToAudio(data) {
      let url = `${environment.baseUrl}api/Translation/SpeechTranslation`;
      return this.http.post(url, data).
        toPromise()
        .then((res: any) => res)
        .then(data => {
          return data;
        }).catch(error => {
          return error;
        });
    }

  /**Service for audio to text translation
   * @param data
   */
  translateAudioToText(data) {
    let url = `${environment.baseUrl}api/Translation/SpeechToText`;
    return this.http.post(url, data).
      toPromise()
      .then((res: any) => res)
      .then(data => { 
        return data;
      }).catch(error => {
        return error;
      });
  }

  /**Service for text to audio translation
   * @param data
   */
  translateTextToAudio(data) {
    let url = `${environment.baseUrl}api/Translation/TextToSpeech`;
    return this.http.post(url, data).
      toPromise()
      .then((res: any) => res)
      .then(data => { 
        return data;
      }).catch(error => {
        return error;
      });
  }

    /**Service for audio to text translation
   * @param data
   */
    translateTextToText(data) {
      let url = `${environment.baseUrl}api/Translation/TextTranslate`;
      return this.http.post(url, data).
        toPromise()
        .then((res: any) => res)
        .then(data => { 
          return data;
        }).catch(error => {
          return error;
        });
    }
}
