
import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FIELDS } from 'src/app/shared/constants/FIELDS';
import { AuthService } from './auth-service.service';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor{
  constructor( private authService: AuthService, private storageService: StorageService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = this.getToken();
    if(req.url.includes('change-password')||req.url.includes('logout') ||req.url.includes('registerfalconuser') 
      || req.url.includes('ActivateFalconUsers') || req.url.includes('CloneUserPermissions') 
     ){
      req = req.clone(
        {
            headers: req.headers.set('Authorization', `Bearer ${token}`)
        });  
    }
    return next.handle(req).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
      },
      (error: HttpErrorResponse) => {
        
      }
    ));
     
  }

  getToken(): string | null {
    return localStorage.getItem(FIELDS.ACCESS_TOKEN) ? localStorage.getItem(FIELDS.ACCESS_TOKEN) : "";
  }
}