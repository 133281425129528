import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NpsResponselistService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }
  private serviceUrl = this.sharedService.configuration.baseUrl;
//   getNPSResponseList(page, size,sortField,sortType,NPSResponsemodel) {
//     return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetNPSRespondedList?pageNo=" + page + "&pageSize=" + size + "&sortField" + sortField + "&sortType" + sortType,NPSResponsemodel )
//       .toPromise()
//       .then((res: any) => {
//         return res;
//       });
//   }

// }

getNPSResponseList(sortField,sortType,page, size,NPSResponsemodel) {
  return this.http.post(environment.baseUrlDashboard + "/Dashboard/GetNPSRespondedList?sortField=" + sortField + "&sortType=" + sortType + "&pageNo=" + page + "&pageSize=" + size,NPSResponsemodel )
    .toPromise()
    .then((res: any) => {
      return res;
    });
}
}
