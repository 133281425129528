import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Inject, OnInit, forwardRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from '../oauth2.config';
import { AppComponent } from '../app.component';
import { FIELDS } from '../shared/constants/FIELDS';

@Component({
  selector: 'app-signin-oidc',
  templateUrl: './signin-oidc.component.html',
  styleUrls: ['./signin-oidc.component.scss']
})
export class SigninOidcComponent implements OnInit {
  code: any;
  sessionstate: any;
  state: any;
  scope: any;
  loader: boolean=false;
  constructor(private oauthService: OAuthService,private route: ActivatedRoute,
    private http: HttpClient, public router: Router,
    @Inject(forwardRef(() => AppComponent)) private app_component:AppComponent) { }

 async ngOnInit() {
  this.loader=true;

  try {
    const tokenResponse = localStorage.getItem(FIELDS.ACCESS_TOKEN);
    this.sendData(tokenResponse);
    console.log('Token Response:', tokenResponse);
  } catch (error) {
    console.error('Token exchange error:', error);
  }
    this.oauthService.configure(authConfig)

    this.loader=false;
  }

  sendData(data){
    //this.app_component.initialSetup(data) // need to pass some data to that component
    this.app_component.ngOnInit()
  }

}
