import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as Rx from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ExportChartModel, SlideTextData } from '../models/export-chart.model';
import { IMAGE_EXTRACTION } from '../enums/export-state-enum';
import { CHART_STATE_OPTIONS } from '../enums/chart-state-options.enum';
import { ChartDataParsing } from '../utils/chart-data-parsing.util';
import * as signalR from '@microsoft/signalr'
import { Configuration} from './../../shared/constants/shared.constants';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public configuration: IConfiguration;

  public isNpstpOpen: boolean = false;

  // for feedback pop up with falcon bird
  hmSlonBirdClickSubject = new Rx.BehaviorSubject<any>(null);
  hmSlonBirdClickSubjectResponse = new Rx.BehaviorSubject<any>(null);
  showBirdIcon: boolean = true;
  showBird: boolean;
  sLHmLoadOnlineHelpSubject = new Rx.BehaviorSubject<any>(null);
  countNotificationSubject = new Rx.BehaviorSubject<any>([]);
  mgsNotificationSubject = new Rx.BehaviorSubject<any>([]);
  grantManageRuleSetTabAccess = new Rx.BehaviorSubject<any>(false);
  // for feedback pop up with falcon bird

  chartToggleParamBehaviousSubject = new Rx.BehaviorSubject('');
  widgetLeftLocationSubject = new Rx.BehaviorSubject<any>('WidgetLeftLocation');
  widgetNpsLocationSubject = new Rx.BehaviorSubject<any>('WidgetNpsLocation');
  widgetCenterLocationSubject = new Rx.BehaviorSubject<any>('WidgetCenterLocation');
  widgetDDLocationSubject = new Rx.BehaviorSubject<any>('WidgetDDLocation');
  widgetSALocationSubject = new Rx.BehaviorSubject<any>('WidgetSALocation');
  widgetSavedOrderListSubject = new Rx.BehaviorSubject<any>('WidgetSavedOrderlist');
  widgetCloseSubject = new Rx.BehaviorSubject<any>('widgetClose');
  // chartExportSubject = new Rx.BehaviorSubject<{ updatedOn: Date, exportFormat: string } | null>(null);
  chartExportSubject = new Rx.BehaviorSubject<string[] | null>(null);
  allowChartExportingSubject = new Rx.BehaviorSubject<boolean>(false);
  chartStatusSubject: Rx.BehaviorSubject<ExportChartModel | null> = new Rx.BehaviorSubject<ExportChartModel | null>(null);
  imgExtractStatusSubject: Rx.BehaviorSubject<IMAGE_EXTRACTION | null> = new Rx.BehaviorSubject<IMAGE_EXTRACTION | null>(null);
  chartTextSubject: Rx.BehaviorSubject<SlideTextData | null> = new Rx.BehaviorSubject<SlideTextData | null>(null);

  dashboardStateSubject = new Rx.BehaviorSubject<string>('');
  errorCountSubject = new Rx.BehaviorSubject<boolean>(false);
  STORYBOARD_VIEW: string = 'storyboard';
  TRENDLINE_VIEW: string = 'chartview';
  SENTIMENTAL_VIEW: string = 'sentimentview';
  EMAIL_VIEW: string = 'emailview';

  widgetConfig: any[] = [];
  // required Widgets' code for PPT/PDF Export
  exportFamComparison: string[] = ['zone', 'branch', 'engineer', 'technician','ProjectManager'];
  chartExportWidgetCodes: string[] = [];
  acceptableChartState: CHART_STATE_OPTIONS[] = [CHART_STATE_OPTIONS.SUCCESS, CHART_STATE_OPTIONS.NO_DATA];
  ConfigData:any;
  updatechartTextSubject(slideTextData: SlideTextData) {
    this.chartTextSubject.next(slideTextData);
  }
  updateChartStatusSubject(chartDetails: ExportChartModel) {
    this.chartStatusSubject.next(chartDetails);
  }
  updateImgExtractStatusSubject(param: IMAGE_EXTRACTION | null) {
    this.imgExtractStatusSubject.next(param);
  }

  updateChartToggleParameter(chartViewBy: string) {
    this.chartToggleParamBehaviousSubject.next(chartViewBy);
  }

  changeWidgetLeftLocation(widgetlocationLeft: any) {
    this.widgetLeftLocationSubject.next(widgetlocationLeft);
  }

  changeWidgetNpsLocation(widgetLocationNps: any) {
    this.widgetNpsLocationSubject.next(widgetLocationNps);
  }

  changeWidgetCenterLocation(widgetLocationCenter: any) {
    this.widgetCenterLocationSubject.next(widgetLocationCenter);
  }

  changeWidgetDDLocation(widgetLocationDD: any) {
    this.widgetDDLocationSubject.next(widgetLocationDD);
  }

  changeWidgetSALocation(widgetLocationSA: any) {
    this.widgetSALocationSubject.next(widgetLocationSA);
  }

  changeWidgetSavedOrderList(widgetOrderSavedMsg: any) {
    this.widgetSavedOrderListSubject.next(widgetOrderSavedMsg);
  }

  updateChartExportSubject(exportWhat: string[] | null) {
    // this.chartExportSubject.next(exportFormat === null ? null : { updatedOn: new Date(), exportFormat: exportFormat });
    this.chartExportWidgetCodes = exportWhat;
    this.chartExportSubject.next(this.chartExportWidgetCodes);
  }

  // for feedback pop up with falcon bird
  clickHeaderFalconBird(isClicked: any) {
    this.hmSlonBirdClickSubject.next(isClicked);
  }
  ActivateHeaderFeedBack(isClick) {
    this.hmSlonBirdClickSubjectResponse.next(isClick)
  }
  loadOnlineHelpGrid(isLoad: any) {
    this.sLHmLoadOnlineHelpSubject.next(isLoad);
  }
  // for feedback pop up with falcon bird

  // for online help
  public isOnlineHelpMode: boolean = false;
  // for online help

  // Appsettings - Shared code

  constructor(private http: HttpClient) { 
    this.ConfigData=Configuration;
  }

  openNotifications() {


    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(environment.baseUrl + 'notify',{
        skipNegotiation: true, transport: signalR.HttpTransportType.WebSockets
      })
      .build();

    connection.start().then(function () {
      console.log('SignalR Connected!');
    }).catch(function (err) {
      return console.error(err.toString());
    });

    connection.on("BroadcastMessage", () => {
      console.log("from service")
      this.loadnotifyCount();
      this.loadnotify();
    });
  }


  loadnotifyCount() {
    return this.http.get<INotificationCountResult>(environment.baseUrl + 'api/Notification/NotificationCount')
      .toPromise()
      .then(result => {
        this.countNotificationSubject.next(result);
        console.log('connection start', result)
      }, error => console.error(error));
  }

  loadnotify() {
    return this.http.get<any>(environment.baseUrl + 'api/Notification/NotificationMessages')
      .toPromise()
      .then(result => {
        this.mgsNotificationSubject.next(result);
        console.log('notification list', result)
      }, error => console.error(error));
  }

  getNotificationMessages(): Rx.Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/Notification/NotificationMessages`,
    );
  }
  getEmailNotificationMessages(emailNotifyId): Rx.Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/Notification/GetEmailNotificationMessagesByID?ID=${emailNotifyId}
      
      `,
    );
  }
  dismissBellNotification(notiIds) {
    return this.http.put<any>(environment.baseUrl + 'api/Notification/DismissBellNotifications', notiIds)
      .toPromise()
      .then(result => {
        console.log('deleted result', result)
      }, error => console.error(error));
  }




  // This function call only one time which will get all appsettings and hold in configuration variable
  loadConfig() {
    // return this.http.get<IConfiguration>(environment.baseUrl + 'api/Configuration/GetConfigurationData')
    //   .toPromise()
    //   .then(result => {
    //     this.configuration = <IConfiguration>(result);
    //     ChartDataParsing.dashBoardUrlImage = this.configuration.DashboardImagePath;
    //   }, error => console.error(error));
    this.configuration=this.ConfigData;
    console.log("SHARED--",this.configuration)
  }


  GetFeedbackImageStatus() {
    return this.http.get(`${this.configuration.baseUrl}api/Dashboard/FeedbackImageStatus`)
      .toPromise()
      .then((res: any) => {
        //console.log(res)
        this.showBird = (res.Response == "true" || res.Response == true) ? true : false;
        return res.Response;
      }).catch(e => {
        console.log(e)
      });
  }
  FeedbackImageDisplayUpdate(data) {
    return this.http.post(this.configuration.baseUrl + 'api/Dashboard/FeedbackImageDisplayUpdate' + data, {
      "Status": data.toString()
    })
      .toPromise()
      .then((res: any) => {
        //console.log(res)
        this.showBird = (res.Response == "true" || res.Response == true) ? true : false;
        return res.Response;
      }).catch(e => {
        console.log(e)
      });
  }
  // this function return the value when we pass key
  getConfigSettingByKey(key) {
    if (key) {
      return this.configuration[key];
    } else {
      return '';
    }
  }

  // Appsettings - End

  /* GET USER PROFILE DETAILS */

  getUserDetails() {
    return this.http.get<any>(environment.baseUrl + 'api/MDM/UserProfile/User' + '?buster=' + new Date().getTime())
      .toPromise()
      .then(result => {
        console.log("shared service", result);
        let roleID = result.Response.SelectedRoles[0].RoleId;
        let userID = result.Response.UserID;
        let pageNumber = 1;
        let pageSize = 10;
        let sortOrder = null;
        let sortField = null;
        const urlParams = '?roleID=' + roleID + '&requestedUserID=' + userID
          + '&pageNo=' + pageNumber + '&pageSize=' + pageSize + '&sortType=' + sortOrder + '&sortField=' + sortField;
        this.getAccessList(urlParams);
      }, error => console.error(error));


  }

  getAccessList(urlParams) {
    return this.http.get(environment.baseUrl + 'api/MDM/UserManagement/AccessControls/UserAccessList' + urlParams + '&buster=' + new Date().getTime())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        if (data.Response !== null) {
          console.log("shared service", data);
          /* CHECK ONLY ONE BA NOT MORE THAN ONE OR ALL */
          let lstBA = data.Response.Collection[0].BAs.toUpperCase().split(',');
          let lstFAM = data.Response.Collection[0].FAMs.toUpperCase().split(',');
          console.log("shares service", lstBA, lstFAM,)
          if (lstBA.length == 1 && lstBA[0] !== 'ALL'
            && lstFAM.length == 1 && lstFAM[0] !== 'ALL'
          ) {
            this.grantManageRuleSetTabAccess.next(true);
          }

        }

        //return data;
      });
  }
  getUserCookie(){
    return this.http.get<any>(environment.baseUrlAuth + 'api/Account/GetUserProfile')
    .toPromise()
    .then(result => {    
      return result;
    }, error => console.error(error));
  }

  getAccessListCheck(urlParams) {
    return this.http.get(environment.baseUrl + 'api/MDM/UserManagement/AccessControls/UserAccessList' + urlParams + '&buster=' + new Date().getTime())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        if (data.Response !== null) {
          console.log("shared service", data);
          /* CHECK ONLY ONE BA NOT MORE THAN ONE OR ALL */
          let lstFAM = data.Response.Collection[0].FAMs.toUpperCase().split(',');
          console.log("shares service", lstFAM,)
          if (lstFAM.length == 1 && lstFAM[0] !== 'ALL'
          ) {
            return true
          } else {
            return false;
          }

        }

        //return data;
      });
  }


  
  getRRTrendData(filterObj: any | null, optionalReq: any = {}) {
    if (!(Object.keys(optionalReq) && Object.keys(optionalReq).length)) {
      return this.http.post(environment.baseUrl + 'api/Dashboard/GetResponseRateTrend360', filterObj)
    } else {
      return this.http.post(environment.baseUrl + 'api/Dashboard/GetResponseRateTrend360', { ...filterObj, ...optionalReq })
    }
  }
}

// AppSettings
// The settings coming from Appsettings
// When we add new settings that need to add in below interface
export interface IConfiguration {
  APIURL: string;
  IdentityURL: string;
  BlobImageURL: string;
  ContainerName: string;
  FolderName: string;
  AzureBlobStorageConnectionString: string;
  ContactUsEmail: string;
  MessageTimeOut: number;
  baseUrl: string;
  dragDropTimeOut: number;
  CustomerSupportEmailID: string;
  AccessDeniedUrl: string;
  MaxLBRecordCount: number;
  DashboardImagePath: string;
}
export interface INotificationCountResult {
  count: number;
}

