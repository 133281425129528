import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as Rx from "rxjs";



@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private serviceUrl = this.sharedService.configuration.baseUrl;
  public GlobalDateChanged = true;

  filterObj = {
    "StartDate": "",
    "EndDate": "",
    "PeriodType": "",
    "BACode": "",
    "FAMCode": "",
    "DivCode": "",
    "BLCode": "",
    "ProjectManager": "",
    "Country": "",
    "OperationalCountry": "",
    "BrandName": "",
    "SalesEngineer": "",
    "Touchpoint": "",
    "Influencer": "",
    "ServiceTechnician": "",
    "Continent": "",
    "Region": "",
    "Zone": "",
    "Branch": "",
    "PinnedField": "",
    "PostCodes": "",
    "GroupNames": "",
    "ActionType": ""
  }
  selectedFilters = {
    baCount: [],
    divCount: [],
    famCount: [],
    blCount: [],
    pmCount:[],
    countryCount: [],
    operationalCountryCount: [],
    brandCount: [],
    zoneCount: [],
    branchCount: [],
    salesCount: [],
    serviceCount: [],
    touchpointCount: [],
    influencerCount: [],
    continentCount: [],
    postcodeCount: [],
    groupPostcodeCount: [],
    regionCount: []
  }

  globalFilterBehaviousSubject = new Rx.BehaviorSubject(this.filterObj);
  globalFilterSelectedFilterSubject = new Rx.BehaviorSubject(this.selectedFilters);

  constructor(private http: HttpClient, private sharedService: SharedService) {

  }


  getAllData() {
    // this.setStorage(this.serviceUrl + 'api/mdm/BusinessAreas');
    return this.http.get(this.serviceUrl + "api/Dashboard/GetMasterFilterData?" + Math.random())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  getAllDataForSDA(surveyId, surveyType) {
    // this.setStorage(this.serviceUrl + 'api/mdm/BusinessAreas');
    return this.http.get(this.serviceUrl + "api/Dashboard/GetSDAMasterFilterData?surveySubCategory=''&surveyType=" + surveyType + "&surveyId=" + surveyId)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  getAllDataForTP() {
    // this.setStorage(this.serviceUrl + 'api/mdm/BusinessAreas');
    return this.http.get(this.serviceUrl + "api/TouchPointTask/GetMasterFilter")
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
  getSavedDataForTp(id){
    return this.http.get(this.serviceUrl + "api/TouchPointTask/GetTouchPointTaskConfigSettingsByID?CTLSTouchPointTaskID=" + id)
    .toPromise()
    .then((res: any) => res)
    .then(data => {
      return data;
    });
  }
  saveUserFilter(filterObject, isDate: boolean) {
    return this.http.post(this.serviceUrl + "api/Dashboard/AddUpdateUserFilter?IsDateParam=" + isDate, filterObject)
      .toPromise()
      .then((res: any) => { return res });
  }

  getUserFilter(): Promise<any> {
    return new Promise((resolve, reject) => {
      let filterData = JSON.parse(window.localStorage.getItem("globalFilterObject"));
      if (!filterData) {
        this.http.get(this.serviceUrl + "api/Dashboard/GetUserFilter?" + Math.random())
          .toPromise()
          .then((res: any) => {
            if (res != null && res.Status == "Success" && res.Response != null) {
              // var filterObjectString = localStorage.getItem("globalFilterObject");
              // if (filterObjectString == null) {
                /* res.Response.StartDate = null;
                res.Response.EndDate = null; */
                this.mapFilterObject(res.Response, true);
                localStorage.setItem("globalFilterObject", JSON.stringify(res.Response));
              // }
            }
            this.globalFilterBehaviousSubject.next(this.filterObj);
            return resolve(res);
          }).catch(e => {
            return reject({
            })
          });
      }
      else {
        var filterObjectStringDate = localStorage.getItem("periodFilterObject");
        if (filterObjectStringDate != null) {
          var dateFilters = JSON.parse(filterObjectStringDate)
          this.filterObj.StartDate = dateFilters.StartDate;
          this.filterObj.EndDate = dateFilters.EndDate;
          this.filterObj.PeriodType = dateFilters.PeriodType;
        }
        this.mapFilterObject(filterData, false);
        this.globalFilterBehaviousSubject.next(this.filterObj);
        return resolve(this.filterObj);
      }
    })
  }

  clearUserFilter() {
    return this.http.post(this.serviceUrl + "api/Dashboard/ClearUserFilter", null)
      .toPromise()
      .then((res: any) => { return res });
  }

  dataOriginal = [];
  pmOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  countryOpCountryOriginal = [];
  operationalCountryContinentOriginal = [];
  brandOriginal = [];
  engineerAndTechnicianOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];
  postcodeOriginal = [];
  groupPostcodeOriginal = [];

  mapFilterObject(obj, includeDateFields) {
    if (includeDateFields) {
      this.filterObj.StartDate = obj.StartDate == null ? "" : obj.StartDate;
      this.filterObj.EndDate = obj.EndDate == null ? "" : obj.EndDate;
      this.filterObj.PeriodType = obj.PeriodType == null ? "" : obj.PeriodType;

      var dateObject = {
        "StartDate": obj.StartDate == null ? "" : obj.StartDate,
        "EndDate": obj.EndDate == null ? "" : obj.EndDate,
        "PeriodType": obj.PeriodType == null ? "" : obj.PeriodType,
      }
      localStorage.setItem("periodFilterObject", JSON.stringify(dateObject));
    }

    this.filterObj.BACode = obj.BACode == null ? "" : obj.BACode;
    this.filterObj.FAMCode = obj.FAMCode == null ? "" : obj.FAMCode;
    this.filterObj.DivCode = obj.DivCode == null ? "" : obj.DivCode;
    this.filterObj.BLCode = obj.BLCode == null ? "" : obj.BLCode;
    this.filterObj.Country = obj.Country == null ? "" : obj.Country;
    this.filterObj.ProjectManager = obj.ProjectManager == null ? "": obj.ProjectManager;

    this.filterObj.OperationalCountry = obj.OperationalCountry == null ? "" : obj.OperationalCountry;


    this.filterObj.BrandName = obj.BrandName == null ? "" : obj.BrandName;
    this.filterObj.Zone = obj.Zone == null ? "" : obj.Zone;
    this.filterObj.Branch = obj.Branch == null ? "" : obj.Branch;
    this.filterObj.SalesEngineer = obj.SalesEngineer == null ? "" : obj.SalesEngineer;
    this.filterObj.ServiceTechnician = obj.ServiceTechnician == null ? "" : obj.ServiceTechnician;
    this.filterObj.Touchpoint = obj.Touchpoint == null ? "" : obj.Touchpoint;
    this.filterObj.Influencer = obj.Influencer == null ? "" : obj.Influencer;
    this.filterObj.Continent = obj.Continent == null ? "" : obj.Continent;
    this.filterObj.Region = obj.Region == null ? "" : obj.Region;
    this.filterObj.PostCodes = obj.PostCode == null ? "" : obj.PostCode;
    this.filterObj.GroupNames = obj.GroupName == null ? "" : obj.GroupName;
    this.filterObj.PinnedField = obj.PinnedField == null ? "" : obj.PinnedField;
  }

  updateFilterObject(): any {
    var filterObjectString = localStorage.getItem("globalFilterObject");
    var periodFilterString = localStorage.getItem("periodFilterObject");

    if (filterObjectString != null) {
      var obj = JSON.parse(filterObjectString);
      this.mapFilterObject(obj, false);
    }
    if (periodFilterString != null) {
      var obj = JSON.parse(periodFilterString);
      this.filterObj.StartDate = obj.StartDate;
      this.filterObj.EndDate = obj.EndDate;
      this.filterObj.PeriodType = obj.PeriodType;
    }
    this.globalFilterBehaviousSubject.next(this.filterObj);
  }

  /**
   * Behaviour subject for id-text of selected filter
   */
  public updateSelectedFilter = (selectedFilters: any) => {
    this.globalFilterSelectedFilterSubject.next(JSON.parse(JSON.stringify(selectedFilters)));
  }
  getPostcodeList(formData, mode?) {
    if(!mode) {
      mode ='M'
    }
    return this.http.post(this.serviceUrl + `api/MDM/PostCodeMaster?mode=${mode}`, formData);
  }

  getGroupedPostcodeList(formData,mode?) {
    if(!mode) {
      mode ='M'
    }
    return this.http.post(this.serviceUrl + `api/MDM/GroupNameMaster?mode=${mode}`, formData);
  }
}
