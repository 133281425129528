import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth-service.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  submitted = false;
  statusMessage: string | null = null;
  passwordMismatch = false;
  showPasswordRequirements = false;
  passwordHistoryError = false;
  passwordHistoryMessage = "Your password has been used recently. Please choose a different one.";
  passwordValidations = {
    lowerCase: false,
    upperCase: false,
    number: false,
    specialChar: false,
    length: false
  };
  code: any;
  email: any;
  showLoader: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private route: ActivatedRoute, private authService: AuthService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.code = params['code'];
      this.email = params['email'];
    });
    this.resetPasswordForm = this.fb.group({
      code: this.code,
      email: this.email,
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, { validators: this.passwordMatchValidator });
  }

  get f() { return this.resetPasswordForm.controls; }

  passwordMatchValidator(form: FormGroup) {
    return form.get('password').value === form.get('confirmPassword').value ? null : { mismatch: true };
  }

  onSubmit() {
    this.statusMessage = null;
    this.submitted = true;

    if (this.resetPasswordForm.invalid || this.passwordMismatch) {
      return;
    }

    const formData = this.resetPasswordForm.value;
    console.log('Reset Password data:', formData);
    this.showLoader = true;
    this.authService.resetPassword({
        Email: formData.email,
        Password: formData.password,
        ConfirmPassword: formData.confirmPassword,
        Code: formData.code
    }).then(rawData => {
      const resetPasswordResponse = Object.assign(rawData);
      console.log(resetPasswordResponse)
      if(resetPasswordResponse.status == "Success"){
        this.showLoader = false;
        this.router.navigate(['/auth/reset-password-success']);
      }else{
        this.showLoader = false;
        this.statusMessage = resetPasswordResponse.errors[0].message;
      }
     }).catch(e => {
        this.showLoader = false;
       console.error('resetPassword Error: ', e.errorMessage);
       this.statusMessage = "Internal Server Error; Please try again."
     });
  }

  validatePassword() {
    this.showPasswordRequirements = true;
    const password = this.resetPasswordForm.get('password').value;
    this.passwordValidations.lowerCase = /[a-z]/.test(password);
    this.passwordValidations.upperCase = /[A-Z]/.test(password);
    this.passwordValidations.number = /[0-9]/.test(password);
    this.passwordValidations.specialChar = /[!@#$%^&*]/.test(password);
    this.passwordValidations.length = password.length >= 8;
  }
  validateConfirmPassword() {
    const password = this.resetPasswordForm.get('password').value;
    const confirmPassword = this.resetPasswordForm.get('confirmPassword').value;
    this.passwordMismatch =  confirmPassword !== password;
  }

  onFocus(field: string) {
    this.statusMessage = null;
    this.showPasswordRequirements = field === 'newPassword';
  }

  onBlur(field: string) {
    if (field === 'newPassword') {
      this.showPasswordRequirements = false;
    }
  }
}

