import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-with-twofa',
  templateUrl: './login-with-twofa.component.html',
  styleUrls: ['./login-with-twofa.component.scss']
})
export class LoginWithTwofaComponent implements OnInit {
  twoFactorForm: FormGroup;
  errorMessage: string | null = null;
  submitted = false;
  returnUrl: string | null = null;

  constructor(private fb: FormBuilder, private router: Router) {
    // Get the return URL from the current route
    const urlParams = new URLSearchParams(window.location.search);
    this.returnUrl = urlParams.get('returnUrl');
  }

  ngOnInit() {
    this.twoFactorForm = this.fb.group({
      twoFactorCode: ['', Validators.required],
      rememberMachine: [false],
      rememberMe: [false]
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.twoFactorForm.valid) {
      const formData = this.twoFactorForm.value;
      console.log('Logging in with code:', formData.twoFactorCode);
      // Perform the login logic (e.g., call an authentication service)

      // If login is successful, navigate to returnUrl
      // this.router.navigate([this.returnUrl]);
    } else {
      this.errorMessage = "Please enter a valid authenticator code.";
    }
  }
}

