import { Component, Input, OnInit } from '@angular/core';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss']
})
export class StatusMessageComponent implements OnInit {
  @Input() message: string;
  messages: Message[] = [];

  ngOnInit() {
    if (this.message) {
      this.messages.push({
        severity: this.statusMessageClass,
        summary: 'Status',
        detail: this.message,
        life: 3000 // Auto hide after 3 seconds
      });
    }
  }

  get statusMessageClass(): string {
    if (!this.message) return 'info'; // Default to info if no message
    return this.message.startsWith('Error') ? 'error' : 'success';
  }
}
