import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth-service.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  title: string = "Forgot Password";
  forgotPasswordForm: FormGroup;
  statusMessage: string | null = null;
  emailError: boolean = false;
  emailNotRegistered: boolean = false;
  blockedUser: boolean = false;
  requiredEmail: boolean = false;
  showLoader: boolean = false;

  constructor(private fb: FormBuilder,  private authService: AuthService,  private router: Router,) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    // Initialize messages or check for existing states if necessary
  }

  validateEmail() {
    const email = this.forgotPasswordForm.get('email').value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(email);
    this.emailError = !isValidEmail;
  }

  onSubmit(): void {
    this.resetFlags();
    if (this.forgotPasswordForm.valid) {
      this.showLoader = true;
      
      const email = this.forgotPasswordForm.value.email;
      this.authService.forgotPassword({email: email}).then(rawData => {
       const forgotPasswordResponse = Object.assign(rawData);
       if(forgotPasswordResponse.status == "Success"){
        this.showLoader = false;
        this.router.navigate(['/auth/forgot-password-confirmation']);
        }else{
          this.showLoader = false;
          if(forgotPasswordResponse.errors[0].code=='EmailNotRegistered'){
            this.emailNotRegistered = true;
          }
          else if(forgotPasswordResponse.errors[0].code=='BlockedUser'){
            this.blockedUser = true;
          }          
          this.statusMessage = forgotPasswordResponse.errors[0].message;
        }
      }).catch(e => {
        this.showLoader = false;
        console.error('forgotPassword Error: ', e.errorMessage);
        this.statusMessage = "Internal Server Error; Please try again."
      });
    }
  }

  private resetFlags(): void {
    this.requiredEmail = false;
    this.emailError = false;
    this.emailNotRegistered = false;
    this.blockedUser = false;
    this.statusMessage = '';
  }

}
