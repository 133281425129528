import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CtlsTaskListingService {
  onHoverData = false;
  onHoverCommentPopup = new BehaviorSubject(false);
  filterObjForTransactional = {
    StartDate: false,
    //for businees filters
    EndDate: false,
    PeriodType: false,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: true,
    Touchpoint: true,
    Influencer: true,
    ServiceTechnician: true,
    Continent: true,
    Region: true,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'TRANS',
    screenCode: 'CTLS_TASK_LISTING', //SELECTED SCREEN
    sessionStorageName: 'CTLS_TRANS_TASK_LISTING',
    savedFilterSessionStorageName: 'CTLS_TRANS_TASK_LISTING_SAVED_FILTER',
    CustomerName: true,
    CustomerAccountNumber: true,
    Distributors: false,
    DistributorSegment: false,
    DistributorAccountNumber: false,
    showDateFilter: true,
    filterObjForGrid: [
      //for removing select field to search
      'IsActive',
      'CreatedDate',
      'CreatedBy',
      'DIV',
      'Brand',
      'Designation',
      'CustomerName',
      'CustomerAccountNo',
      'InfluenceLevel',
      'Status',
      'Branch',
      'Zone',
      'ServiceTechnician',
      'SalesEngineer',
      'Touchpoint',
      'FAM',
      'BA',
      'ResolveWithin'
    ],
  };
  filterObjForRelational = {
    StartDate: false,
    EndDate: false,
    PeriodType: false,
    BACode: true,
    FAMCode: true,
    DivCode: true,
    BLCode: false,
    Country: true,
    BrandName: true,
    SalesEngineer: false,
    Touchpoint: false,
    Influencer: true,
    ServiceTechnician: false,
    Continent: true,
    Region: false,
    Zone: true,
    Branch: true,
    PinnedField: true,
    ActionType: true,
    surveySubCategoryCode: 'DIST',
    screenCode: 'CTLS_TASK_LISTING', //SELECTED SCREEN
    sessionStorageName: 'CTLS_DIST_TASK_LISTING',
    savedFilterSessionStorageName: 'CTLS_DIST_TASK_LISTING_SAVED_FILTER',
    CustomerName: false,
    CustomerAccountNumber: false,
    Distributors: true,
    DistributorSegment: true,
    DistributorAccountNumber: true,
    showDateFilter: true,
    filterObjForGrid: [
      'IsActive',
      'CreatedDate',
      'CreatedBy',
      'DIV',
      'Brand',
      'Designation',
      'CustomerName',
      'CustomerAccountNo',
      'InfluenceLevel',
      'Status',
      'DistributorSegment',
      'Distributor',
      'DistributorAccountNo',
      'Branch',
      'Zone',
      'FAM',
      'BA',
    ],
  };
  /** Form wizard state */
  surveyID: number = 0;

  /** BehaviourSubjects */
  blockFull: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) { }

  /**Service for load grid data in task survey feedback
   * @param dataObj
   */

  getTaskListing(assigned, value, filterObj): Observable<any> {
    if (!!assigned) {
      if (value === 1) {
        return this.http.post<any>(
          `${environment.baseUrlCTLS}/TaskListing/GetTransactionalTaskListingDetails`,
          filterObj
        );
      } else if (value === 2) {
        return this.http.post<any>(
          `${environment.baseUrlCTLS}/TaskListing/GetRelationshipTaskListingDetails`,
          filterObj
        );
      }
    } else {
      if (value === 1) {
        return this.http.post<any>(
          `${environment.baseUrlCTLS}/TaskListing/GetTransactionalAutomaticTaskListingDetails`,
          filterObj
        );
      } else if (value === 2) {
        return this.http.post<any>(
          `${environment.baseUrlCTLS}/TaskListing/GetRelationshipAutomaticTaskListingDetails`,
          filterObj
        );
      }
    }
  }
  /**Service for get modified columns
   * @param dataObj
   */
  GetModifyColumns(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/GridColumn/GetModifyColumn`,
      {
        params: {
          GridName: dataObj.GridName,
          SubCategoryCode: dataObj.SubCategoryCode,
        },
      }
    );
  }

  /**Service for save  task list
   * @param dataObj
   */
  SaveTaskManagementDetails(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/SaveTaskManagementDetails`,
      dataObj
    );
  }
  /**Service for fetching bulk update results
   * @param dataObj
   */
  GetBulkUpdateData(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/BulkUpload`,
      dataObj
    );
  }
  /**
   * set
   * @param flag
   */
  setOnHoverOfComment(flag: boolean) {
    this.onHoverCommentPopup.next(flag);
  }

  /**
   * get current comment status
   */
  getCurrentCommentStatus() {
    this.onHoverCommentPopup.subscribe((value) => {
      this.onHoverData = value;
    });
    return this.onHoverData;
  }

  /**Service for save  inline edit comment and status
   * @param dataObj
   */
  UpdateTaskCommentAndStatus(dataObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/UpdateTaskCommentAndStatus`,
      dataObj
    );
  }

  /**
   * Delete Task from Task Listing page
   * @param CTLSTaskListID: array of taskIds
   * @returns: Observable
   */
  DeleteTasks(CTLSTaskListID): Observable<any> {
    return this.http.put<any>(
      `${environment.baseUrlCTLS}/TaskListing/DeleteTaskList`,
      CTLSTaskListID
    );
  }

  ViewRelationshipSurveyDetailsById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetRelationshipSurveyDetailsBySurveyID`,
      { params: { SurveyID: id } }
    );
  }

  ViewTransactionalSurveyDetailsById(id): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetTranscationalSurveyDetailsBySurveyID`,
      { params: { SurveyID: id } }
    );
  }

  GetSurveyFeedbackComments(
    surveyId,
    lang,
    selectedRadio = 1
  ): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetCustomerFeedbackQuestionnaire`,
      {
        SurveyInstanceId: surveyId,
        LangId: lang,
        SurveyCategory: selectedRadio === 1 ? 'TRANS' : 'DIST',
      }
    );
  }
  /**
   * Saves task listing sequence
   * @param saveObj
   */
  SaveModifiedSequence(saveObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/SaveTaskSequence`,
      saveObj
    );
  }
  /**
  * Transalte Question and response with google api
  * @param data
  * @param LangId
  */

  translateWithGoogle(data, LangId) {
    const transData = {
      'LangId': LangId,
      'TranslatedQuestion': data
    };
    return this.http.post(environment.baseUrlCTLS + '/CustomerFeedback/TranslateWithGoogle', transData)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }


  /** 
* Unpin task sequence
* @param CTLSTaskListID
*/
  unpinTaskSequence(CTLSTaskListIDs, IsTrans): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/UnpinTaskSequence`,
      {TaskListIDs: CTLSTaskListIDs, IsTrans}
    );
  }


  getBA(): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/Unavailability/GetBADetails`,
    );
  }
  getFAM(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetFAMDetails`,
      {
        params: {
          BAID: dataObj.BAID,
        },
      }
    );
  }

  getFAMForUnAssigned(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/Unavailability/GetFAMDetails`,
      {
        params: {
          BAID: dataObj.BAID,
        },
      }
    );
  }

  getDIV(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetDivision`,
      {
        params: {
          FAM: dataObj.FAM, BA: dataObj.BA,

        },
      }
    );
  }

  getDIVUnAssigned(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrl}api/Unavailability/GetDivDetails`,
      {
        params: {
          FAM: dataObj.FAM, BA: dataObj.BA,

        },
      }
    );
  }

  getBrand(dataObj): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetBrand`,
      {
        params: {
          div: dataObj.div, FAM: dataObj.FAM, BA: dataObj.BA,
        },
      }
    );
  }
  getAssignees(dataObj): Observable<any> {
    dataObj.BA = !dataObj.BA ? "" : dataObj.BA;
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetAssignee`,
      {
        params: {
          div: dataObj.div, FAM: dataObj.FAM, BA: dataObj.BA, BrandID: dataObj.BrandID,
        },
      }
    );
  }

  SaveAssign(saveObj): Observable<any> {
    return this.http.post<any>(
      `${environment.baseUrlCTLS}/TaskListing/AssignTask`,
      saveObj
    );
  }

  getUpdatedTime(taskID): Observable<any> {
    return this.http.get<any>(
      `${environment.baseUrlCTLS}/TaskListing/GetTimeDetailsByTaskID?CTLSTaskListID=${taskID}`
    );
  }

  getCTLSAttachments(taskListId: number){
    return this.http.get<any>(`${environment.baseUrlCTLS}/EditTask/GetAttachmentsByCTLSTaskid?CTLSTaskListID=${taskListId}`)
  }

}
