import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-password-changed-success',
  templateUrl: './password-changed-success.component.html',
  styleUrls: ['./password-changed-success.component.scss']
})
export class PasswordChangedSuccessComponent implements OnInit {
  message: string | null = null;
  redirectUrl: string = '/dashboard/home'; // Default redirect URL
  countdown: number = 5;

  constructor(private router: Router, private storageService: StorageService) { }

  ngOnInit() {
    // Get the message from a service or via route parameters
    // For this example, we're simulating it:
    this.message = this.getPasswordChangeMessage(); // Simulated function

    this.redirectUrl = this.message && this.message.includes("Password Expiry")
      ? '/auth/login'
      : '/dashboard/home';

    this.startCountdown();

  }

  getPasswordChangeMessage(): string {
    // Simulate a message (this should be replaced with real logic to get the message)
    return 'Your password has expired'; // Example message; replace with real data
  }

  startCountdown() {
    const interval = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(interval);
        this.redirect();
      }
    }, 1000);
  }

  redirect(): void {
    if(this.redirectUrl=='/auth/login'){
      this.storageService.clearAllExceptRememberMe();
    }
    this.router.navigate([this.redirectUrl]);
  }
}

