import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent implements OnInit {
  postLogoutRedirectUri: string | null = null;
  automaticRedirectAfterSignOut: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    // Here, fetch the logout redirect URI and automatic redirect flag from a service
    // For example, you might get this from a service that handles authentication state
    // This is just a placeholder; implement your logic to retrieve this data as needed.

    this.postLogoutRedirectUri = this.getPostLogoutRedirectUri();
    this.automaticRedirectAfterSignOut = this.shouldRedirectAutomatically();

    if (this.automaticRedirectAfterSignOut) {
      // Automatically redirect to the post logout redirect URI
      setTimeout(() => {
        if (this.postLogoutRedirectUri) {
          window.location.href = this.postLogoutRedirectUri;
        }
      }, 3000); // Adjust time as necessary
    }
  }

  getPostLogoutRedirectUri(): string | null {
    // Replace this with actual logic to fetch the redirect URI
    return 'https://example.com/login'; // Example redirect URI
  }

  shouldRedirectAutomatically(): boolean {
    // Replace this with actual logic to determine if automatic redirect is needed
    return true; // Example value
  }
}

