import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-signed-out',
  templateUrl: './signed-out.component.html',
  styleUrls: ['./signed-out.component.scss']
})
export class SignedOutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    // Any initialization logic can go here
  }

}

