import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  passwordForm: FormGroup;
  statusMessage: string = ''; // Replace with your actual status message if needed
  formErrors: string[] = [];
  passwordsMismatch: boolean = false;

  constructor(private fb: FormBuilder) {
    this.passwordForm = this.fb.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });

    this.passwordForm.get('confirmPassword').valueChanges.subscribe(() => {
      this.checkPasswords();
    });
  }

  ngOnInit(): void {}

  checkPasswords(): void {
    const newPassword = this.passwordForm.get('newPassword').value;
    const confirmPassword = this.passwordForm.get('confirmPassword').value;
    this.passwordsMismatch = newPassword !== confirmPassword;
  }

  onSubmit(): void {
    this.formErrors = [];
    if (this.passwordForm.invalid) {
      this.formErrors.push('Please fill out all required fields.');
      return;
    }

    if (this.passwordsMismatch) {
      this.formErrors.push('Passwords do not match.');
      return;
    }

    // Call your service to handle password setting here
    // Example: this.yourService.setPassword(this.passwordForm.value).subscribe(...);
    
    console.log('Password set:', this.passwordForm.value);
  }
}

