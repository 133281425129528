export const environment = {
  production: true,
  baseUrl: 'https://apiqa.cemfalcon.com/',
  baseUrlNode: 'https://apiqa.cemfalcon.com:3000/',
  baseUrlAuth: 'https://app-10007097-falcon-authenticate-api-qa-f5cndqaec6apbjhj.westeurope-01.azurewebsites.net/api/v1',
  baseUrlDashboard: 'https://app-10007097-falcon-dashboard-api-qa-cwcxdwe5bueabman.westeurope-01.azurewebsites.net/api/v1',
  loginUrl: 'https://qa.cemfalcon.com/auth/login',
  changePasswordUrl: 'https://qa.cemfalcon.com/auth/change-password',
  accessDeniedUrl: "error/accessdenied",
  apiUrl: "https://apiqa.cemfalcon.com/",
  identityUrl: "https://apiqa.cemfalcon.com:11000",
  ctlsApiUrl: "https://apiqa.cemfalcon.com:12001",
  dashboardImagePath: "https://falconuat1.blob.core.windows.net/falconuatimg/dashboard/",
  containerName: "falconimguploadlocal",
  dragDropTimeOut: 5000,
  CustomerSupportEmailID: 'ACFalcon-admin-prod@thinkpalm.com',
  Field:{
    OpCountryId:"FAMOperationalCountryID"
  },
  websocket:{
    hostURL:'wss://apiqa.cemfalcon.com:3000'
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZmFsY29udWF0IiwiYSI6ImNseGN3MnZ2cjBtMTMyanExZG9sb3h5cncifQ.h42eYcli6C3ZdCSA7KDOpw'
  },
  imagepath:'https://qa.cemfalcon.com/assets/images/',
  assetpath:'https://qa.cemfalcon.com/assets/',
  activeDirectory: {
    config: {
      auth: {
        clientId :"05a2c715-dc09-4b50-8d2b-cb63b4fc2e34",
        authority: "https://login.microsoftonline.com/556e6b1f-b49d-4278-8baf-db06eeefc8e9/"
      }
    }
  }
};