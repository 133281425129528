import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { DialogService } from 'primeng/api';
import { debug } from 'util';
import { forEach } from '@angular/router/src/utils/collection';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  private serviceUrl = this.sharedService.configuration.baseUrl;
  recordCount: number;
  userid: number;
  userRoles = []; // userRoles.push({RoleID:1,RoleName:'Champion'});

  // For user profile
  userProfileRoles = [];

  constructor(private http: HttpClient, public dialogService: DialogService, private sharedService: SharedService) { }

  // To get user list for user management grid
  getUserList(urlParams) {
    return this.http.get(this.serviceUrl + 'api/MDM/UserManagement/Users' + urlParams + '&buster=' + new Date().getTime())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  // To get user list for user management grid
  getUserMasterData() {
    return this.http.get(this.serviceUrl + 'api/MDM/UserManagement/Users/MasterData?buster=' + new Date().getTime()).
      toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  // get user details by userID
  getUserDetailsById(userID) {
    return this.http.get(this.serviceUrl + 'api/MDM/UserManagement/Users/' + userID + '?buster=' + new Date().getTime())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }

  Registerfalconuser(userData) {
    return this.http.post(environment.baseUrlAuth + 'api/account/registerfalconuser', userData)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }

  updateUserMenuAccess(payload) {
    return this.http.post(this.serviceUrl + 'api/MDM/UserManagement/Users/UpdateRPException', payload)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }
  EditFalconUser(userData, userId) {
    return this.http.post(environment.baseUrlAuth + 'api/account/registerfalconuser/' + userId, userData)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }

  // block user details by userID
  blockUserByUserIds(userID) {
    return this.http.get(this.serviceUrl + 'api/MDM/UserManagement/Users/Block/' + userID + '?buster=' + new Date().getTime())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }

  // block/Unblock user details by userID
  blockUnblockUser(blockUnblockUser) {
    return this.http.post(this.serviceUrl + 'api/MDM/UserManagement/Users/BlockUnblockUser', blockUnblockUser)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }

  // To get Access permission list
  getAccessList(urlParams) {
    return this.http.get(this.serviceUrl + 'api/MDM/UserManagement/AccessControls/UserAccessList' + urlParams + '&buster=' + new Date().getTime())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  // Delete Access Permission by
  deleteAccessControl(accessID) {
    return this.http.get(this.serviceUrl + 'api/MDM/UserManagement/AccessControls/Delete/' + accessID)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }

  // Get Access Control By ID
  getAccessControlById(accessID) {
    return this.http.get(this.serviceUrl + 'api/MDM/UserManagement/AccessControls/' + accessID+ '?buster=' + new Date().getTime())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }

  // for insert or update user details
  addAccessControl(userAccessControl) {
    return this.http.post(this.serviceUrl + 'api/MDM/UserManagement/AccessControls/Add/', userAccessControl)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }

  UploadUserDP(data, fname = null) {
    let url;
    if (fname != null && fname != '') {
      url = this.serviceUrl + 'api/MDM/UserManagement/USERDP?fname=' + fname;
    } else {
      url = this.serviceUrl + 'api/MDM/UserManagement/USERDP';
    }
    return this.http.post(url, data).
      toPromise()
      .then((res: any) => res)
      .then(data => { return data;
      }).catch(error => {
        return error;
      });
  }


  exportUsersToExcel(searchUserParam) {
    return this.http.post(this.sharedService.configuration.baseUrl + 'api/MDM/UserManagement/Export', searchUserParam, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  // User Profile
  // get user details by userID
  getUserDetails() {
    return this.http.get(this.serviceUrl + 'api/MDM/UserProfile/User' + '?buster=' + new Date().getTime())
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
      });
  }

  uploadFile(file, selectedUserId) {
    let serviceUrl = environment.baseUrlAuth + 'api/account/CloneUserPermissions?selectedUserId=' + selectedUserId;
    return this.http.post(serviceUrl, file)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  downloadCloneTemplateFileFromBlob(filepath) {
    let serviceUrl = this.sharedService.configuration.baseUrl + 'api/MDM/DownloadCloneTemplateFileFromBlob/' + filepath;
    return this.http.get(serviceUrl, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

//update first name and last name
UpdateUserName(userData) {
  return this.http.post(this.serviceUrl + 'api/MDM/UserProfile/User/Update', userData)
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      }).catch(error => {
        return error;
  });
}

ActivateFalconUsers(emailIds){
  return this.http.post(environment.baseUrlAuth  + 'api/account/ActivateFalconUsers', emailIds)
  .toPromise()
  .then((res: any) => res)
  .then(data => data)
  .catch(error => error);
}

}
