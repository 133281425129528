
import { FalconSpinnerService } from '../falcon-components/falcon-spinner/falcon-spinner.service';
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Observable, Subject, asapScheduler, pipe, of, from, interval, merge, fromEvent } from 'rxjs';

import { HttpBackend } from "@angular/common/http";
import { tap } from 'rxjs/operators';
import { FIELDS } from 'src/app/shared/constants/FIELDS';

  @Injectable({ providedIn: 'root' })
export class Loader {
  pending = false;
}

@Injectable()
export class FalconHttpInterceptor implements HttpInterceptor{
  constructor(public spinnerService: FalconSpinnerService) {}
  count = 0;
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token = this.getToken();
    // this.spinnerService.show();
    //If we want to avoid any request from inerceptor use this method.
    ////this.http.get(url, {headers:{skip:"true"});
    // const skipIntercept = req.headers.has('skip');
      // if (skipIntercept) {
        // req = req.clone({
        //       headers: req.headers.delete('skip')
        //   });
      // }else{
        this.spinnerService.show();
        this.count++;
        req = req.clone(
          {
              headers: req.headers.set('Authorization', `Bearer ${token}`)
          });  
        return next.handle(req).pipe(tap(
          (event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.count--;
              if (this.count == 0) this.spinnerService.hide();
            }
          },
          (err: any) => {
            if (req.headers.get("handleError") === "onService") {
              console.log("Interceptor does nothing...");
            } else {
              console.log("onInterceptor handle ", err);
            }
          }
        ));
      // }
      
  }

  getToken(): string | null {
    return localStorage.getItem(FIELDS.ACCESS_TOKEN) ? localStorage.getItem(FIELDS.ACCESS_TOKEN) : "";
  }
}