import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth-service.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  oldPwdError: boolean = false;
  passwordHistoryError: boolean = false;
  redirectUrl: string = '/dashboard/home';
  statusMessage: any;
  passwordMismatch = false;
  showPasswordRequirements = false;
  passwordValidations = {
    lowerCase: false,
    upperCase: false,
    number: false,
    specialChar: false,
    length: false
  };
  showLoader: boolean = false;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, { validators: this.passwordMatchValidator });
  }
  
  get f() { return this.changePasswordForm.controls; }

  passwordMatchValidator(form: FormGroup) {
    return form.get('password').value === form.get('confirmPassword').value ? null : { mismatch: true };
  }

  onSubmit() {
    this.resetFlags();
    if (this.changePasswordForm.valid) {
      this.showLoader = true;
      const formData = this.changePasswordForm.value;
      console.log('Change Password data:', formData);
      this.authService.changePassword({
          OldPassword: formData.oldPassword,
          NewPassword: formData.password,
          ConfirmPassword: formData.confirmPassword
      }).then(rawData => {
        const changePasswordResponse = Object.assign(rawData);
        console.log(changePasswordResponse)
        if(changePasswordResponse.status == "Success"){
          this.showLoader = false;
          this.router.navigate(['/auth/password-changed-success']);
        }else{
          this.showLoader = false;
          if(changePasswordResponse.errors[0].code=='ChangePasswordError'){
            this.oldPwdError=true;
          }else if(changePasswordResponse.errors[0].code=='CannotReuseOldPassword'){
            this.passwordHistoryError=true;
          }
          this.statusMessage = changePasswordResponse.errors[0].message;
        }
      }).catch(e => {
        this.showLoader = false;
        console.error('changePassword Error: ', e.errorMessage);
        this.statusMessage = "Internal Server Error; Please try again."
      });
    }
  }

  validatePassword() {
    this.showPasswordRequirements = true;
    const password = this.changePasswordForm.get('password').value;
    this.passwordValidations.lowerCase = /[a-z]/.test(password);
    this.passwordValidations.upperCase = /[A-Z]/.test(password);
    this.passwordValidations.number = /[0-9]/.test(password);
    this.passwordValidations.specialChar = /[!@#$%^&*]/.test(password);
    this.passwordValidations.length = password.length >= 8;
  }
  validateConfirmPassword() {
    const password = this.changePasswordForm.get('password').value;
    const confirmPassword = this.changePasswordForm.get('confirmPassword').value;
    this.passwordMismatch =  confirmPassword !== password;
  }

  onFocus(field: string) {
    console.log(field)
    this.statusMessage = null;
    this.showPasswordRequirements = field === 'newPassword';
  }

  onBlur(field: string) {
    if (field === 'newPassword') {
      this.showPasswordRequirements = false;
    }
  }

  cancel() {
    this.router.navigate(['/']);
  }

  private resetFlags(): void {
    this.oldPwdError = false;
    this.passwordHistoryError=false;
    this.statusMessage = '';
  }
}
