import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/services/storage.service';

@Component({
  selector: 'app-reset-password-success',
  templateUrl: './reset-password-success.component.html',
  styleUrls: ['./reset-password-success.component.scss']
})
export class ResetPasswordSuccessComponent implements OnInit {
  countdown: number = 5;
  private intervalId: any;
  redirectUrl: string = '/auth/login';

  constructor(private router: Router, private storageService: StorageService) { }

  ngOnInit(): void {
    this.startCountdown();
  }

  startCountdown(): void {
    this.intervalId = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(this.intervalId);
        this.redirect();
      }
    }, 1000);
  }

  redirect(): void {
    this.storageService.clearAllExceptRememberMe();
    this.router.navigate([this.redirectUrl]);
  }
}

