import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Top5CustomersService {

  constructor(private http: HttpClient, private sharedService: SharedService) { }

  private serviceUrl = this.sharedService.configuration.baseUrl;

  filterObj = {
    "StartDate": null,
    "EndDate": null,
    "PeriodType": "",
    "BACode": "",
    "FAMCode": "",
    "DivCode": "",
    "BLCode": "",
    "Country": "",
    "BrandName": "",
    "SalesEngineer": "",
    "Touchpoint": "",
    "ServiceTechnician": "",
    "Continent": "",
    "Region": "",
    "Zone": "",
    "Branch": ""
  }

  getTop5customers() {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/GetTopCustomerByTransaction', this.filterObj)
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

}
