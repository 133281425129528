import { forEach } from '@angular/router/src/utils/collection';

import { Component, OnInit, EventEmitter, Output, Input, OnChanges, ViewChild, ElementRef, HostListener } from '@angular/core';
import { FilterService } from '../../services/filter.service';
import { Message, SelectItem, TreeNode } from 'primeng/components/common/api';
import * as moment from 'moment';
import { ScrollPanel } from 'primeng/scrollpanel';
import { SharedService } from 'src/app/shared/services/shared.service';
import { JsonPipe } from '@angular/common';
import { LostbusinessCustomerService } from 'src/app/components/dashboard/service/lostbusiness-customer.service';
import { Subscription, forkJoin, of } from 'rxjs';
import * as _ from 'lodash';
import { catchError } from 'rxjs/operators';
import { title } from 'process';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges {
 
  @Input() feedbackList :any;
  private LostCountSubject: Subscription;
  showBusyIndicator: boolean = false;selectedPostalCode: any;
  postcodeDataCopy: any[] = [];
  postcodeDataCopyFilter: any[] = [];
  postalCodeSeleted: any;
  postCodeValueToShow: string;
  postCodeValuePinnedToShow: string;
  showload: boolean;
  status: boolean = false;
  groupDrpdwnstatus: any;
  selectedGrpPostalCode: any;
  postcodeGrpDataCopy: any[] = [];
  postcodeGrpDataCopyFilter: any[] = [];
  showGrpload: boolean;
  postCodeGrpIdsSelected= [];
  postCodeGrpValueToShow: string;
  postCodeGrpValuePinnedToShow: string;
  allPostalCodeSeleted: boolean = false;
  allPostalCodeGrpSeleted: boolean = false;
  postcodeChcked: any;
  postcodeGrpChcked: boolean;
  groupPostCodeIdsSelectedCopy: any[];
  nonUniquePostalCode: any[];
;
  //Master data properties
  data = [];
  zoneBranch = [];
  countryContinent = [];
  operationalCountryContinent = [];
  brand = [];
  engineerAndTechnician = [];
  touchPoints = [];
  influencers = [];

  dataOriginal = [];
  zoneBranchOriginal = [];
  countryContinentOriginal = [];
  operationalCountryContinentOriginal = [];
  
  brandOriginal = [];
  pmOriginal = [];
  engineerAndTechnicianOriginal = [];
  touchPointsOriginal = [];
  influencersOriginal = [];

  //dropdowndata
  famData = [];
  baData = [];
  pmData = [];
  divData = [];
  blData = [];
  countryData = [];
  operationalCountryData = [];
  brandData = [];
  zoneData = [];
  branchData = [];
  salesEngData = [];
  serviceTechData = [];
  touchPointData: Array<TreeNode> = [];
  influencerData = [];
  countinentData = [];
  regionData = [];
  postcodeData = [];
  groupPostcodeData = [];
  tpSelectionlabel = 'Select Touchpoint';

  //calanderSelectedDate = "";
  msgs: Message[] = [];
  isShowAlert: boolean = false;
  successMsg: boolean = false;
  MessageTimeOut = this.sharedService.configuration.MessageTimeOut;
  postCodeIdsSelected = [];
  groupPostCodeIdsSelected = [];
  //selected filter
  selectedTouchPointNodes: Array<TreeNode> = [];
  filterMapping: any = {
    FAMId: null,
    BAId: null,
    DIVId: null,
    BLId: null,
    CountryId: null,
    OperationalCountryId: null,    
    BrandId: null,
    ZoneId: null,
    BranchId: null,
    SalesEngId: null,
    ServiceTechId: null,
    TouchPointId: null,
    InfluencerId: null,
    ContinentId: null,
    regionId: null,
    PMId: null,
  };

  pinnedFields = {
    baPinned: true,
    divPinned: true,
    famPinned: true,
    blPinned: false,
    countryPinned: true,
    operationalCountryPinned: false,    
    brandPinned: false,
    zonePinned: false,
    branchPinned: false,
    salesPinned: false,
    servicePinned: false,
    touchpointPinned: false,
    influencerPinned: false,
    continentPinned: false,
    regionPinned: false,
    pmPinned: false,
    postcodePinned: false,
    groupPostcodePinned: false
  }

  pinnedFieldValuess = {
    baPinnedValue: "All",
    divPinnedValue: "All",
    famPinnedValue: "All",
    blPinnedValue: "All",
    countryPinnedValue: "All",
    operationalCountryPinnedValue: "All",    
    brandPinnedValue: "All",
    zonePinnedValue: "All",
    branchPinnedValue: "All",
    salesPinnedValue: "All",
    servicePinnedValue: "All",
    touchpointPinnedValue: "All",
    influencerPinnedValue: "All",
    continentPinnedValue: "All",
    regionPinnedValue: "All",
    pmPinnedValue: "All",
    postcodePinnedValue: "All",
    groupPostcodePinnedValue: "All"
  }

  pinnedFieldValuesArray = {
    baPinnedValue: [],
    divPinnedValue: [],
    famPinnedValue: [],
    blPinnedValue: [],
    countryPinnedValue: [],
    operationalCountryPinnedValue: [],    
    brandPinnedValue: [],
    zonePinnedValue: [],
    branchPinnedValue: [],
    salesPinnedValue: [],
    servicePinnedValue: [],
    touchpointPinnedValue: [],
    influencerPinnedValue: [],
    continentPinnedValue: [],
    regionPinnedValue: [],
    pmPinnedValue: [],
    postcodePinnedValue: [],
    groupPostcodePinnedValue: [],
  }

  inlineFilter = {
    baInline: false,
    famInline: false,
    divInline: false,
    blInline: false,
    countryInline: false,
    operationalCountryInline: false,
    
    brandInline: false,
    pmInline: false,
    zoneInline: false,
    branchInline: false,
    salesInline: false,
    serviceInline: false,
    touchpointInline: false,
    influencerInline: false,
    continentInline: false,
    regionInline: false,
    postcodeInline: false,
    groupPostcodeInline: false
  }

  countOfAllData = {
    baCount: 0,
    divCount: 0,
    famCuunt: 0,
    blCount: 0,
    countryCount: 0,
    operationalCountryCount: 0,
    pmCount: 0,
    
    brandCount: 0,
    zoneCount: 0,
    branchCount: 0,
    salesCount: 0,
    serviceCount: 0,
    touchpointCount: 0,
    influencerCount: 0,
    continentCount: 0,
    regionCount: 0,
    postcodeCount: 0,
    groupPostcodeCount: 0
  }

  selectedCount = {
    baCount: [],
    divCount: [],
    famCount: [],
    blCount: [],
    countryCount: [],
    operationalCountryCount: [],
    pmCount: [],
    brandCount: [],
    zoneCount: [],
    branchCount: [],
    salesCount: [],
    serviceCount: [],
    touchpointCount: [],
    influencerCount: [],
    continentCount: [],
    regionCount: [],
    postcodeCount: [],
    groupPostcodeCount: []
  }

  periodType = {
    M: true,
    Q: false,
    H: false,
    Y: false,
  }

  delimiter = "|";
  filterToggle: boolean = false;
  filterDateToggle: boolean = false;
  pinFilterSecVisible: boolean = false;

  // rangeDates: Date[];
  dateTime = new Date();
  defaultDate = new Date();
  isPeriodType: boolean = false;
  isDateRange: boolean = true;
  StartDate = "";
  EndDate = "";
  startDateCal = "";
  endDateCal = "";


  @Input('npsWidgetStatus') npsWidgetStatus;
  @Input('savedFilterObject') savedFilterObject
  @Input('savedFilterObjectFromDB') savedFilterObjectFromDB
  @Input('selectedSurveyDate') selectedSurveyDate;
  @Output('submitted') submitted: EventEmitter<any> = new EventEmitter<any>();
  @Output('saved') saved: EventEmitter<string> = new EventEmitter<string>();
  @Output('pinChanged') pinChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output('onPinnedValueChange') onPinnedValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output('reset') reset: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('famDDL') famDDL;
  @ViewChild('baDDL') baDDL;
  @ViewChild('pmDDL') pmDDL;
  @ViewChild('divDDL') divDDL;
  @ViewChild('blDDL') blDDL;
  @ViewChild('countryDDL') countryDDL;
  @ViewChild('operationalCountryDDL') operationalCountryDDL;
  
  @ViewChild('brandDDL') brandDDL;
  @ViewChild('zoneDDL') zoneDDL;
  @ViewChild('branchDDL') branchDDL;
  @ViewChild('salesDDL') salesDDL;
  @ViewChild('serviceDDL') serviceDDL;
  @ViewChild('continentDDL') continentDDL;
  @ViewChild('regionDDL') regionDDL;
  @ViewChild('postcodeDDL') postcodeDDL;
  @ViewChild('groupPostcodeDDL') groupPostcodeDDL;
  @ViewChild('tpDDL') tpDDL;
  @ViewChild('infDDL') infDDL;


  @ViewChild('famDDLPinned') famDDLPinned;
  @ViewChild('baDDLPinned') baDDLPinned;
  @ViewChild('pmDDLPinned') pmDDLPinned;
  @ViewChild('divDDLPinned') divDDLPinned;
  @ViewChild('blDDLPinned') blDDLPinned;
  @ViewChild('countryDDLPinned') countryDDLPinned;
  @ViewChild('operationalCountryDDLPinned') operationalCountryDDLPinned;
  
  @ViewChild('brandDDLPinned') brandDDLPinned;
  @ViewChild('zoneDDLPinned') zoneDDLPinned;
  @ViewChild('branchDDLPinned') branchDDLPinned;
  @ViewChild('salesDDLPinned') salesDDLPinned;
  @ViewChild('serviceDDLPinned') serviceDDLPinned;
  @ViewChild('continentDDLPinned') continentDDLPinned;
  @ViewChild('regionDDLPinned') regionDDLPinned;
  @ViewChild('tpDDLPinned') tpDDLPinned;
  @ViewChild('infDDLPinned') infDDLPinned;
  @ViewChild('postcodeDDLPinned') postcodeDDLPinned;
  @ViewChild('groupPostcodeDDLPinned') groupPostcodeDDLPinned;

  constructor(private filterService: FilterService, private sharedService: SharedService,private lostfilterService: LostbusinessCustomerService, private el: ElementRef) { }

  ngOnInit() {
    if (this.selectedSurveyDate) {
      const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let surveyDate = this.selectedSurveyDate.substr(0, 2) + '-' +
        month[(+this.selectedSurveyDate.substr(2, 2)) - 1] + '-' + this.selectedSurveyDate.substr(4);
      this.startDateCal = surveyDate;
      this.endDateCal = surveyDate;
      this.submitDatefilter();
    } else {
      this.showBusyIndicator = true;
      this.filterService.getUserFilter().then(res => {
        this.savedFilterObject = this.filterService.filterObj;
        this.convertToArray();
        if (this.filterService.dataOriginal.length > 0) {
          this.influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });
          this.getInitialLoadDataForPostCodes();
          this.getInitialLoadDataForPostCodes('grouped');
         this.onDone()
        } else {
          this.getViewData();
        }
      }).catch(error => {
        this.savedFilterObject = this.filterService.filterObj;
        this.convertToArray();
        if (this.filterService.dataOriginal.length > 0) {
          this.influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });
          this.getInitialLoadDataForPostCodes();
          this.getInitialLoadDataForPostCodes('grouped');
          this.onDone()
        } else {
          this.getViewData();
        }
      });
    }
    const today = new Date();
  }

  getSelectedDate(event, dateType) {
    var dateToSelect = 1;
    if (dateType === "endDate") {
      try {
        if (this.endDateCal != null) {
          dateToSelect = parseInt(this.endDateCal.split("-")[0]);
        }
      } catch{ }
    }
    if (dateType === "startDate") {
      try {
        if (this.startDateCal != null) {
          dateToSelect = parseInt(this.startDateCal.split("-")[0]);
        }
      } catch{ }
    }
    var lastDay = new Date(event.year, event.month, 0).getDate();
    if (lastDay < dateToSelect) {
      dateToSelect = lastDay
    }
    return dateToSelect;
  }

  onMonthChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onDateSelection(new Date(event.year, event.month - 1, dateToSelect), dateType);
  }

  onYearChange(event, dateType) {
    var dateToSelect = this.getSelectedDate(event, dateType);
    this.onDateSelection(new Date(event.year, event.month - 1, dateToSelect), dateType);
  }

  resetDatefilter() {
    this.isDateRange = true;
    this.isPeriodType = true;
    this.periodType.Q = false;
    this.periodType.H = false;
    this.periodType.Y = false;
    this.periodType.M = true;

    const today = new Date();
    this.StartDate = this.changeDateFormate(today);
    this.EndDate = this.changeDateFormate(today);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
    //this.calanderSelectedDate = this.StartDate;
    // this.rangeDates = [today, today];
    this.calculateStartAndEndDate('M');
  }

  calculateStartAndEndDate(key) {

    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = date;

    if (key == "Q") {
      var quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter == 1) {
        firstDay = new Date(y, 0, 1);
        lastDay = new Date(y, 2, 31);
      } else if (quarter == 2) {
        firstDay = new Date(y, 3, 1);
        lastDay = new Date(y, 5, 30);
      }
      else if (quarter == 3) {
        firstDay = new Date(y, 6, 1);
        lastDay = new Date(y, 8, 30);
      }
      else if (quarter == 4) {
        firstDay = new Date(y, 9, 1);
        lastDay = new Date(y, 11, 31);
      }
    }
    if (key == "H") {
      var hy = Math.floor((date.getMonth() + 6) / 6);
      if (hy == 1) {
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, 5, 30);
      }
      else if (hy == 2) {
        var firstDay = new Date(y, 6, 1);
        var lastDay = new Date(y, 11, 31);
      }

    }

    if (key == "Y") {
      var firstDay = new Date(y, 0, 1);
      var lastDay = new Date(y, 11, 31);
    }

    this.StartDate = this.changeDateFormate(firstDay);
    this.EndDate = this.changeDateFormate(lastDay);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;

    //  this.rangeDates = [new Date(), new Date()];
    // this.calanderSelectedDate = this.changeDateFormate(new Date());
  }

  onDateSelection(selectedDate, dateType) {
    this.isPeriodType = false;
    this.isDateRange = true;
    if (selectedDate != null && dateType == 'startDate') {
      this.startDateCal = this.changeDateFormate(new Date(selectedDate));
      var endDateFormat = this.changeDateFormat(this.EndDate);
      var endDate = new Date(endDateFormat);

      if (moment(selectedDate) <= moment(endDate)) {
        this.StartDate = this.changeDateFormate(new Date(selectedDate));
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
      }
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    } else if (selectedDate != null && dateType == 'endDate') {
      this.endDateCal = this.changeDateFormate(new Date(selectedDate));
      var startDateFormat = this.changeDateFormat(this.StartDate);
      var startDate = new Date(startDateFormat);
      if (selectedDate >= moment(startDate)) {
        this.EndDate = this.changeDateFormate(new Date(selectedDate));
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'End Date should not be lesser than Start Date');

      }
      //this.EndDate = this.changeDateFormate(new Date(selectedDate));
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          this.periodType[key] = false;
        }
      }
    }
    //  else if (selectedDate[0] != null) {
    //this.calanderSelectedDate = this.changeDateFormate(new Date(selectedDate[0]));
    // }
    this.GlobalDateChanged();
  }

  updatePeriodType(periodType) {
    var today = new Date();
    this.StartDate = this.changeDateFormate(today);
    this.EndDate = this.changeDateFormate(today);
    this.startDateCal = this.StartDate;
    this.endDateCal = this.EndDate;
    //this.calanderSelectedDate = this.StartDate;
    //this.rangeDates = [today, today];

    for (var key in this.periodType) {
      if (this.periodType.hasOwnProperty(key)) {
        if (key == periodType) {
          this.periodType[key] = true;
          this.calculateStartAndEndDate(key);
        } else {
          this.periodType[key] = false;
        }
      }
    }
    this.isDateRange = false;
    this.isPeriodType = true;
    this.GlobalDateChanged();
  }

  ngOnChanges() {
    if (this.npsWidgetStatus != "") {
      this.filterToggle = false;
      this.filterDateToggle = false;
      this.pinFilterSecVisible = false;
    }
  }

  convertToArray() {

    // var dateRange = [];
    var periodType = null;

    if (this.savedFilterObject != null) {
      if (this.filterService.filterObj.StartDate != null && this.filterService.filterObj.StartDate) {
        // dateRange.push(new Date(this.filterService.filterObj.StartDate));
        this.startDateCal = this.filterService.filterObj.StartDate
        this.StartDate = this.filterService.filterObj.StartDate;
      }
      if (this.filterService.filterObj.EndDate != null && this.filterService.filterObj.EndDate) {
        // dateRange.push(new Date(this.filterService.filterObj.EndDate));
        this.endDateCal = this.filterService.filterObj.EndDate;
        this.EndDate = this.filterService.filterObj.EndDate;
      }
      if (this.filterService.filterObj.PeriodType != null && this.filterService.filterObj.PeriodType) {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            if (key == this.filterService.filterObj.PeriodType) {
              this.periodType[key] = true;
              periodType = key
              this.calculateStartAndEndDate(key);
            } else {
              this.periodType[key] = false;
            }
          }
        }
      }
      else {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            this.periodType[key] = false;
          }
        }
      }
      
      this.filterMapping.BAId = (this.savedFilterObject.BACode != null && this.savedFilterObject.BACode != "") ? this.savedFilterObject.BACode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.PMId = (this.savedFilterObject.ProjectManager != null && this.savedFilterObject.ProjectManager != "") ? this.savedFilterObject.ProjectManager.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.DIVId = (this.savedFilterObject.DivCode != null && this.savedFilterObject.DivCode != "") ? this.savedFilterObject.DivCode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.FAMId = (this.savedFilterObject.FAMCode != null && this.savedFilterObject.FAMCode != "") ? this.savedFilterObject.FAMCode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.BLId = (this.savedFilterObject.BLCode != null && this.savedFilterObject.BLCode != "") ? this.savedFilterObject.BLCode.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.CountryId = (this.savedFilterObject.Country != null && this.savedFilterObject.Country != "") ? this.savedFilterObject.Country.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []

      this.filterMapping.OperationalCountryId = (this.savedFilterObject.OperationalCountry != null && this.savedFilterObject.OperationalCountry != "") ? this.savedFilterObject.OperationalCountry.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []

      this.filterMapping.BrandId = (this.savedFilterObject.BrandName != null && this.savedFilterObject.BrandName != "") ? this.savedFilterObject.BrandName.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.ZoneId = (this.savedFilterObject.Zone != null && this.savedFilterObject.Zone != "") ? this.savedFilterObject.Zone.split(this.delimiter) : []
      this.filterMapping.BranchId = (this.savedFilterObject.Branch != null && this.savedFilterObject.Branch != "") ? this.savedFilterObject.Branch.split(this.delimiter) : []
      this.filterMapping.SalesEngId = (this.savedFilterObject.SalesEngineer != null && this.savedFilterObject.SalesEngineer != "") ? this.savedFilterObject.SalesEngineer.split(this.delimiter) : []
      this.filterMapping.ServiceTechId = (this.savedFilterObject.ServiceTechnician != null && this.savedFilterObject.ServiceTechnician != "") ? this.savedFilterObject.ServiceTechnician.split(this.delimiter) : []
      this.filterMapping.TouchPointId = (this.savedFilterObject.Touchpoint != null && this.savedFilterObject.Touchpoint != "") ? this.savedFilterObject.Touchpoint.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.InfluencerId = (this.savedFilterObject.Influencer != null && this.savedFilterObject.Influencer != "") ? this.savedFilterObject.Influencer.split(this.delimiter) : []
      this.filterMapping.ContinentId = (this.savedFilterObject.Continent != null && this.savedFilterObject.Continent != "") ? this.savedFilterObject.Continent.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.regionId = (this.savedFilterObject.Region != null && this.savedFilterObject.Region != "") ? this.savedFilterObject.Region.split(this.delimiter).map(Number).filter(x => !isNaN(x)) : []
      this.filterMapping.GroupName = (this.savedFilterObject.GroupNames != null && this.savedFilterObject.GroupNames != "") ? this.savedFilterObject.GroupNames.split(this.delimiter) : []
      this.filterMapping.PostCode = (this.savedFilterObject.PostCodes != null && this.savedFilterObject.PostCodes != "") ? this.savedFilterObject.PostCodes.split(this.delimiter) : []
    }

    if (this.StartDate != null && this.EndDate != null) {
      this.isPeriodType = false;
      this.isDateRange = true;
    }

    if (periodType != null) {
      this.isPeriodType = true;
      this.isDateRange = false;
    }

    if (this.filterService.filterObj.EndDate == null && this.filterService.filterObj.StartDate == null && this.periodType == null) {
      this.periodType.M = true;
      this.calculateStartAndEndDate('M');
    }

  }

  convertToDelimiterSeparated(del): any {
    return {
      "BACode": this.filterMapping.BAId != null ? this.filterMapping.BAId.join(del) : "",
      "ProjectManager": this.filterMapping.PMId != null ? this.filterMapping.PMId.join(del) : "",
      "FAMCode": this.filterMapping.FAMId != null ? this.filterMapping.FAMId.join(del) : "",
      "DivCode": this.filterMapping.DIVId != null ? this.filterMapping.DIVId.join(del) : "",
      "BLCode": this.filterMapping.BLId != null ? this.filterMapping.BLId.join(del) : "",
      "Country": this.filterMapping.CountryId != null ? this.filterMapping.CountryId.join(del) : "",
      "OperationalCountry": this.filterMapping.OperationalCountryId != null ? this.filterMapping.OperationalCountryId.join(del) : "",
      "BrandName": this.filterMapping.BrandId != null ? this.filterMapping.BrandId.join(del) : "",
      "SalesEngineer": this.filterMapping.SalesEngId != null ? this.filterMapping.SalesEngId.join(del) : "",
      "Touchpoint": this.filterMapping.TouchPointId != null ? this.filterMapping.TouchPointId.join(del) : "",
      "Influencer": this.filterMapping.InfluencerId != null ? this.filterMapping.InfluencerId.join(del) : "",
      "ServiceTechnician": this.filterMapping.ServiceTechId != null ? this.filterMapping.ServiceTechId.join(del) : "",
      "Continent": this.filterMapping.ContinentId != null ? this.filterMapping.ContinentId.join(del) : "",
      "Region": this.filterMapping.regionId != null ? this.filterMapping.regionId.join(del) : "",
      "Zone": this.filterMapping.ZoneId != null ? this.filterMapping.ZoneId.join(del) : "",
      "Branch": this.filterMapping.BranchId != null ? this.filterMapping.BranchId.join(del) : "",
      "GroupName": this.filterMapping.GroupName != null ? this.filterMapping.GroupName.join(del) : "",
      "PostCode": this.filterMapping.PostCode != null ? this.filterMapping.PostCode.join(del) : "",
      "PinnedField": ""
    }
  }

  reloadData() {

    
    //added new method  'getDistinctByValue' removed getDistinct method and map method to resolve slowness of filter issue, as getDistinct method is very slow to respone 
     //Need casecading filter effect
    this.famData = this.getDistinctByValue(this.data, 'FAMID', 'FAMCode','value');
      this.baData= this.getDistinctByValue(this.data, 'BAID', 'BACode','value');
      this.pmData= this.getDistinctByValue(this.data, 'PMID', 'ProjectManagerName','value');
      this.divData= this.getDistinctByValue(this.data, 'DivisionID', 'DivisionCode','value');
      this.blData= this.getDistinctByValue(this.data, 'BLID', 'BLName','value');

      this.countryData= this.getDistinctByValue(this.countryContinent, 'CountryID', 'CountryName','value');

      this.operationalCountryData= this.getDistinctByValue(this.operationalCountryContinent, 'CountryID', 'CountryName','value');
      
      this.countinentData= this.getDistinctByValue(this.countryContinent, 'ContinentID', 'ContinentName','value');
      this.regionData= this.getDistinctByValue(this.countryContinent, 'RegionID', 'RegionName','value');

      this.zoneData= this.getDistinctByValue(this.zoneBranch, 'ZoneCode', 'ZoneCode', "label");
      this.branchData= this.getDistinctByValue(this.zoneBranch, 'BranchCode', 'BranchCode', "label");

      this.salesEngData= this.getDistinctByValue(this.engineerAndTechnician.filter(x => { if (x.EngineerType == "Sales") { return x; } }), 'EngineerName', 'EngineerName', "label");
      this.serviceTechData= this.getDistinctByValue(this.engineerAndTechnician.filter(x => { if (x.EngineerType == "Service") { return x; } }), 'EngineerName', 'EngineerName', "label");
      
      this.brandData= this.getDistinctByValue(this.brand, 'BrandID', 'BrandName','value');


    //Independent dropdowns
    this.touchPointData = this.formatTouchPointData(this.filterService.touchPointsOriginal);// this.touchPoints.map(x => { return { value: x.TouchPointID, label: x.TouchPointCode } });
    this.influencerData = this.influencers.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });

    // this.brandData = this.brand.map(x => { return { value: x.BrandID, label: x.BrandName } });

    
   
    this.setPinnedFilterValue();
  }

  formatTouchPointData(list: Array<any>): Array<any> {
    // let ids = [];
    // let tpList = [];

    // list.forEach(element => {
    //   if (ids.indexOf(element.TouchPointID) < 0) {
    //     var label = element.TouchPoint;
    //     if (element.ParentTouchPointId > 0) {
    //       let parent = list.filter(ele => { return (ele.TouchPointID == element.ParentTouchPointId) })
    //       label = parent.map(ele => {
    //         return ele.TouchPoint + "-" + label
    //       });
    //       if (label.length > 0) {
    //         label = label[0];
    //       }
    //     }
    //     tpList.push({ label: label, value: element.TouchPointID });
    //     ids.push(element.TouchPointID);
    //   }
    // });
    // tpList = tpList.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    // return tpList;

    let ids = [];
    let tpList = [];
    var uq_list = [];
    var distArr = [];

    list.forEach(function (x) { if (!distArr.includes(x.TouchPointID)) { distArr.push(x.TouchPointID); uq_list.push(x); } });

    uq_list.forEach(element => {
      let childrens = [];
      if (ids.indexOf(element.TouchPointID) < 0 && element.ParentTouchPointId == null) {
        uq_list.forEach(e => {
          if (e.ParentTouchPointId == element.TouchPointID) {
            childrens.push({ label: e.TouchPoint, data: e.TouchPointID, children: [] });
            ids.push(e.TouchPointID);
          }
        })
        tpList.push({ label: element.TouchPoint, data: element.TouchPointID, children: childrens });
        ids.push(element.TouchPointID);
      }
    });
    tpList = tpList.sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
    return tpList;
  }

  onTouchpointUnSelect(event) {
    var UnselectedID = event.node.data;
    var index = this.filterMapping.TouchPointId.indexOf(UnselectedID);
    if (index > -1) {
      this.filterMapping.TouchPointId.splice(index, 1);
    }
    // this.SelectedTouchPoints(true);
    this.updateTouchpointTreeLabel();
  }

  onTouchpointSelect(event) {
    var selectedID = event.node.data;
    var index = this.filterMapping.TouchPointId.indexOf(selectedID);
    if(event.node.parent && event.node.parent.data) {
      let parentIndex =  this.filterMapping.TouchPointId.indexOf(event.node.parent.data)
      if(parentIndex == -1) {
        this.filterMapping.TouchPointId.push(event.node.parent.data);
      }
    }
    
    if (index == -1) {
      this.filterMapping.TouchPointId.push(selectedID);
    }
    // this.SelectedTouchPoints(true);
    this.updateTouchpointTreeLabel();
  }

  updateTouchpointTreeLabel() {
    if (this.filterMapping.TouchPointId == null || this.filterMapping.TouchPointId.length == 0) {
      this.tpSelectionlabel = "Select Touchpoint";
    } else if (this.filterMapping.TouchPointId.length >= 4) {
      this.tpSelectionlabel = this.selectedTouchPointNodes.length + " items selected";
    } else if (this.filterMapping.TouchPointId.length < 4 && this.filterMapping.TouchPointId.length >= 1) {
      this.tpSelectionlabel = this.selectedTouchPointNodes.map(ele => ele.label).join(", ");
    }
  }

  getSelectedNodeArray() {
    var selectedNodes = [];
    this.touchPointData.forEach(ele => {
      if (this.filterMapping.TouchPointId.indexOf(ele.data) >= 0) {
        selectedNodes.push(ele);
      }
      ele.children.forEach(eleChild => {
        if (this.filterMapping.TouchPointId.indexOf(eleChild.data) >= 0) {
          selectedNodes.push(eleChild);
        }
      });

    });
    return selectedNodes;
  }

  pouplateTreeSelection() {
    this.selectedTouchPointNodes = [];
    this.filterMapping.TouchPointId.forEach(element => {
      var selectedNode = null;
      this.touchPointData.forEach(ele => {
        if (ele.data == element) {
          selectedNode = ele;
        } else if (ele.children.length > 0) {
          return (ele.children.filter(eleChild => {
            if (eleChild.data == element) {
              selectedNode = eleChild;
            }
          }))
        }
      });
      if (selectedNode != null) {
        const node = selectedNode;
        this.selectedTouchPointNodes.push(node);
      }
    });
  }

  getViewData() {  
    this.showBusyIndicator = true;
    if (this.filterService.dataOriginal.length > 0 ) {
          //added new method  'getDistinctByValue' removed getDistinct method and map method to resolve slowness of filter issue, as getDistinct method is very slow to respone 
       //Need casecading filter effect
      this.famData = this.getDistinctByValue(this.filterService.dataOriginal, 'FAMID', 'FAMCode','value');
      this.baData= this.getDistinctByValue(this.filterService.dataOriginal, 'BAID', 'BACode','value');
      this.pmData= this.getDistinctByValue(this.filterService.pmOriginal, 'PMID', 'ProjectManagerName','value');
      this.divData= this.getDistinctByValue(this.filterService.dataOriginal, 'DivisionID', 'DivisionCode','value');
      this.blData= this.getDistinctByValue(this.filterService.dataOriginal, 'BLID', 'BLName','value');

      
      this.countryData= this.getDistinctByValue(this.filterService.countryContinentOriginal, 'CountryID', 'CountryName','value');

      this.operationalCountryData= this.getDistinctByValue(this.filterService.operationalCountryContinentOriginal, 'CountryID', 'CountryName','value');

      this.countinentData= this.getDistinctByValue(this.filterService.countryContinentOriginal, 'ContinentID', 'ContinentName','value');

      this.regionData= this.getDistinctByValue(this.filterService.countryContinentOriginal, 'RegionID', 'RegionName','value');

      // do we need to add opertional country continent and region to drop down..

      this.zoneData= this.getDistinctByValue(this.filterService.zoneBranchOriginal, 'ZoneCode', 'ZoneCode', "label");

      this.branchData= this.getDistinctByValue(this.filterService.zoneBranchOriginal, 'BranchCode', 'BranchCode', "label");

      this.salesEngData= this.getDistinctByValue(this.filterService.engineerAndTechnicianOriginal.filter(x => { if (x.EngineerType == "Sales") { return x; } }), 'EngineerName', 'EngineerName', "label");
      this.serviceTechData= this.getDistinctByValue(this.filterService.engineerAndTechnicianOriginal.filter(x => { if (x.EngineerType == "Service") { return x; } }), 'EngineerName', 'EngineerName', "label");
      
      this.brandData= this.getDistinctByValue(this.filterService.brandOriginal, 'BrandID', 'BrandName','value');
      

      //Independent dropdowns
      this.touchPointData = this.formatTouchPointData(this.filterService.touchPointsOriginal); // this.filterService.touchPointsOriginal.map(x => { return { value: x.TouchPointID, label: x.TouchPointCode } });
      this.influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });

      //this.brandData = this.filterService.brandOriginal.map(x => { return { value: x.BrandID, label: x.BrandName } });

     
     

      this.filterMapping.BAId = [];
      this.filterMapping.PMId = [];
      this.filterMapping.FAMId = [];
      this.filterMapping.DIVId = [];
      this.filterMapping.BLId = [];
      this.filterMapping.CountryId = [];
      this.filterMapping.OperationalCountryId = [];
      this.filterMapping.BrandId = [];
      this.filterMapping.ZoneId = [];
      this.filterMapping.BranchId = [];
      this.filterMapping.SalesEngId = [];
      this.filterMapping.ServiceTechId = [];
      this.filterMapping.TouchPointId = [];
      this.filterMapping.InfluencerId = [];
      this.filterMapping.ContinentId = [];
      this.filterMapping.regionId = [];
      this.filterMapping.PostCode = [];
      this.filterMapping.GroupName = [];

      this.getInitialLoadDataForPostCodes();
      this.getInitialLoadDataForPostCodes('grouped');

      //Clear selected meta tag
      for (var key in this.selectedCount) {
        if (this.selectedCount.hasOwnProperty(key)) {
          this.selectedCount[key] = [];
        }
      }
      this.showBusyIndicator = false;


    } else {
      
      this.filterService.getAllData().then(rawdata => {

        if (rawdata.Response != null) {
          
          this.filterService.dataOriginal = Object.assign([],rawdata.Response.FamBADivBLFilterModel);
          this.filterService.zoneBranchOriginal = Object.assign([],rawdata.Response.FamBADivZoneBranchFilterModel);
          this.filterService.engineerAndTechnicianOriginal = Object.assign([],rawdata.Response.FamBANameTypeFilterModel);
          this.filterService.countryContinentOriginal = Object.assign([],rawdata.Response.FamCountryContiRegionFilterModel);
          this.filterService.operationalCountryContinentOriginal = Object.assign([],rawdata.Response.FamOperationalCountryContiRegionFilterModel);

          this.filterService.brandOriginal = Object.assign([],rawdata.Response.FamBABrandFilterModel);
          this.filterService.pmOriginal = Object.assign([],rawdata.Response.FamBAProjectManagerFilterModel);
          this.filterService.touchPointsOriginal = Object.assign([],rawdata.Response.TouchPointFilterModel);
          this.filterService.influencersOriginal = Object.assign([],rawdata.Response.InfluencerModel);
          this.influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });
          this.getInitialLoadDataForPostCodes();
          this.getInitialLoadDataForPostCodes('grouped');
          /* this.applyCasecadingOnList().then(ele => {
            this.setPinnedFilterValue();
            this.applyfilterOnLabel();
            this.showBusyIndicator = false;
          }); */
          this.setAllDataCount();

        }
        else {
          this.applyCasecadingOnList().then(ele => {
            this.setPinnedFilterValue();
            this.applyfilterOnLabel();
            this.showBusyIndicator = false;
          });
        }


      }).catch(error => {
        this.applyCasecadingOnList().then(ele => {
          this.setPinnedFilterValue();
          this.showBusyIndicator = false;
        });
      })
    }

  }

  setAllDataCount() {
   
    //Independent dropdowns
    var touchPointData = this.filterService.touchPointsOriginal.map(x => { return { value: x.TouchPointID, label: x.TouchPointCode } });
    var influencerData = this.filterService.influencersOriginal.map(x => { return { value: x.Influencelevel, label: x.Influencelevel } });

     //added new method  'getDistinctByValue' removed getDistinct method and map method to resolve slowness of filter issue, as getDistinct method is very slow to respone 
    //Need casecading filter effect
    this.countOfAllData.famCuunt = this.getDistinctByValue(this.filterService.dataOriginal, 'FAMID', 'FAMCode','value').length;
    this.countOfAllData.baCount= this.getDistinctByValue(this.filterService.dataOriginal, 'BAID', 'BACode','value').length;
    this.countOfAllData.pmCount= this.getDistinctByValue(this.filterService.pmOriginal, 'PMID', 'ProjectManagerName','value').length;
    this.countOfAllData.divCount= this.getDistinctByValue(this.filterService.dataOriginal, 'DivisionID', 'DivisionCode','value').length;
    this.countOfAllData.blCount= this.getDistinctByValue(this.filterService.dataOriginal, 'BLID', 'BLName','value').length;

    this.countOfAllData.countryCount= this.getDistinctByValue(this.filterService.countryContinentOriginal, 'CountryID', 'CountryName','value').length;

    this.countOfAllData.operationalCountryCount= this.getDistinctByValue(this.filterService.operationalCountryContinentOriginal, 'CountryID', 'CountryName','value').length;
    
    this.countOfAllData.continentCount= this.getDistinctByValue(this.filterService.countryContinentOriginal, 'ContinentID', 'ContinentName','value').length;
    this.countOfAllData.regionCount= this.getDistinctByValue(this.filterService.countryContinentOriginal, 'RegionID', 'RegionName','value').length;

    this.countOfAllData.zoneCount= this.getDistinctByValue(this.filterService.zoneBranchOriginal, 'ZoneCode', 'ZoneCode', "label").length;
    this.countOfAllData.branchCount= this.getDistinctByValue(this.filterService.zoneBranchOriginal, 'BranchCode', 'BranchCode', "label").length;

    this.countOfAllData.salesCount= this.getDistinctByValue(this.filterService.engineerAndTechnicianOriginal.filter(x => { if (x.EngineerType == "Sales") { return x; } }), 'EngineerName', 'EngineerName', "label").length;
    this.countOfAllData.serviceCount= this.getDistinctByValue(this.filterService.engineerAndTechnicianOriginal.filter(x => { if (x.EngineerType == "Service") { return x; } }), 'EngineerName', 'EngineerName', "label").length;
    
    this.countOfAllData.brandCount= this.getDistinctByValue(this.filterService.brandOriginal, 'BrandID', 'BrandName','value').length;
  }

  filterByBA(list, baList): any {
    return list.filter(ele => {
      if (baList.indexOf(ele.BAID) >= 0) {
        return ele;
      }
    });
  };

  filterByFAM(list, famList): any {
    return list.filter(ele => {
      if (famList.indexOf(ele.FAMID) >= 0) {
        return ele;
      }
    });
  };
  filterByDIV(list, divList): any {
    return list.filter(ele => {
      if (divList.indexOf(ele.DivisionID) >= 0 || divList.indexOf(ele.DivID) >= 0) {
        return ele;
      }
    });
  };
  filterByPostCode(list, postCodeList): any {
    return list.filter(ele => {
      if (postCodeList.indexOf(ele.PostCode) >= 0 || postCodeList.indexOf(ele.PostCode) >= 0) {
        return ele;
      }
    });
  };

  // Filter based on FAM
  SelectedFams(event) {
    
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  removeFakeClass() {
    var element = <HTMLElement>document.getElementsByClassName('ui-multiselect-panel')[0];
    if (element) {
      element.classList.remove('remove-arrow');
    }
  }

  onOpenPanel() {
    var element = <HTMLElement>document.getElementsByClassName('ui-multiselect-panel')[0];
    element.className += " remove-arrow";
  }

  // Filter based on BA
  SelectedBas(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList('ba').then(res => {
      this.showBusyIndicator = false;
    });
  }

  // Filter based on DIV
  SelectedDivs(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }
  // Filter based on PM
  SelectedPM(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  // Filter based on BL
  SelectedBls(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedCountries(event) {
    this.showBusyIndicator = true;
    this.hideScroll = false;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedOperationalCountries(event) {
    this.showBusyIndicator = true;
    this.hideScroll = false;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedBrands(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedZones(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedBranchs(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedPms(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedSalesEngs(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedServiceTechs(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedContinents(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }
  SelectedRegions(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedTouchPoints(event) {
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  SelectedInfluncer(event) {
    setTimeout(() => {
      this.applyfilterOnLabel()
    }, 10);
  }
  SelectedPostcodes(data, index?) {
    if (data) {
      let isExistIndex = this.postCodeIdsSelected.findIndex(x=>x.PostCode == data.PostCode);
      if(isExistIndex ==-1) {
        this.postCodeIdsSelected.push(data)
      } else {
        this.postCodeIdsSelected.splice(isExistIndex,1)
      }
      this.filterMapping.PostCode = _.compact(_.uniq(_.map(this.postCodeIdsSelected, x => x.PostCode))) || [];
      this.applyfilterOnLabel();    
    }
  }
  SelectedPostcodesAll() {
    this.allPostalCodeSeleted = !this.allPostalCodeSeleted
    this.postcodeChcked = this.allPostalCodeSeleted;
    if(this.allPostalCodeSeleted === true) {
      if(this.selectedPostalCode)
        this.postCodeIdsSelected =  this.postcodeDataCopyFilter;
      else {
        this.postCodeIdsSelected = this.postcodeDataCopy
      }
    }
    else
      this.postCodeIdsSelected =[];
    this.filterMapping.PostCode = _.compact(_.uniq(_.map(this.postCodeIdsSelected, x => x.PostCode))) || [];
    this.applyfilterOnLabel();    
  }
  SelectedGrpPostcodesAll() {
    this.allPostalCodeGrpSeleted = !this.allPostalCodeGrpSeleted
    this.postcodeGrpChcked = this.allPostalCodeGrpSeleted;
    if(this.allPostalCodeGrpSeleted === true){
      if(this.selectedPostalCode)
        this.postCodeGrpIdsSelected =  this.postcodeGrpDataCopyFilter;
      else {
        this.postCodeGrpIdsSelected = this.postcodeGrpDataCopy
      }
    }
    else
      this.postCodeGrpIdsSelected =[];
    this.filterMapping.GroupName = _.compact(_.uniq(_.map(this.postCodeGrpIdsSelected, x => x.GroupName))) || [];
    this.applyfilterOnLabel();    
}
  SelectedGrpPostcodes(data, index?) {
    if (data) {
      let isExistIndex = this.postCodeGrpIdsSelected.findIndex(x=>x.GroupName == data.GroupName);
      if(isExistIndex ==-1) {
        this.postCodeGrpIdsSelected.push(data)
      } else {
        this.postCodeGrpIdsSelected.splice(isExistIndex,1)
      }
      this.filterMapping.GroupName = _.compact(_.uniq(_.map(this.postCodeGrpIdsSelected, x => x.GroupName))) || [];
      this.applyfilterOnLabel();    
    }
  }

  onDone(type?) {
    // if(type==2){
    //   this.postCodeIdsSelected.forEach((element,index) => {
    //     let findIndex = this.postCodeGrpIdsSelected.findIndex(data=> data.PostCode == element.PostCode)
    //       if(findIndex == -1){
    //         this.postCodeIdsSelected.splice(index,1)
    //       }  
    //     });
    // } else {
    //   this.postCodeGrpIdsSelected.forEach((element,index) => {
    //     let findIndex = this.postCodeIdsSelected.findIndex(data=> data.PostCode == element.PostCode)
    //       if(findIndex == -1){
    //         this.postCodeGrpIdsSelected.splice(index,1)
    //       }  
    //     });
    // }
    if(type== 2) {
      
    }
    
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.applyfilterOnLabel();
      this.showBusyIndicator = false;
    });
    this.status = false;
    this.groupDrpdwnstatus = false;
  }


  SelectedGroupPostcodes(event) {
    if (event !== true)
      this.filterMapping.GroupName = _.compact(_.uniq(_.map(this.postCodeGrpIdsSelected, x => x.GroupName))) || [];
    this.showBusyIndicator = true;
    this.applyCasecadingOnList().then(res => {
      this.showBusyIndicator = false;
    });
  }

  resetFilter() {
    this.tpSelectionlabel = "Select Touchpoint";
    this.postCodeIdsSelected = [];
    this.postCodeGrpIdsSelected = [];
    this.groupPostCodeIdsSelected = [];
    this.getViewData();
  }

  submit() {
    this.GlobalDateChanged(false);
    this.reomveExtraIds();
    var delimiterSepratedObject = this.convertToDelimiterSeparated(this.delimiter);
    var piinedFieldsArray = [];
    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          piinedFieldsArray.push(key);
        }
      }
    }
    delimiterSepratedObject["PinnedField"] = piinedFieldsArray.join("|");
    localStorage.setItem("globalFilterObject", JSON.stringify(delimiterSepratedObject));
    this.filterService.updateFilterObject();
    this.filterService.updateSelectedFilter(this.selectedCount);
    this.setPinnedFilterValue();
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    this.lostfilterService.LostCountSubject.next(true);
    this.submitted.emit(delimiterSepratedObject);
  }

  submitDatefilter() {
    this.successMsg = false;
    var startDate = "";
    var endDate = "";
    var periodType = "";

    if (this.isPeriodType) {
      for (var key in this.periodType) {
        if (this.periodType.hasOwnProperty(key)) {
          if (this.periodType[key] == true) {
            periodType = key;
          }
        }
      }
    }
    else {
      var startDateFormat = this.changeDateFormat(this.startDateCal);
      var startDateVal = new Date(startDateFormat);
      var endDateFormat = this.changeDateFormat(this.endDateCal);
      var endDateVal = new Date(endDateFormat);

      if (moment(startDateVal) <= moment(endDateVal)) {
        this.StartDate = this.startDateCal;
        startDate = this.startDateCal;
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
        return;
      }
      if (moment(endDateVal) >= moment(startDateVal)) {
        this.EndDate = this.endDateCal;
        endDate = this.endDateCal;
      }
      else {
        this.successMsg = true;
        this.callErrorMsg('error', '', 'End Date should not be less than Start Date');
        return;
      }
    }
    //  {
    //   startDate = this.StartDate;
    //   endDate = this.EndDate;
    // }

    var filterObject = {
      "StartDate": startDate,
      "EndDate": endDate,
      "PeriodType": periodType,
    }

    this.GlobalDateChanged();
    localStorage.setItem("periodFilterObject", JSON.stringify(filterObject));
    this.filterService.updateFilterObject();
    this.filterToggle = false;
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
    this.filterService.filterObj.ActionType = "Submit";
    this.lostfilterService.LostCountSubject.next(true);
    this.submitted.emit(this.filterService.filterObj);
  }

  saveMasterFilter(isDate = false) {
    this.GlobalDateChanged(isDate);
    var filterObject = null;
    if (isDate) {
      var startDate = "";
      var endDate = "";
      var periodType = "";

      if (this.isPeriodType) {
        for (var key in this.periodType) {
          if (this.periodType.hasOwnProperty(key)) {
            if (this.periodType[key] == true) {
              periodType = key;
            }
          }
        }
      }
      else {
        var startDateFormat = this.changeDateFormat(this.startDateCal);
        var startDateVal = new Date(startDateFormat);
        var endDateFormat = this.changeDateFormat(this.endDateCal);
        var endDateVal = new Date(endDateFormat);

        if (moment(startDateVal) <= moment(endDateVal)) {
          this.StartDate = this.startDateCal;
          startDate = this.startDateCal;
        }
        else {
          this.successMsg = true;
          this.callErrorMsg('error', '', 'Start Date should not be greater than End Date');
          return;
        }
        if (moment(endDateVal) >= moment(startDateVal)) {
          endDate = this.endDateCal;
          this.EndDate = this.endDateCal;
        }
        else {
          this.successMsg = true;
          this.callErrorMsg('error', '', 'End Date should not be lesser than Start Date');
          return;
        }
      }

      filterObject = {
        "StartDate": startDate,
        "EndDate": endDate,
        "PeriodType": periodType,
      }
    }
    else {
      this.reomveExtraIds();
      filterObject = this.convertToDelimiterSeparated(this.delimiter);
      var piinedFieldsArray = [];
      for (var key in this.pinnedFields) {
        if (this.pinnedFields.hasOwnProperty(key)) {
          if (this.pinnedFields[key] == true) {
            piinedFieldsArray.push(key);
          }
        }
      }
      filterObject["PinnedField"] = piinedFieldsArray.join("|");
    }

    this.filterService.saveUserFilter(filterObject, isDate).then(res => {
      if (res != null && res.Status == "Success") {
        if (isDate) {
          //filterObject = this.filterService.filterObj;
          localStorage.setItem("periodFilterObject", JSON.stringify(filterObject));
          this.filterDateToggle = false;
        } else {
          localStorage.setItem("globalFilterObject", JSON.stringify(filterObject));
          this.filterToggle = false;
        }
        this.filterService.updateFilterObject();
        this.setPinnedFilterValue();
        if (isDate == true)
          this.filterService.filterObj.ActionType = "Save";
        else
          this.filterService.filterObj.ActionType = "Submit";
          this.lostfilterService.LostCountSubject.next(true);
        this.submitted.emit(this.filterService.filterObj);
        this.pinFilterSecVisible = false;
        this.saved.emit("Success");
      }
      else {

        this.saved.emit("Failure");
      }
    }).catch(e => {

      this.saved.emit("ApiError");
    });
  }

  clearUserFilter() {
    this.filterService.clearUserFilter().then(res => {
      if (res.Status == "Success" && res.Response === true) {
        this.getViewData();
        this.setPinnedFilterValue();
        this.postCodeIdsSelected = [];
        this.postCodeGrpIdsSelected = [];
        this.groupPostCodeIdsSelected = [];

        var date = new Date();
        var fromDate = this.changeDateFormate(new Date(date.getFullYear(), date.getMonth(), 1));
        var toDate = this.changeDateFormate(date);

        this.isDateRange = true;
        this.isPeriodType = true;
        this.periodType.Q = false;
        this.periodType.H = false;
        this.periodType.Y = false;
        this.periodType.M = false;


        this.StartDate = fromDate;
        this.EndDate = toDate;
        this.startDateCal = this.StartDate;
        this.endDateCal = this.EndDate;

        for (var key in this.filterService.filterObj) {
          if (this.filterService.filterObj.hasOwnProperty(key)) {
            this.filterService.filterObj[key] = ""
          }
        }

        this.filterService.filterObj.StartDate = fromDate;
        this.filterService.filterObj.EndDate = toDate;

        this.filterService.filterObj
        var filterObjMain = {
          "BACode": "",
          "FAMCode": "",
          "ProjectManager": "",
          "DivCode": "",
          "BLCode": "",
          "Country": "",
          "OperationalCountry": "",
          "BrandName": "",
          "SalesEngineer": "",
          "Touchpoint": "",
          "Influencer": "",
          "ServiceTechnician": "",
          "Continent": "",
          "Region": "",
          "Zone": "",
          "Branch": "",
          "Postcode": "",
          "GroupName": "",
          "PinnedField": "",
          "ActionType": "",
          "ViewAnalysisBarPeriod": "",
          "ViewAnalysisType": "",
          "DispositionStatus": "",
        }

        var filterObjDate = {
          "StartDate": fromDate,
          "EndDate": toDate,
          "PeriodType": ""
        }
        localStorage.setItem("globalFilterObject", JSON.stringify(filterObjMain));
        localStorage.setItem("periodFilterObject", JSON.stringify(filterObjDate));

        this.callErrorMsg('success', '', 'Saved filter cleared successfully.');
      }
      else {
        this.callErrorMsg('error', '', 'Unable to clear saved filter.');
      }
    });
  }

  setPinnedFilterValue() {
    this.pinnedFieldValuesArray.famPinnedValue = [];
    this.pinnedFieldValuesArray.baPinnedValue = [];
    this.pinnedFieldValuesArray.divPinnedValue = [];
    this.pinnedFieldValuesArray.blPinnedValue = [];
    this.pinnedFieldValuesArray.countryPinnedValue = [];
    this.pinnedFieldValuesArray.operationalCountryPinnedValue = [];
    
    this.pinnedFieldValuesArray.brandPinnedValue = [];
    this.pinnedFieldValuesArray.zonePinnedValue = [];
    this.pinnedFieldValuesArray.branchPinnedValue = [];
    this.pinnedFieldValuesArray.salesPinnedValue = [];
    this.pinnedFieldValuesArray.servicePinnedValue = [];
    this.pinnedFieldValuesArray.touchpointPinnedValue = [];
    this.pinnedFieldValuesArray.influencerPinnedValue = [];
    this.pinnedFieldValuesArray.continentPinnedValue = [];
    this.pinnedFieldValuesArray.regionPinnedValue = [];
    this.pinnedFieldValuesArray.pmPinnedValue = [];
    this.pinnedFieldValuesArray.postcodePinnedValue = [];
    this.pinnedFieldValuesArray.groupPostcodePinnedValue = [];

    if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length > 0) {
      var codes = this.famData.filter(ele => {
        if (this.filterMapping.FAMId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.famPinnedValue = this.filterMapping.FAMId.length == 1 ? codes[0].toString() : (this.filterMapping.FAMId.length == this.countOfAllData.famCuunt ? "All" : codes.length);
      this.pinnedFieldValuesArray.famPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.famPinnedValue = "All";
    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length > 0) {
      var codes = this.baData.filter(ele => {
        if (this.filterMapping.BAId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.baPinnedValue = this.filterMapping.BAId.length == 1 ? codes[0].toString() : (this.filterMapping.BAId.length == this.countOfAllData.baCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.baPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.baPinnedValue = "All";
    }

    if (this.filterMapping.PMId != null && this.filterMapping.PMId.length > 0) {
      var codes = this.pmData.filter(ele => {
        if (this.filterMapping.PMId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.pmPinnedValue = this.filterMapping.PMId.length == 1 ? codes[0].toString() : (this.filterMapping.PMId.length == this.countOfAllData.pmCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.pmPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.pmPinnedValue = "All";
    }

    if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length > 0) {
      var codes = this.divData.filter(ele => {
        if (this.filterMapping.DIVId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.divPinnedValue = this.filterMapping.DIVId.length == 1 ? codes[0].toString() : (this.filterMapping.DIVId.length == this.countOfAllData.divCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.divPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.divPinnedValue = "All";
    }

    if (this.filterMapping.BLId != null && this.filterMapping.BLId.length > 0) {
      var codes = this.blData.filter(ele => {
        if (this.filterMapping.BLId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.blPinnedValue = this.filterMapping.BLId.length == 1 ? codes[0].toString() : (this.filterMapping.BLId.length == this.countOfAllData.blCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.blPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.blPinnedValue = "All";
    }

    if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length > 0) {
      var codes = this.countryData.filter(ele => {
        if (this.filterMapping.CountryId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.countryPinnedValue = this.filterMapping.CountryId.length == 1 ? codes[0].toString() : (this.filterMapping.CountryId.length == this.countOfAllData.countryCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.countryPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.countryPinnedValue = "All";
    }

    if (this.filterMapping.OperationalCountryId != null && this.filterMapping.OperationalCountryId.length > 0) {
      var codes = this.operationalCountryData.filter(ele => {
        if (this.filterMapping.OperationalCountryId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.operationalCountryPinnedValue = this.filterMapping.OperationalCountryId.length == 1 ? codes[0].toString() : (this.filterMapping.OperationalCountryId.length == this.countOfAllData.operationalCountryCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.operationalCountryPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.operationalCountryPinnedValue = "All";
    }


    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length > 0) {
      var codes = this.brandData.filter(ele => {
        if (this.filterMapping.BrandId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.brandPinnedValue = this.filterMapping.BrandId.length == 1 ? codes[0].toString() : (this.filterMapping.BrandId.length == this.countOfAllData.brandCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.brandPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.brandPinnedValue = "All";
    }

    if (this.filterMapping.ZoneId != null && this.filterMapping.ZoneId.length > 0) {
      var codes = this.zoneData.filter(ele => {
        if (this.filterMapping.ZoneId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.zonePinnedValue = this.filterMapping.ZoneId.length == 1 ? codes[0].toString() : (this.filterMapping.ZoneId.length == this.countOfAllData.zoneCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.zonePinnedValue = codes;
    } else {
      this.pinnedFieldValuess.zonePinnedValue = "All";
    }

    if (this.filterMapping.BranchId != null && this.filterMapping.BranchId.length > 0) {
      var codes = this.branchData.filter(ele => {
        if (this.filterMapping.BranchId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.branchPinnedValue = this.filterMapping.BranchId.length == 1 ? codes[0].toString() : (this.filterMapping.BranchId.length == this.countOfAllData.branchCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.branchPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.branchPinnedValue = "All";
    }

    if (this.filterMapping.SalesEngId != null && this.filterMapping.SalesEngId.length > 0) {
      var codes = this.salesEngData.filter(ele => {
        if (this.filterMapping.SalesEngId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.salesPinnedValue = this.filterMapping.SalesEngId.length == 1 ? codes[0].toString() : (this.filterMapping.SalesEngId.length == this.countOfAllData.salesCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.salesPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.salesPinnedValue = "All";
    }

    if (this.filterMapping.ServiceTechId != null && this.filterMapping.ServiceTechId.length > 0) {
      var codes = this.serviceTechData.filter(ele => {
        if (this.filterMapping.ServiceTechId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.servicePinnedValue = this.filterMapping.ServiceTechId.length == 1 ? codes[0].toString() : (this.filterMapping.ServiceTechId.length == this.countOfAllData.serviceCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.servicePinnedValue = codes;
    } else {
      this.pinnedFieldValuess.servicePinnedValue = "All";
    }

    if (this.filterMapping.TouchPointId != null && this.filterMapping.TouchPointId.length > 0) {
      var codes = this.getSelectedNodeArray().map(ele => { return ele.label as any });
      this.pinnedFieldValuess.touchpointPinnedValue = this.filterMapping.TouchPointId.length == 1 ? codes[0].toString() : (this.filterMapping.TouchPointId.length == this.countOfAllData.touchpointCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.touchpointPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.touchpointPinnedValue = "All";
    }

    if (this.filterMapping.InfluencerId != null && this.filterMapping.InfluencerId.length > 0) {
      var codes = this.influencerData.filter(ele => {
        if (this.filterMapping.InfluencerId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.influencerPinnedValue = this.filterMapping.InfluencerId.length == 1 ? codes[0].toString() : (this.filterMapping.InfluencerId.length == this.countOfAllData.influencerCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.influencerPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.influencerPinnedValue = "All";
    }

    if (this.filterMapping.ContinentId != null && this.filterMapping.ContinentId.length > 0) {
      var codes = this.countinentData.filter(ele => {
        if (this.filterMapping.ContinentId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.continentPinnedValue = this.filterMapping.ContinentId.length == 1 ? codes[0].toString() : (this.filterMapping.ContinentId.length == this.countOfAllData.continentCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.continentPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.continentPinnedValue = "All";
    }

    if (this.filterMapping.regionId != null && this.filterMapping.regionId.length > 0) {
      var codes = this.regionData.filter(ele => {
        if (this.filterMapping.regionId.indexOf(ele.value) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.label });
      this.pinnedFieldValuess.regionPinnedValue = this.filterMapping.regionId.length == 1 ? codes[0].toString() : (this.filterMapping.regionId.length == this.countOfAllData.regionCount ? "All" : codes.length);
      this.pinnedFieldValuesArray.regionPinnedValue = codes;
    } else {
      this.pinnedFieldValuess.regionPinnedValue = "All";
    }

    if (this.filterMapping.PostCode != null && this.filterMapping.PostCode.length > 0) {
      var codes = this.postCodeIdsSelected.filter(ele => {
        if (this.filterMapping.PostCode.indexOf(ele.PostCode) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.PostCode });
      this.pinnedFieldValuess.postcodePinnedValue = this.filterMapping.PostCode.length == 1 ? codes[0].toString() : codes.length;
      this.pinnedFieldValuesArray.postcodePinnedValue = codes;
    } else {
      this.pinnedFieldValuess.postcodePinnedValue = "All";
    }
    if (this.filterMapping.GroupName != null && this.filterMapping.GroupName.length > 0) {
      var codes = this.postCodeGrpIdsSelected.filter(ele => {
        if (this.filterMapping.GroupName.indexOf(ele.GroupName) >= 0) {
          return ele;
        }
      }).map(ele => { return ele.GroupName });
      this.pinnedFieldValuess.groupPostcodePinnedValue = this.filterMapping.GroupName.length == 1 ? codes[0].toString() : codes.length;
      this.pinnedFieldValuesArray.groupPostcodePinnedValue = codes;
    } else {
      this.pinnedFieldValuess.groupPostcodePinnedValue = "All";
    }

    if (this.filterService.filterObj.PinnedField != null && this.filterService.filterObj.PinnedField != "") {
      for (var key in this.pinnedFields) {
        if (this.pinnedFields.hasOwnProperty(key)) {
          this.pinnedFields[key] = false;
          if (this.filterService.filterObj.PinnedField.split(this.delimiter).indexOf(key) >= 0) {
            this.pinnedFields[key] = true;
          }
        }
      }
    }


  }

  reomveExtraIds(displayOnly = false) {

    var famids = [];
    var baids = [];
    var divids = [];
    var pmids = [];
    var blids = [];
    var countryids = [];
    var operationalcountryids = [];
    var brandids = [];
    var zoneids = [];
    var branchids = [];
    var salesids = [];
    var serviceids = [];
    var continentids = [];
    var regionids = [];
    var postcodeids = [];
    var grouppostcodeids = [];

    if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length > 0) {
      this.filterMapping.FAMId.forEach(element => {
        if (this.famData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          famids.push(element);
        }
      });
    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length > 0) {
      this.filterMapping.BAId.forEach(element => {
        if (this.baData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          baids.push(element);
        }
      });
    }

    if (this.filterMapping.PMId != null && this.filterMapping.PMId.length > 0) {
      this.filterMapping.PMId.forEach(element => {
        if (this.pmData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          pmids.push(element);
        }
      });
    }

    if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length > 0) {
      this.filterMapping.DIVId.forEach(element => {
        if (this.divData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          divids.push(element);
        }
      });
    }

    if (this.filterMapping.BLId != null && this.filterMapping.BLId.length > 0) {
      this.filterMapping.BLId.forEach(element => {
        if (this.blData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          blids.push(element);
        }
      });
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length > 0) {
      this.filterMapping.BrandId.forEach(element => {
        if (this.brandData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          brandids.push(element);
        }
      });
    }

    if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length > 0) {
      this.filterMapping.CountryId.forEach(element => {
        if (this.countryData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          countryids.push(element);
        }
      });

    }

    if (this.filterMapping.OperationalCountryId != null && this.filterMapping.OperationalCountryId.length > 0) {
      this.filterMapping.OperationalCountryId.forEach(element => {
        if (this.operationalCountryData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          operationalcountryids.push(element);
        }
      });

    }

    if (this.filterMapping.ContinentId != null && this.filterMapping.ContinentId.length > 0) {
      this.filterMapping.ContinentId.forEach(element => {
        if (this.countinentData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          continentids.push(element);
        }
      });

    }
    if (this.filterMapping.regionId != null && this.filterMapping.regionId.length > 0) {
      this.filterMapping.regionId.forEach(element => {
        if (this.regionData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          regionids.push(element);
        }
      });
    }

    if (this.filterMapping.ZoneId != null && this.filterMapping.ZoneId.length > 0) {
      this.filterMapping.ZoneId.forEach(element => {
        if (this.zoneData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          zoneids.push(element);
        }
      });
    }

    if (this.filterMapping.BranchId != null && this.filterMapping.BranchId.length > 0) {
      this.filterMapping.BranchId.forEach(element => {
        if (this.branchData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          branchids.push(element);
        }
      });
    }

    if (this.filterMapping.ServiceTechId != null && this.filterMapping.ServiceTechId.length > 0) {
      this.filterMapping.ServiceTechId.forEach(element => {
        if (this.serviceTechData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          serviceids.push(element);
        }
      });
    }

    if (this.filterMapping.SalesEngId != null && this.filterMapping.SalesEngId.length > 0) {
      this.filterMapping.SalesEngId.forEach(element => {
        if (this.salesEngData.map(ele => { return ele.value }).indexOf(element) >= 0) {
          salesids.push(element);
        }
      });
    }
    if (this.filterMapping.PostCode != null && this.filterMapping.PostCode.length > 0) {
      this.filterMapping.PostCode.forEach(element => {
        if (this.postCodeIdsSelected.map(ele => { return ele.PostCode }).indexOf(element) >= 0) {
          postcodeids.push(element);
        }
      });
    }
    if (this.filterMapping.GroupName != null && this.filterMapping.GroupName.length > 0) {
      this.filterMapping.GroupName.forEach(element => {
        if (this.postCodeGrpIdsSelected.map(ele => { return ele.GroupName }).indexOf(element) >= 0) {
          grouppostcodeids.push(element);
        }
      });
    }
    if (displayOnly) {
      this.selectedCount.famCount = this.famData.filter(ele => { if (famids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.baCount = this.baData.filter(ele => { if (baids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.pmCount = this.pmData.filter(ele => { if (pmids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.divCount = this.divData.filter(ele => { if (divids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.blCount = this.blData.filter(ele => { if (blids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.countryCount = this.countryData.filter(ele => { if (countryids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.operationalCountryCount = this.operationalCountryData.filter(ele => { if (operationalcountryids.indexOf(ele.value) >= 0) { return ele } });

      this.selectedCount.brandCount = this.brandData.filter(ele => { if (brandids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.salesCount = this.salesEngData.filter(ele => { if (salesids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.serviceCount = this.serviceTechData.filter(ele => { if (serviceids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.zoneCount = this.zoneData.filter(ele => { if (zoneids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.branchCount = this.branchData.filter(ele => { if (branchids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.continentCount = this.countinentData.filter(ele => { if (continentids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.regionCount = this.regionData.filter(ele => { if (regionids.indexOf(ele.value) >= 0) { return ele } });
      this.selectedCount.postcodeCount = this.postcodeData.filter(ele => { if (postcodeids.indexOf(ele.PostCode) >= 0) { return { value: ele.PostCode, label: ele.PostCode } } });
      this.selectedCount.groupPostcodeCount = this.groupPostcodeData.filter(ele => { if (grouppostcodeids.indexOf(ele.GroupName) >= 0) { return { value: ele.GroupName, label: ele.GroupName } } });

      this.selectedCount.touchpointCount = this.getSelectedNodeArray();//this.touchPointData.filter(ele => { if (this.filterMapping.TouchPointId.indexOf(ele.data) >= 0) { return ele } });
      this.selectedCount.influencerCount = this.influencerData.filter(ele => { if (this.filterMapping.InfluencerId.indexOf(ele.value) >= 0) { return ele } });
      this.filterService.updateSelectedFilter(this.selectedCount);
      //.map(ele=>{ return ele.label});
    } else {
      
      this.filterMapping.FAMId = famids;
      this.filterMapping.BAId = baids;
      this.filterMapping.PMId = pmids;
      this.filterMapping.DIVId = divids;
      this.filterMapping.BLId = blids;
      this.filterMapping.CountryId = countryids;
      this.filterMapping.OperationalCountryId = operationalcountryids;
      this.filterMapping.BrandId = brandids;
      this.filterMapping.ZoneId = zoneids;
      this.filterMapping.BranchId = branchids;
      this.filterMapping.SalesEngId = salesids;
      this.filterMapping.ServiceTechId = serviceids;
      this.filterMapping.ContinentId = continentids;
      this.filterMapping.regionId = regionids;
      this.filterMapping.PostCode = postcodeids;
      this.filterMapping.GroupName = grouppostcodeids;
    }

  }

  togglePin(fieldName) {
    var pinnedCount = 0;

    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          pinnedCount++;
        }
      }
    }
    console.log('togglePin');
    if (pinnedCount <= 3) {
      switch (fieldName) {
        case "fam": {
          this.pinnedFields.famPinned = !this.pinnedFields.famPinned;
          break;
        }
        case "ba": {
          this.pinnedFields.baPinned = !this.pinnedFields.baPinned;
          break;
        }
        case "div": {
          this.pinnedFields.divPinned = !this.pinnedFields.divPinned;
          break;
        }
        case "bl": {
          this.pinnedFields.blPinned = !this.pinnedFields.blPinned;
          break;
        }
        case "country": {
          this.pinnedFields.countryPinned = !this.pinnedFields.countryPinned;
          break;
        }
        case "operationalCountry": {
          this.pinnedFields.operationalCountryPinned = !this.pinnedFields.operationalCountryPinned;
          break;
        }
        case "brand": {
          this.pinnedFields.brandPinned = !this.pinnedFields.brandPinned;
          break;
        }
        case "pm": {
          this.pinnedFields.pmPinned = !this.pinnedFields.pmPinned;
          break;
        }
        case "zone": {
          this.pinnedFields.zonePinned = !this.pinnedFields.zonePinned;
          break;
        }
        case "branch": {
          this.pinnedFields.branchPinned = !this.pinnedFields.branchPinned;
          break;
        }
        case "sales": {
          this.pinnedFields.salesPinned = !this.pinnedFields.salesPinned;
          break;
        }
        case "service": {
          this.pinnedFields.servicePinned = !this.pinnedFields.servicePinned;
          break;
        }
        case "touchpoint": {
          this.pinnedFields.touchpointPinned = !this.pinnedFields.touchpointPinned;
          break;
        }
        case "influencer": {
          this.pinnedFields.influencerPinned = !this.pinnedFields.influencerPinned;
          break;
        }
        case "continent": {
          this.pinnedFields.continentPinned = !this.pinnedFields.continentPinned;
          break;
        }
        case "region": {
          this.pinnedFields.regionPinned = !this.pinnedFields.regionPinned;
          break;
        }
        case "postcode": {
          this.pinnedFields.postcodePinned = !this.pinnedFields.postcodePinned;
          break;
        }
        case "groupPostcode": {
          this.pinnedFields.groupPostcodePinned = !this.pinnedFields.groupPostcodePinned;
          break;
        }
      }
    }
    else {

      switch (fieldName) {
        case "fam": {
          this.isShowAlert = !this.pinnedFields.famPinned;
          this.pinnedFields.famPinned = this.pinnedFields.famPinned ? !this.pinnedFields.famPinned : this.pinnedFields.famPinned;
          break;
        }
        case "ba": {
          this.isShowAlert = !this.pinnedFields.baPinned;
          this.pinnedFields.baPinned = this.pinnedFields.baPinned ? !this.pinnedFields.baPinned : this.pinnedFields.baPinned;
          break;
        }

        case "pm": {
          this.isShowAlert = !this.pinnedFields.pmPinned;
          this.pinnedFields.pmPinned = this.pinnedFields.pmPinned ? !this.pinnedFields.pmPinned : this.pinnedFields.pmPinned;
          break;
        }
        case "div": {
          this.isShowAlert = !this.pinnedFields.divPinned;
          this.pinnedFields.divPinned = this.pinnedFields.divPinned ? !this.pinnedFields.divPinned : this.pinnedFields.divPinned;
          break;
        }
        case "bl": {
          this.isShowAlert = !this.pinnedFields.blPinned;
          this.pinnedFields.blPinned = this.pinnedFields.blPinned ? !this.pinnedFields.blPinned : this.pinnedFields.blPinned;
          break;
        }
        case "country": {
          this.isShowAlert = !this.pinnedFields.countryPinned;
          this.pinnedFields.countryPinned = this.pinnedFields.countryPinned ? !this.pinnedFields.countryPinned : this.pinnedFields.countryPinned;
          break;
        }
        case "operationalCountry": {
          this.isShowAlert = !this.pinnedFields.operationalCountryPinned;
          this.pinnedFields.operationalCountryPinned = this.pinnedFields.operationalCountryPinned ? !this.pinnedFields.operationalCountryPinned : this.pinnedFields.operationalCountryPinned;
          break;
        }
        case "brand": {
          this.isShowAlert = !this.pinnedFields.brandPinned;
          this.pinnedFields.brandPinned = this.pinnedFields.brandPinned ? !this.pinnedFields.brandPinned : this.pinnedFields.brandPinned;
          break;
        }
        case "zone": {
          this.isShowAlert = !this.pinnedFields.zonePinned;
          this.pinnedFields.zonePinned = this.pinnedFields.zonePinned ? !this.pinnedFields.zonePinned : this.pinnedFields.zonePinned;
          break;
        }
        case "branch": {
          this.isShowAlert = !this.pinnedFields.branchPinned;
          this.pinnedFields.branchPinned = this.pinnedFields.branchPinned ? !this.pinnedFields.branchPinned : this.pinnedFields.branchPinned;
          break;
        }
        case "sales": {
          this.isShowAlert = !this.pinnedFields.salesPinned;
          this.pinnedFields.salesPinned = this.pinnedFields.salesPinned ? !this.pinnedFields.salesPinned : this.pinnedFields.salesPinned;
          break;
        }
        case "service": {
          this.isShowAlert = !this.pinnedFields.servicePinned;
          this.pinnedFields.servicePinned = this.pinnedFields.servicePinned ? !this.pinnedFields.servicePinned : this.pinnedFields.servicePinned;
          break;
        }
        case "touchpoint": {
          this.isShowAlert = !this.pinnedFields.touchpointPinned;
          this.pinnedFields.touchpointPinned = this.pinnedFields.touchpointPinned ? !this.pinnedFields.touchpointPinned : this.pinnedFields.touchpointPinned;
          break;
        }
        case "influencer": {
          this.isShowAlert = !this.pinnedFields.influencerPinned;
          this.pinnedFields.influencerPinned = this.pinnedFields.influencerPinned ? !this.pinnedFields.influencerPinned : this.pinnedFields.influencerPinned;
          break;
        }
        case "continent": {
          this.isShowAlert = !this.pinnedFields.continentPinned;
          this.pinnedFields.continentPinned = this.pinnedFields.continentPinned ? !this.pinnedFields.continentPinned : this.pinnedFields.continentPinned;
          break;
        }
        case "region": {
          this.isShowAlert = !this.pinnedFields.regionPinned;
          this.pinnedFields.regionPinned = this.pinnedFields.regionPinned ? !this.pinnedFields.regionPinned : this.pinnedFields.regionPinned;
          break;
        }
        case "postcode": {
          this.isShowAlert = !this.pinnedFields.postcodePinned;
          this.pinnedFields.postcodePinned = this.pinnedFields.postcodePinned ? !this.pinnedFields.postcodePinned : this.pinnedFields.postcodePinned;
          break;
        }
        case "groupPostcode": {
          this.isShowAlert = !this.pinnedFields.groupPostcodePinned;
          this.pinnedFields.groupPostcodePinned = this.pinnedFields.groupPostcodePinned ? !this.pinnedFields.groupPostcodePinned : this.pinnedFields.groupPostcodePinned;
          break;
        }
      }


      if (this.isShowAlert) {
        this.successMsg = true;
        this.callErrorMsg('info', '', 'You can select only 4 quick filters.');
        // alert('Can not pin more than four field. Please unpin one of the finned field first.');
      }

    }


    var piinedFieldsArray = [];
    for (var key in this.pinnedFields) {
      if (this.pinnedFields.hasOwnProperty(key)) {
        if (this.pinnedFields[key] == true) {
          piinedFieldsArray.push(key);
        }
      }
    }

    var valueFromSession = localStorage.getItem("globalFilterObject");
    var delimiterSepratedObject = this.filterService.filterObj
    if (valueFromSession != null) {
      delimiterSepratedObject = JSON.parse(localStorage.getItem("globalFilterObject"))
    }
    delimiterSepratedObject["PinnedField"] = piinedFieldsArray.join("|");

    localStorage.setItem("globalFilterObject", JSON.stringify(delimiterSepratedObject));
    this.filterService.updateFilterObject();

    this.pinChanged.emit(this.pinnedFields);
  }

  toggleFilter() {
    this.filterToggle = !this.filterToggle;

    if (this.filterToggle) {
      this.convertToArray();
      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then(res => {
        this.showBusyIndicator = false;
      });
    }
    this.filterDateToggle = false;
    this.pinFilterSecVisible = false;
  }

  toggleDateFilter() {
    this.filterDateToggle = !this.filterDateToggle;
    if (this.filterDateToggle) {
      this.convertToArray();
    }
    this.filterToggle = false;
    this.pinFilterSecVisible = false;
  }

  closeInlineFilter() {
    this.pinFilterSecVisible = false;
  }

  toggleInlineFilter(fieldName) {
    this.status = false;
    this.groupDrpdwnstatus =  false;
    this.pinFilterSecVisible = !this.pinFilterSecVisible

    if (this.pinFilterSecVisible) {
      this.convertToArray();

      this.showBusyIndicator = true;
      this.applyCasecadingOnList().then(res => {
        this.showBusyIndicator = false;
      });
    }

    for (var key in this.inlineFilter) {
      if (this.inlineFilter.hasOwnProperty(key)) {
        this.inlineFilter[key] = false;
        if (key == fieldName) {
          this.inlineFilter[key] = true;
        }
      }
    }

    this.filterDateToggle = false;
    this.filterToggle = false;
  }
  applyfilterOnLabel() {
   
    this.reomveExtraIds(true);
    if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length >= 4) {
      if (this.famDDL) {
        this.famDDL.valuesAsString = this.selectedCount.famCount.length + " items selected";
      }
      else if (this.famDDLPinned) {
        this.famDDLPinned.valuesAsString = this.selectedCount.famCount.length + " items selected";
      }

    }
    else if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length < 4 && this.filterMapping.FAMId.length >= 1) {
      if (this.famDDL) {
        this.famDDL.valuesAsString = this.selectedCount.famCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.famDDLPinned) {
        this.famDDLPinned.valuesAsString = this.selectedCount.famCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length >= 4) {
      if (this.baDDL) {
        this.baDDL.valuesAsString = this.selectedCount.baCount.length + " items selected";
      }
      else if (this.baDDLPinned) {
        this.baDDLPinned.valuesAsString = this.selectedCount.baCount.length + " items selected";
      }

    }
    else if (this.filterMapping.BAId != null && this.filterMapping.BAId.length < 4 && this.filterMapping.BAId.length >= 1) {
      if (this.baDDL) {
        this.baDDL.valuesAsString = this.selectedCount.baCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.baDDLPinned) {
        this.baDDLPinned.valuesAsString = this.selectedCount.baCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.PMId != null && this.filterMapping.PMId.length >= 4) {
      if (this.pmDDL) {
        this.pmDDL.valuesAsString = this.selectedCount.pmCount.length + " items selected";
      }
      else if (this.pmDDLPinned) {
        this.pmDDLPinned.valuesAsString = this.selectedCount.pmCount.length + " items selected";
      }

    }
    else if (this.filterMapping.PMId != null && this.filterMapping.PMId.length < 4 && this.filterMapping.PMId.length >= 1) {
      if (this.pmDDL) {
        this.pmDDL.valuesAsString = this.selectedCount.pmCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.pmDDLPinned) {
        this.pmDDLPinned.valuesAsString = this.selectedCount.pmCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length >= 4) {
      if (this.divDDL) {
        this.divDDL.valuesAsString = this.selectedCount.divCount.length + " items selected";
      }
      else if (this.divDDLPinned) {
        this.divDDLPinned.valuesAsString = this.selectedCount.divCount.length + " items selected";
      }

    }
    else if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length < 4 && this.filterMapping.DIVId.length >= 1) {
      if (this.divDDL) {
        this.divDDL.valuesAsString = this.selectedCount.divCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.divDDLPinned) {
        this.divDDLPinned.valuesAsString = this.selectedCount.divCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.BLId != null && this.filterMapping.BLId.length >= 4) {
      if (this.blDDL) {
        this.blDDL.valuesAsString = this.selectedCount.blCount.length + " items selected";
      }
      else if (this.blDDLPinned) {
        this.blDDLPinned.valuesAsString = this.selectedCount.blCount.length + " items selected";
      }

    }
    else if (this.filterMapping.BLId != null && this.filterMapping.BLId.length < 4 && this.filterMapping.BLId.length >= 1) {
      if (this.blDDL) {
        this.blDDL.valuesAsString = this.selectedCount.blCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.blDDLPinned) {
        this.blDDLPinned.valuesAsString = this.selectedCount.blCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length >= 4) {
      if (this.countryDDL) {
        this.countryDDL.valuesAsString = this.selectedCount.countryCount.length + " items selected";
      }
      else if (this.countryDDLPinned) {
        this.countryDDLPinned.valuesAsString = this.selectedCount.countryCount.length + " items selected";
      }

    }
    else if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length < 4 && this.filterMapping.CountryId.length >= 1) {
      if (this.countryDDL) {
        this.countryDDL.valuesAsString = this.selectedCount.countryCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.countryDDLPinned) {
        this.countryDDLPinned.valuesAsString = this.selectedCount.countryCount.map(ele => { return ele.label }).join(", ");
      }
    }


    if (this.filterMapping.OperationalCountryId != null && this.filterMapping.OperationalCountryId.length >= 4) {
      if (this.operationalCountryDDL) {
        this.operationalCountryDDL.valuesAsString = this.selectedCount.operationalCountryCount.length + " items selected";
      }
      else if (this.operationalCountryDDLPinned) {
        this.operationalCountryDDLPinned.valuesAsString = this.selectedCount.operationalCountryCount.length + " items selected";
      }

    }
    else if (this.filterMapping.OperationalCountryId != null && this.filterMapping.OperationalCountryId.length < 4 && this.filterMapping.OperationalCountryId.length >= 1) {
      if (this.operationalCountryDDL) {
        this.operationalCountryDDL.valuesAsString = this.selectedCount.operationalCountryCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.operationalCountryDDLPinned) {
        this.operationalCountryDDLPinned.valuesAsString = this.selectedCount.operationalCountryCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length >= 4) {
      if (this.brandDDL) {
        this.brandDDL.valuesAsString = this.selectedCount.brandCount.length + " items selected";
      } else if (this.brandDDLPinned) {
        this.brandDDLPinned.valuesAsString = this.selectedCount.brandCount.length + " items selected";
      }
    }
    else if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length < 4 && this.filterMapping.BrandId.length >= 1) {
      if (this.brandDDL) {
        this.brandDDL.valuesAsString = this.selectedCount.brandCount.map(ele => { return ele.label }).join(", ");
      } else if (this.brandDDLPinned) {
        this.brandDDLPinned.valuesAsString = this.selectedCount.brandCount.map(ele => { return ele.label }).join(", ")
      }
    }

    if (this.filterMapping.ZoneId != null && this.filterMapping.ZoneId.length >= 4) {
      if (this.zoneDDL) {
        this.zoneDDL.valuesAsString = this.selectedCount.zoneCount.length + " items selected";
      }
      else if (this.zoneDDLPinned) {
        this.zoneDDLPinned.valuesAsString = this.selectedCount.zoneCount.length + " items selected";
      }

    }
    else if (this.filterMapping.ZoneId != null && this.filterMapping.ZoneId.length < 4 && this.filterMapping.ZoneId.length >= 1) {
      if (this.zoneDDL) {
        this.zoneDDL.valuesAsString = this.selectedCount.zoneCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.zoneDDLPinned) {
        this.zoneDDLPinned.valuesAsString = this.selectedCount.zoneCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.BranchId != null && this.filterMapping.BranchId.length >= 4) {
      if (this.branchDDL) {
        this.branchDDL.valuesAsString = this.selectedCount.branchCount.length + " items selected";
      }
      else if (this.branchDDLPinned) {
        this.branchDDLPinned.valuesAsString = this.selectedCount.branchCount.length + " items selected";
      }

    }
    else if (this.filterMapping.BranchId != null && this.filterMapping.BranchId.length < 4 && this.filterMapping.BranchId.length >= 1) {
      if (this.branchDDL) {
        this.branchDDL.valuesAsString = this.selectedCount.branchCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.branchDDLPinned) {
        this.branchDDLPinned.valuesAsString = this.selectedCount.branchCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.SalesEngId != null && this.filterMapping.SalesEngId.length >= 4) {
      if (this.salesDDL) {
        this.salesDDL.valuesAsString = this.selectedCount.salesCount.length + " items selected";
      }
      else if (this.salesDDLPinned) {
        this.salesDDLPinned.valuesAsString = this.selectedCount.salesCount.length + " items selected";
      }

    }
    else if (this.filterMapping.SalesEngId != null && this.filterMapping.SalesEngId.length < 4 && this.filterMapping.SalesEngId.length >= 1) {
      if (this.salesDDL) {
        this.salesDDL.valuesAsString = this.selectedCount.salesCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.salesDDLPinned) {
        this.salesDDLPinned.valuesAsString = this.selectedCount.salesCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.ServiceTechId != null && this.filterMapping.ServiceTechId.length >= 4) {
      if (this.serviceDDL) {
        this.serviceDDL.valuesAsString = this.selectedCount.serviceCount.length + " items selected";
      }
      else if (this.serviceDDLPinned) {
        this.serviceDDLPinned.valuesAsString = this.selectedCount.serviceCount.length + " items selected";
      }

    }
    else if (this.filterMapping.ServiceTechId != null && this.filterMapping.ServiceTechId.length < 4 && this.filterMapping.ServiceTechId.length >= 1) {
      if (this.serviceDDL) {
        this.serviceDDL.valuesAsString = this.selectedCount.serviceCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.serviceDDLPinned) {
        this.serviceDDLPinned.valuesAsString = this.selectedCount.serviceCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.ContinentId != null && this.filterMapping.ContinentId.length >= 4) {
      if (this.continentDDL) {
        this.continentDDL.valuesAsString = this.selectedCount.continentCount.length + " items selected";
      }
      else if (this.continentDDLPinned) {
        this.continentDDLPinned.valuesAsString = this.selectedCount.continentCount.length + " items selected";
      }

    }
    else if (this.filterMapping.ContinentId != null && this.filterMapping.ContinentId.length < 4 && this.filterMapping.ContinentId.length >= 1) {
      if (this.continentDDL) {
        this.continentDDL.valuesAsString = this.selectedCount.continentCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.continentDDLPinned) {
        this.continentDDLPinned.valuesAsString = this.selectedCount.continentCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if (this.filterMapping.regionId != null && this.filterMapping.regionId.length >= 4) {
      if (this.regionDDL) {
        this.regionDDL.valuesAsString = this.selectedCount.regionCount.length + " items selected";
      }
      else if (this.regionDDLPinned) {
        this.regionDDLPinned.valuesAsString = this.selectedCount.regionCount.length + " items selected";
      }

    }
    else if (this.filterMapping.regionId != null && this.filterMapping.regionId.length < 4 && this.filterMapping.regionId.length >= 1) {
      if (this.regionDDL) {
        this.regionDDL.valuesAsString = this.selectedCount.regionCount.map(ele => { return ele.label }).join(", ");
      }
      else if (this.regionDDLPinned) {
        this.regionDDLPinned.valuesAsString = this.selectedCount.regionCount.map(ele => { return ele.label }).join(", ");
      }
    }

    if ((this.filterMapping.PostCode != null && this.filterMapping.PostCode.length >= 4) || (this.postCodeIdsSelected && this.postCodeIdsSelected.length >= 4) ) {
      this.selectedCount.postcodeCount =  this.selectedCount.postcodeCount.length != this.postCodeIdsSelected.length ? this.postCodeIdsSelected : this.selectedCount.postcodeCount
      this.postCodeValueToShow = this.selectedCount.postcodeCount.length + " items selected";
      this.postCodeValuePinnedToShow = this.selectedCount.postcodeCount.length + " items selected";

    }
    else if (this.filterMapping.PostCode != null && this.filterMapping.PostCode.length < 4 && this.filterMapping.PostCode.length >= 1) {
      this.selectedCount.postcodeCount =  this.selectedCount.postcodeCount.length != this.postCodeIdsSelected.length ? this.postCodeIdsSelected : this.selectedCount.postcodeCount
      this.postCodeValueToShow = this.selectedCount.postcodeCount.map(ele => { return ele.PostCode }).join(", ");
      this.postCodeValuePinnedToShow = this.selectedCount.postcodeCount.map(ele => { return ele.PostCode }).join(", ");
    }

    if (this.filterMapping.GroupName != null && this.filterMapping.GroupName.length >= 4 || (this.postCodeGrpIdsSelected && this.postCodeGrpIdsSelected.length >= 4)) {
      this.selectedCount.groupPostcodeCount =  this.selectedCount.groupPostcodeCount.length != this.postCodeGrpIdsSelected.length ? this.postCodeGrpIdsSelected : this.selectedCount.groupPostcodeCount
      this.postCodeGrpValueToShow = this.selectedCount.groupPostcodeCount.length + " items selected";
      this.postCodeGrpValuePinnedToShow = this.selectedCount.groupPostcodeCount.length + " items selected";
    }
    else if (this.filterMapping.GroupName != null && this.filterMapping.GroupName.length < 4 && this.filterMapping.GroupName.length >= 1) {
      this.selectedCount.groupPostcodeCount =  this.selectedCount.groupPostcodeCount.length != this.postCodeGrpIdsSelected.length ? this.postCodeGrpIdsSelected : this.selectedCount.groupPostcodeCount
      this.postCodeGrpValueToShow = this.selectedCount.groupPostcodeCount.map(ele => { return ele.GroupName }).join(", ");
      this.postCodeGrpValuePinnedToShow = this.selectedCount.groupPostcodeCount.map(ele => { return ele.GroupName }).join(", ");
    }

    // for touchpoint
    if (this.filterMapping.TouchPointId == null || this.filterMapping.TouchPointId.length == 0) {
      this.tpSelectionlabel = "Select Touchpoint";
    } else if (this.filterMapping.TouchPointId.length >= 4) {
      this.tpSelectionlabel = this.selectedCount.touchpointCount.length + " items selected";
    } else if (this.filterMapping.TouchPointId.length < 4 && this.filterMapping.TouchPointId.length >= 1) {
      this.tpSelectionlabel = this.selectedCount.touchpointCount.map(ele => ele.label).join(", ");
    }
  }

  removeSelectedValue(id, fieldName) {
console.log('fieldName',fieldName);
    switch (fieldName) {
      case "fam": {
        var index = this.filterMapping.FAMId.indexOf(id);
        if (index > -1) {
          this.filterMapping.FAMId.splice(index, 1);
          this.SelectedFams(true);
          if (this.famDDL) {
            this.famDDL.updateLabel();
          } else if (this.famDDLPinned) {
            this.famDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "ba": {
        var index = this.filterMapping.BAId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BAId.splice(index, 1);
          this.SelectedBas(true);
          if (this.baDDL) {
            this.baDDL.updateLabel();
          } else if (this.baDDLPinned) {
            this.baDDLPinned.updateLabel();
          }
        }
        break;
      }

      case "pm": {
        var index = this.filterMapping.PMId.indexOf(id);
        if (index > -1) {
          this.filterMapping.PMId.splice(index, 1);
          this.SelectedPM(true);
          if (this.pmDDL) {
            this.pmDDL.updateLabel();
          } else if (this.pmDDLPinned) {
            this.pmDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "div": {
        var index = this.filterMapping.DIVId.indexOf(id);
        if (index > -1) {
          this.filterMapping.DIVId.splice(index, 1);
          this.SelectedDivs(true);
          if (this.divDDL) {
            this.divDDL.updateLabel();
          } else if (this.divDDLPinned) {
            this.divDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "bl": {
        var index = this.filterMapping.BLId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BLId.splice(index, 1);
          this.SelectedBls(true);
          if (this.blDDL) {
            this.blDDL.updateLabel();
          } else if (this.blDDLPinned) {
            this.blDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "country": {
        var index = this.filterMapping.CountryId.indexOf(id);
        if (index > -1) {
          this.filterMapping.CountryId.splice(index, 1);
          this.SelectedCountries(true);
          if (this.countryDDL) {
            this.countryDDL.updateLabel();
          } else if (this.countryDDLPinned) {
            this.countryDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "operationalCountry": {
        var index = this.filterMapping.OperationalCountryId.indexOf(id);
        if (index > -1) {
          this.filterMapping.OperationalCountryId.splice(index, 1);
          this.SelectedOperationalCountries(true);
          if (this.operationalCountryDDL) {
            this.operationalCountryDDL.updateLabel();
          } else if (this.operationalCountryDDLPinned) {
            this.operationalCountryDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "brand": {
        var index = this.filterMapping.BrandId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BrandId.splice(index, 1);
          this.SelectedBrands(true);
          if (this.brandDDL) {
            this.brandDDL.updateLabel();
          } else if (this.brandDDLPinned) {
            this.brandDDLPinned.updateLabel();
          }

        }
        break;
      }
      case "zone": {
        var index = this.filterMapping.ZoneId.indexOf(id);
        if (index > -1) {
          this.filterMapping.ZoneId.splice(index, 1);
          this.SelectedZones(true);
          if (this.zoneDDL) {
            this.zoneDDL.updateLabel();
          } else if (this.zoneDDLPinned) {
            this.zoneDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "branch": {
        var index = this.filterMapping.BranchId.indexOf(id);
        if (index > -1) {
          this.filterMapping.BranchId.splice(index, 1);
          this.SelectedBranchs(true);
          if (this.branchDDL) {
            this.branchDDL.updateLabel();
          } else if (this.branchDDLPinned) {
            this.branchDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "sales": {
        var index = this.filterMapping.SalesEngId.indexOf(id);
        if (index > -1) {
          this.filterMapping.SalesEngId.splice(index, 1);
          this.SelectedSalesEngs(true);
          if (this.salesDDL) {
            this.salesDDL.updateLabel();
          } else if (this.salesDDLPinned) {
            this.salesDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "service": {
        var index = this.filterMapping.ServiceTechId.indexOf(id);
        if (index > -1) {
          this.filterMapping.ServiceTechId.splice(index, 1);
          this.SelectedServiceTechs(true);
          if (this.serviceDDL) {
            this.serviceDDL.updateLabel();
          } else if (this.serviceDDLPinned) {
            this.serviceDDLPinned.updateLabel();
          }
        }
        break;
      }

      case "touchpoint": {
        var index = this.filterMapping.TouchPointId.indexOf(id);
        if (index > -1) {
          this.filterMapping.TouchPointId.splice(index, 1);
          this.SelectedTouchPoints(true);
          if (this.tpDDL) {
            this.tpDDL.updateLabel();
          } else if (this.tpDDLPinned) {
            this.tpDDLPinned.updateLabel();
          }
        }
        // this.updateTouchpointTreeLabel();
        break;
      }
      case "influencer": {
        var index = this.filterMapping.InfluencerId.indexOf(id);
        if (index > -1) {
          this.filterMapping.InfluencerId.splice(index, 1);
          this.reomveExtraIds(true);
          if (this.infDDL) {
            this.infDDL.updateLabel();
          } else if (this.infDDLPinned) {
            this.infDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "continent": {
        var index = this.filterMapping.ContinentId.indexOf(id);
        if (index > -1) {
          this.filterMapping.ContinentId.splice(index, 1);
          this.SelectedContinents(true);
          if (this.continentDDL) {
            this.continentDDL.updateLabel();
          } else if (this.continentDDLPinned) {
            this.continentDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "region": {
        var index = this.filterMapping.regionId.indexOf(id);
        if (index > -1) {
          this.filterMapping.regionId.splice(index, 1);
          this.SelectedRegions(true);
          if (this.regionDDL) {
            this.regionDDL.updateLabel();
          } else if (this.regionDDLPinned) {
            this.regionDDLPinned.updateLabel();
          }
        }
        break;
      }
      case "postcode": {
        var index = this.filterMapping.PostCode.indexOf(id);
        if (index > -1) {
          this.filterMapping.PostCode.splice(index, 1);
          this.postCodeIdsSelected.splice(index,1)
          this.onDone(1);
          this.applyfilterOnLabel();
          this.selectedPostalCode = this.postCodeIdsSelected.length == 0 ? '' :this.selectedPostalCode;
        }
        break;
      }
      case "groupPostcode": {
        var index = this.filterMapping.GroupName.indexOf(id);
        if (index > -1) {
          this.filterMapping.GroupName.splice(index, 1);
          this.postCodeGrpIdsSelected.splice(index,1)
          this.onDone(2);
          this.applyfilterOnLabel();
          this.selectedGrpPostalCode = this.postCodeGrpIdsSelected.length == 0 ? '' :this.selectedGrpPostalCode;
        }
        break;
      }
    }
  }


  private includes(container, value) {
    var returnValue = false;
    var pos = container.indexOf(value);
    if (pos >= 0) {
      returnValue = true;
    }
    return returnValue;
  }

  private changeDateFormat(data) {
    var splitDate = data.split('-');
    var month_names = ["Jan", "Feb", "Mar",
      "Apr", "May", "Jun",
      "Jul", "Aug", "Sep",
      "Oct", "Nov", "Dec"];
    var indexMonth = month_names.indexOf(splitDate[1]);
    return (indexMonth + 1) +
      "/" + splitDate[0] +
      "/" + splitDate[2];
  }

  private changeDateFormate(date) {

    // var month_names =["Jan","Feb","Mar",
    //                   "Apr","May","Jun",
    //                   "Jul","Aug","Sep",
    //                   "Oct","Nov","Dec"];

    // var day = date.getDate().toString().length <= 1? "0"+ date.getDate().toString(): date.getDate().toString();
    // var month_index = date.getMonth();
    // var year = date.getFullYear();

    // return "" + day + "-" + month_names[month_index] + "-" + year;


    // Try and parse the passed value.
    var momentDate = moment(date);
    return momentDate.format('DD-MMM-YYYY');


    // return (date.getMonth() + 1) +
    //   "/" + date.getDate() +
    //   "/" + date.getFullYear();
  }

  //severity: info/success/warn/error
  callErrorMsg(type, summary = '', detail = '') {
    this.successMsg = true;
    this.msgs = [];
    this.msgs.push({ severity: type, summary: summary, detail: detail });
    setTimeout(function () { document.getElementById('ClearMsg').click(); }, this.MessageTimeOut);
  }

  clearMessage() {
    this.msgs = [];
    this.successMsg = false;
  }
  // added below function in replacement of getDistinct 
 // parameters
 // data -array list from which unique list will be returned 
 //value- key for value
 //label- key for label
 //key - to check label or anyother key 
  private getDistinctByValue(data, value, label, key){
    let uniqueLists=[]; 
    let ids=[];
    if (key == "label") {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (element[label] != null) {
          if (ids.indexOf(element[label].trim().toLowerCase())==-1) { // removed map method due to this fillter is resposing slow 
              uniqueLists.push({ value: element[value], label: element[label] });  // directly pushing distinct  object  to uniquelist array
              ids.push(element[label].trim().toLowerCase());
          }
        }
      }
    } else { 
        data.forEach(element => {
          if ((ids.indexOf(element[value])==-1) && element[value] != null) {
                uniqueLists.push({ value: element[value], label: element[label] }); // directly pushing distinct  object  to uniquelist array
                ids.push(element[value]);
          }
        });
  }
    uniqueLists = uniqueLists.sort(function (a, b) {
      return a.label > b.label ? 1 : -1;
    })
    return uniqueLists
  }
  applyCasecadingOnList(field = '') {

  

    return new Promise(resolve => {
      
      setTimeout(() => {
        //added new method  'getDistinctByValue' removed getDistinct method and map method to resolve slowness of filter issue, as getDistinct method is very slow to respone 
        this.famData = this.getDistinctByValue(this.filterList('fam'), 'FAMID', 'FAMCode','value');
        this.baData= this.getDistinctByValue(this.filterList('ba'), 'BAID', 'BACode','value');
        this.pmData= this.getDistinctByValue(this.filterList('pm'), 'PMID', 'ProjectManagerName','value');
        this.divData= this.getDistinctByValue(this.filterList('div'), 'DivisionID', 'DivisionCode','value');
        this.blData= this.getDistinctByValue(this.filterList('bl'), 'BLID', 'BLName','value');
        this.brandData= this.getDistinctByValue(this.filterList('brand'), 'BrandID', 'BrandName','value');
        this.countryData= this.getDistinctByValue(this.filterList('country'), 'CountryID', 'CountryName','value');

        
        this.operationalCountryData= this.getDistinctByValue(this.filterList('operationalCountry'), 'CountryID', 'CountryName','value');

        
        this.countinentData= this.getDistinctByValue(this.filterList('continent'), 'ContinentID', 'ContinentName','value');

        this.regionData= this.getDistinctByValue(this.filterList('region'), 'RegionID', 'RegionName','value');

        
        this.zoneData= this.getDistinctByValue(this.filterList('zone'), 'ZoneCode', 'ZoneCode', "label");
        this.branchData= this.getDistinctByValue(this.filterList('branch'), 'BranchCode', 'BranchCode', "label");
        this.salesEngData= this.getDistinctByValue(this.filterList('sales').filter(x => { if (x.EngineerType == "Sales") { return x; } }), 'EngineerName', 'EngineerName', "label");
        this.serviceTechData= this.getDistinctByValue(this.filterList('service').filter(x => { if (x.EngineerType == "Service") { return x; } }), 'EngineerName', 'EngineerName', "label");
    

        this.touchPointData = this.formatTouchPointData(this.filterList('tp'));
        this.pouplateTreeSelection();
        
        this.removeFakeClass();
        setTimeout(() => {
          this.applyfilterOnLabel()
        }, 5);
        resolve({ Status: "success" });
      }, 5);
    });
  }

  filterList(fieldName): Array<any> {

    var badivfamblList = JSON.parse(JSON.stringify(this.filterService.dataOriginal));
    var brandList = JSON.parse(JSON.stringify(this.filterService.brandOriginal));
    var pmList = JSON.parse(JSON.stringify(this.filterService.pmOriginal));
    var zoneBranchList = JSON.parse(JSON.stringify(this.filterService.zoneBranchOriginal));
    var engTechList = JSON.parse(JSON.stringify(this.filterService.engineerAndTechnicianOriginal));
    var countryContinentList = JSON.parse(JSON.stringify(this.filterService.countryContinentOriginal));
    //var operationalCountryContinentList = (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length > 0) ? JSON.parse(JSON.stringify(this.filterService.operationalCountryContinentOriginal)) : [];
    var operationalCountryContinentList =  JSON.parse(JSON.stringify(this.filterService.operationalCountryContinentOriginal));
    var tpList = JSON.parse(JSON.stringify(this.filterService.touchPointsOriginal));

    var listToReturn = [];

    if (this.filterMapping.FAMId != null && this.filterMapping.FAMId.length > 0 && fieldName != 'fam') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.FAMId.indexOf(ele.FAMID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, true, badivfamblList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, true, badivfamblList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
    }

    if (this.filterMapping.BAId != null && this.filterMapping.BAId.length > 0 && fieldName != 'ba') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.BAId.indexOf(ele.BAID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, true, badivfamblList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, true, badivfamblList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
    }

    if (this.filterMapping.PMId != null && this.filterMapping.PMId.length > 0 && fieldName != 'pm') {
      pmList = pmList.filter(ele => {
        if (this.filterMapping.PMId.indexOf(ele.PMID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, pmList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, pmList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, true, pmList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, pmList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, true, pmList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, pmList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, pmList, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(false, false, true, pmList, tpList);
    }

    if (this.filterMapping.DIVId != null && this.filterMapping.DIVId.length > 0 && fieldName != 'div') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.DIVId.indexOf(ele.DivisionID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, true, badivfamblList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, true, badivfamblList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
    }

    if (this.filterMapping.BLId != null && this.filterMapping.BLId.length > 0 && fieldName != 'bl') {
      badivfamblList = badivfamblList.filter(ele => {
        if (this.filterMapping.BLId.indexOf(ele.BLID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, badivfamblList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, badivfamblList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, true, badivfamblList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, badivfamblList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, true, badivfamblList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, badivfamblList, operationalCountryContinentList);
      
      tpList = this.filterBasedOnEligibility(true, false, true, badivfamblList, tpList);
    }

    if (this.filterMapping.BrandId != null && this.filterMapping.BrandId.length > 0 && fieldName != 'brand') {
      brandList = brandList.filter(ele => {
        if (this.filterMapping.BrandId.indexOf(ele.BrandID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, brandList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, brandList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, true, brandList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, brandList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, true, brandList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, brandList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, brandList, operationalCountryContinentList);
      
      tpList = this.filterBasedOnEligibility(false, false, true, brandList, tpList);
    }

    if (this.filterMapping.CountryId != null && this.filterMapping.CountryId.length > 0 && fieldName != 'country') {
      countryContinentList = countryContinentList.filter(ele => {
        if (this.filterMapping.CountryId.indexOf(ele.CountryID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, countryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, countryContinentList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, false, countryContinentList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, countryContinentList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, false, countryContinentList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, operationalCountryContinentList);
      
      //tpList = this.filterBasedOnEligibility(false,false,false,countryContinentList, tpList);
    }

    if (this.filterMapping.OperationalCountryId != null && this.filterMapping.OperationalCountryId.length > 0 && fieldName != 'operationalCountry') {
      operationalCountryContinentList = operationalCountryContinentList.filter(ele => {
        if (this.filterMapping.OperationalCountryId.indexOf(ele.CountryID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, operationalCountryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, operationalCountryContinentList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, false, operationalCountryContinentList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, operationalCountryContinentList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, false, operationalCountryContinentList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, operationalCountryContinentList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, operationalCountryContinentList, operationalCountryContinentList);
      
      //tpList = this.filterBasedOnEligibility(false,false,false,countryContinentList, tpList);
    }
    

    if (this.filterMapping.ContinentId != null && this.filterMapping.ContinentId.length > 0 && fieldName != 'continent') {
      countryContinentList = countryContinentList.filter(ele => {
        if (this.filterMapping.ContinentId.indexOf(ele.ContinentID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, countryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, countryContinentList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, false, countryContinentList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, countryContinentList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, false, countryContinentList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, countryContinentList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, operationalCountryContinentList);
      

    }

    if (this.filterMapping.regionId != null && this.filterMapping.regionId.length > 0 && fieldName != 'region') {
      countryContinentList = countryContinentList.filter(ele => {
        if (this.filterMapping.regionId.indexOf(ele.RegionID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, false, countryContinentList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, false, countryContinentList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, false, countryContinentList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, false, countryContinentList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, false, countryContinentList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, countryContinentList, operationalCountryContinentList);
      


    }

    if (this.filterMapping.ZoneId != null && this.filterMapping.ZoneId.length > 0 && fieldName != 'zone') {
      zoneBranchList = zoneBranchList.filter(ele => {
        if (this.filterMapping.ZoneId.indexOf(ele.ZoneCode) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, zoneBranchList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, zoneBranchList, operationalCountryContinentList);
      
      tpList = this.filterBasedOnEligibility(true, false, true, zoneBranchList, tpList);
    }

    if (this.filterMapping.BranchId != null && this.filterMapping.BranchId.length > 0 && fieldName != 'branch') {
      zoneBranchList = zoneBranchList.filter(ele => {
        if (this.filterMapping.BranchId.indexOf(ele.BranchCode) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(true, true, true, zoneBranchList, zoneBranchList);
      pmList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, pmList);
      engTechList = this.filterBasedOnEligibility(false, true, true, zoneBranchList, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, zoneBranchList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, zoneBranchList, operationalCountryContinentList);
      
      tpList = this.filterBasedOnEligibility(true, false, true, zoneBranchList, tpList);
    }

    if (this.filterMapping.ServiceTechId != null && this.filterMapping.ServiceTechId.length > 0 && fieldName != 'service') {
      var techList = engTechList.filter(ele => {
        if (this.filterMapping.ServiceTechId.indexOf(ele.EngineerName) >= 0 && ele.EngineerType == "Service") {
          return ele;
        }
      });

      engTechList = this.filterBasedOnEligibility(false, true, true, techList, engTechList);

      engTechList = engTechList.filter(ele => {
        if (ele.EngineerType == "Sales" || (this.filterMapping.ServiceTechId.indexOf(ele.EngineerName) >= 0 && ele.EngineerType == "Service")) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, engTechList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, engTechList, brandList);
      pmList = this.filterBasedOnEligibility(false, true, true, engTechList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, engTechList, zoneBranchList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, engTechList, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, engTechList, operationalCountryContinentList);
      
      tpList = this.filterBasedOnEligibility(false, false, true, engTechList, tpList);

    }

    if (this.filterMapping.SalesEngId != null && this.filterMapping.SalesEngId.length > 0 && fieldName != 'sales') {
      var engList = engTechList.filter(ele => {
        if (this.filterMapping.SalesEngId.indexOf(ele.EngineerName) >= 0 && ele.EngineerType == "Sales") {
          return ele;
        }
      });

      engTechList = this.filterBasedOnEligibility(false, true, true, engList, engTechList);

      engTechList = engTechList.filter(ele => {
        if (ele.EngineerType == "Service" || (this.filterMapping.SalesEngId.indexOf(ele.EngineerName) >= 0 && ele.EngineerType == "Sales")) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(false, true, true, engTechList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, engTechList, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, engTechList, zoneBranchList);
      pmList = this.filterBasedOnEligibility(false, true, true, engTechList, pmList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, engTechList, countryContinentList);

      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, engTechList, operationalCountryContinentList);
      
      tpList = this.filterBasedOnEligibility(false, false, true, engTechList, tpList);
    }

    if (this.filterMapping.TouchPointId != null && this.filterMapping.TouchPointId.length > 0 && fieldName != 'tp') {
      tpList = tpList.filter(ele => {
        if (this.filterMapping.TouchPointId.indexOf(ele.TouchPointID) >= 0) {
          return ele;
        }
      });

      badivfamblList = this.filterBasedOnEligibility(true, false, true, tpList, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, false, true, tpList, brandList);
      pmList = this.filterBasedOnEligibility(false, false, true, engTechList, pmList);
      zoneBranchList = this.filterBasedOnEligibility(true, false, true, tpList, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, false, true, tpList, engTechList);
      //countryContinentList = this.filterBasedOnEligibility(false, true, false, tpList, countryContinentList); // Commented by Dwarika as no dircet relation with touchpoint.
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, engTechList, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(true, false, true, tpList, tpList);

    }
    if (this.filterMapping.PostCode != null && this.filterMapping.PostCode.length > 0 && fieldName != 'postcode') {

      badivfamblList = this.filterBasedOnEligibility(false, true, true, this.postCodeIdsSelected, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, this.postCodeIdsSelected, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, this.postCodeIdsSelected, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, true, this.postCodeIdsSelected, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, this.postCodeIdsSelected, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, this.postCodeIdsSelected, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(false, false, true, this.postCodeIdsSelected, tpList);
      if (fieldName == 'zone' || fieldName === 'branch') {
        let zoneBranchListFiltered = [];
        this.postCodeIdsSelected.map(x => {
          if (fieldName == 'zone') {
            let zoneList = zoneBranchList.filter(y => y.ZoneMasterID === x.ZoneId);
            if (zoneList && zoneList.length) {
              zoneBranchListFiltered = [...zoneList];
            }
          }
          if (fieldName == 'branch') {
            let branchList = zoneBranchList.filter(y => y.BranchMasterID === x.BranchID);
            if (branchList && branchList.length) {
              zoneBranchListFiltered = [...branchList];
            }
          }
        });
        zoneBranchList = [...zoneBranchListFiltered];
      }
    }
    if (this.filterMapping.GroupName != null && this.filterMapping.GroupName.length > 0 && fieldName != 'groupPostcode') {

      badivfamblList = this.filterBasedOnEligibility(false, true, true, this.postCodeGrpIdsSelected, badivfamblList);
      brandList = this.filterBasedOnEligibility(false, true, true, this.postCodeGrpIdsSelected, brandList);
      zoneBranchList = this.filterBasedOnEligibility(false, true, true, this.postCodeGrpIdsSelected, zoneBranchList);
      engTechList = this.filterBasedOnEligibility(false, true, true, this.postCodeGrpIdsSelected, engTechList);
      countryContinentList = this.filterBasedOnEligibility(false, true, false, this.postCodeGrpIdsSelected, countryContinentList);
      operationalCountryContinentList = this.filterBasedOnEligibility(false, true, false, this.postCodeGrpIdsSelected, operationalCountryContinentList);
      tpList = this.filterBasedOnEligibility(false, false, true, this.postCodeGrpIdsSelected, tpList);
      if (fieldName == 'zone' || fieldName === 'branch') {
        let zoneBranchListFiltered = [];
        this.postCodeGrpIdsSelected.map(x => {
          if (fieldName == 'zone') {
            let zoneList = zoneBranchList.filter(y => y.ZoneMasterID === x.ZoneId);
            if (zoneList && zoneList.length) {
              zoneBranchListFiltered = [...zoneList];
            }
          }
          if (fieldName == 'branch') {
            let branchList = zoneBranchList.filter(y => y.BranchMasterID === x.BranchID);
            if (branchList && branchList.length) {
              zoneBranchListFiltered = [...branchList];
            }
          }
        });
        zoneBranchList = [...zoneBranchListFiltered];
      }
    }

    if (fieldName == 'fam' || fieldName == 'ba' || fieldName == 'div' || fieldName == 'bl') {
      listToReturn = badivfamblList;
    }
    else if (fieldName == 'branch' || fieldName == 'zone') {
      listToReturn = zoneBranchList;
    }
    else if (fieldName == 'country') {
      listToReturn = countryContinentList;
    }
    else if (fieldName == 'operationalCountry' ) {  
      listToReturn = operationalCountryContinentList;
    }
    else if ( fieldName == 'continent' || fieldName == 'region' ) {
      listToReturn = countryContinentList.concat(operationalCountryContinentList);
    }
    else if (fieldName == 'sales' || fieldName == 'service') {
      listToReturn = engTechList;
    }
    else if (fieldName == 'brand') {
      listToReturn = brandList;
    }
    else if (fieldName == 'pm') {
      listToReturn = pmList;
    }
    else if (fieldName == 'tp') {
      listToReturn = tpList;
    }
    return listToReturn;
  }

  filterBasedOnEligibility(divEligible, famEligible, baEligible, fromList, toList, postCodeEligible?): Array<any> {
    if (divEligible) {
      var divs = [];
      for (var i = 0; i < fromList.length; i++) {
        if (fromList[i].DivisionID) {
          divs.push(fromList[i].DivisionID);
        } else if (fromList[i].DivID) {
          divs.push(fromList[i].DivID);
        }
      };//.map(ele => { return ele.DivisionID });
      toList = this.filterByDIV(toList, divs);
    }
    if (famEligible) {
      //var fams = fromList.map(ele => { return ele.FAMID });
      var fams = [];
      for (var i = 0; i < fromList.length; i++) {
        if (fromList[i].FAMID) {
          fams.push(fromList[i].FAMID);
        } else if (fromList[i].FamID) {
          fams.push(fromList[i].FamID);
        }
      };
      toList = this.filterByFAM(toList, fams);
    }
    if (baEligible) {
      //var bas = fromList.map(ele => { return ele.BAID });
      var bas = [];
      for (var i = 0; i < fromList.length; i++) {
        bas.push(fromList[i].BAID);
      };
      toList = this.filterByBA(toList, bas);
    }

    return toList
  }

  closeFilterPanel() {
    this.filterToggle = false;
    this.filterDateToggle = false;
  }

  @ViewChild('scrollPanel') private myScrollContainer;
  // @ViewChild('scroll') scroll
  hideScroll: boolean = false;
  scrollPos(event) {
    this.hideScroll = true
    //this.myScrollContainer.contentViewChild.nativeElement.scrollTop+=200
  }

  GlobalDateChanged(flag = true) {
    this.filterService.GlobalDateChanged = flag;
  }

  filterPostCodes(event: any = {}, postCodes = []) {
    let reqData: any = {};
    reqData.pageNo = 1;
    reqData.pageSize = 100;
    this.showload = true;
    let _this=this;
    if (event && event.query) {
      this.selectedPostalCode = event.query
    }
    if(!this.selectedPostalCode) {
      event = '';
    } else{
      event = this.selectedPostalCode
    }
    reqData = { ...reqData, ...this.setPostCodeFilter({ ...this.filterMapping }, event) };
    forkJoin([this.filterService.getPostcodeList(reqData,'B')
      .pipe(catchError(err => of(null))),
    this.filterService.getGroupedPostcodeList(reqData,'B')
      .pipe(catchError(err => of(null)))]).subscribe(
        ([data1, data2]: any[]) => {
          let postcodeData = [];
          if (data1 && data1.Status == 'Success' && data1.Response && data1.Response.Collection) {
            postcodeData = [...data1.Response.Collection];
          }
          if (data2 && data2.Status == 'Success' && data2.Response && data2.Response.Collection) {
            postcodeData = [...postcodeData, ...data2.Response.Collection];
          }
          if (_this.filterMapping.GroupName && _this.filterMapping.GroupName.length > 0) {
            postcodeData = postcodeData.filter(x => x.GroupName && _this.filterMapping.GroupName.includes(x.GroupName));
          }
          if (postCodes && postCodes.length) {
            this.postCodeIdsSelected = postcodeData.filter(x => postCodes.includes(x.PostCode));
          } 
          // this.postcodeData = [...postcodeData];
          this.postcodeData = _.compact(_.uniq(_.map(this.postcodeData, x => x.PostCode))) || [];
          if(event) {
            this.postcodeDataCopyFilter = [...postcodeData];
            this.postcodeData = [...postcodeData];
          }else {
            this.postcodeDataCopy = [...postcodeData]
            this.postcodeData = [...postcodeData];
          }

          this.showload =false
        },
        (error) => {
          this.postcodeData = [];
          this.showload =false
        }
      );
  }
  handleDropdownClickChange(data, index) {
    this.filterMapping.PostCode = _.compact(_.uniq(_.map(this.postCodeIdsSelected, x => x.PostCode))) || [];
    
    this.showBusyIndicator = true;
    
    this.applyCasecadingOnList().then(res => {                      
      this.showBusyIndicator = false;
    });
  }

  filterGroupPostCodes(event: any = {}, groupNames = []) {
    let reqData: any = {};
    reqData.pageNo = 1;
    reqData.pageSize = 100;
    let _this=this;
    if (event && event.query) {
      this.selectedGrpPostalCode = event.query;
    }
    if(!this.selectedGrpPostalCode) {
      event = '';
    }else{
      event = this.selectedGrpPostalCode
    }
    this.showGrpload = true;
    reqData = { ...reqData, ...this.setPostCodeFilter({ ...this.filterMapping }, event) };
    this.filterService.getGroupedPostcodeList(reqData,'B').subscribe(
      (data: any) => {
        let groupPostcodeData = [];
        if (data && data.Status == 'Success' && data.Response && data.Response.Collection) {
          groupPostcodeData = [...data.Response.Collection];
        }
        if (this.filterMapping.PostCode && this.filterMapping.PostCode.length) {
          groupPostcodeData = groupPostcodeData.filter(x => x.PostCode && this.filterMapping.PostCode.includes(x.PostCode));
        }
        if (groupNames && groupNames.length) {
          _this.groupPostCodeIdsSelected = _.uniqBy(groupPostcodeData.filter(x => groupNames.includes(x.GroupName)), 'GroupName');
          _this.postCodeGrpIdsSelected=  _this.groupPostCodeIdsSelected
          let data = groupPostcodeData.filter(x => groupNames.includes(x.GroupName))
          if(data.length > 0 && (!_this.groupPostCodeIdsSelected || _this.groupPostCodeIdsSelected.length<=0 || _this.groupPostCodeIdsSelected==undefined)) {
            _this.groupPostCodeIdsSelected = groupPostcodeData[0]
          }
        }
        if(event) {
          this.postcodeGrpDataCopyFilter =  _.uniqBy(groupPostcodeData, 'GroupName');
          this.groupPostcodeData =  _.uniqBy(groupPostcodeData, 'GroupName');
          this.nonUniquePostalCode = groupPostcodeData
        }else {
          this.postcodeGrpDataCopy =  _.uniqBy(groupPostcodeData, 'GroupName');
          this.groupPostcodeData =  _.uniqBy(groupPostcodeData, 'GroupName');
          this.nonUniquePostalCode = groupPostcodeData
        }
        this.showGrpload = false;
        // this.groupPostcodeData = _.uniqBy(groupPostcodeData, 'GroupName');
        this.applyCasecadingOnList('groupPostCode').then(ele => {
          this.setPinnedFilterValue();
          this.showBusyIndicator = false;
        });
      },
      (error) => {
        this.groupPostcodeData = [];
        this.showGrpload = false;
      }
    );
  }
  setPostCodeFilter(data: any = {}, query) {
    if (!(this.filterMapping.FAMId && this.filterMapping.FAMId.length)) {
      if ((this.filterMapping.CountryId && this.filterMapping.CountryId.length) ||
        (this.filterMapping.regionId && this.filterMapping.regionId.length) ||
        (this.filterMapping.BranchID && this.filterMapping.BranchID.length) ||
        (this.filterMapping.ZoneId && this.filterMapping.ZoneId.length)) {
        data.FAMId = _.compact(_.uniq(_.map(this.famData, x => x.value))) || [];
      }
    }
    /* if (!(this.filterMapping.BAId && this.filterMapping.BAId.length)) {
      if ((this.filterMapping.BranchID && this.filterMapping.BranchID.length) ||
        (this.filterMapping.ZoneId && this.filterMapping.ZoneId.length)) {
        data.BAId = _.compact(_.uniq(_.map(this.baData, x => x.value))) || [];
      }
    }
    if (!(this.filterMapping.DIVId && this.filterMapping.DIVId.length)) {
      if ((this.filterMapping.BranchID && this.filterMapping.BranchID.length) ||
        (this.filterMapping.ZoneId && this.filterMapping.ZoneId.length)) {
        data.DIVId = _.compact(_.uniq(_.map(this.divData, x => x.value))) || [];
      }
    } */
    let zoneIds = [...this.filterMapping.ZoneId] || [];
    let branchIds = [...this.filterMapping.BranchId] || [];
    if (this.filterService.zoneBranchOriginal && this.filterService.zoneBranchOriginal.length) {
      if (this.filterMapping.ZoneId && this.filterMapping.ZoneId.length) {
        let zonemasterList = _.map(this.filterService.zoneBranchOriginal.filter(x => this.filterMapping.ZoneId.includes(x.ZoneCode)), y => y.ZoneMasterID);
        zoneIds = _.compact(_.uniq(zonemasterList)) || [];
      }
      if (this.filterMapping.BranchId && this.filterMapping.BranchId.length) {
        let branchmasterList = _.map(this.filterService.zoneBranchOriginal.filter(x => this.filterMapping.BranchId.includes(x.BranchCode)), y => y.BranchMasterID);
        branchIds = _.compact(_.uniq(branchmasterList)) || [];
      }
    }
    return {
      BAID: data.BAId.join('|') || '',
      BranchID: branchIds.join('|') || '',
      BrandID: data.BrandId.join('|') || '',
      DivID: data.DIVId.join('|') || '',
      FamID: data.FAMId.join('|') || '',
      ZoneId: zoneIds.join('|') || '',
      PlainText: query || '',
      SortField: null,
      SortType: 0
    }
  }
  getInitialLoadDataForPostCodes(type = 'ungrouped') {
    if (type === 'grouped') {
      if (this.filterMapping.PostCode && this.filterMapping.PostCode.length) {
        this.filterPostCodes({ query: this.filterMapping.PostCode.join('|') }, this.filterMapping.PostCode);
      }
    } else {
      if (this.filterMapping.GroupName && this.filterMapping.GroupName.length) {
        this.filterGroupPostCodes({ query: this.filterMapping.GroupName.join('|') }, this.filterMapping.GroupName);
      } else {
        this.applyCasecadingOnList().then(ele => {
          this.setPinnedFilterValue();
          this.showBusyIndicator = false;
        });
      }
    }
  }
  showDrpdwn(){    
      this.status = !this.status;
      this.groupDrpdwnstatus = false
      if(this.status == true) {
        if(this.selectedPostalCode) {
          this.postcodeData = [];
          this.selectedPostalCode = '';
          const inputElement = document.getElementById('myInput') as HTMLInputElement;
          if (inputElement) {
            inputElement.value = '';
          }
        }
        // if(this.postcodeData.length == 0) {
          // if(this.postcodeDataCopy.length>0) {
          //   this.postcodeData = this.postcodeDataCopy;
          // } else
          let query = '';
          // if(this.postCodeGrpIdsSelected.length > 0) {
          //   this.nonUniquePostalCode.forEach(element => {
          //     let checkIsExist = this.postCodeGrpIdsSelected.filter(data=>data.GroupName == element.GroupName)
          //     if(checkIsExist)
          //     query = query ? query+ "|"+ element.PostCode : element.PostCode;
          //   });
          // }
          // this.selectedPostalCode = query;
            this.filterPostCodes()
        // }
        this.OuterDropdown()
      }    
  }
  onKey(event) {
    if(event && event.target && (event.target.value.length >=3 || event.target.value.length ==0)) {
      this.postcodeChcked = false;
      this.selectedPostalCode = event.target.value;
      this.filterPostCodes(event.target.value) 
    }
  }
  onGrpKey(event) {
    if(event && event.target && (event.target.value.length >=3 || event.target.value.length ==0)) {
      this.postcodeGrpChcked = false;
      this.selectedGrpPostalCode = event.target.value;
      this.filterGroupPostCodes(event.target.value) 
    }
  }
  showgroupDrpdwn(){ 
    this.status = false
    this.groupDrpdwnstatus = !this.groupDrpdwnstatus;
      if(this.groupDrpdwnstatus == true) {
        if(this.selectedGrpPostalCode) {
          this.groupPostcodeData = [];
          this.selectedPostalCode = '';
          const inputElement = document.getElementById('myInputGrp') as HTMLInputElement;
          if (inputElement) {
            inputElement.value = '';
          }
        }
        // if(this.groupPostcodeData.length == 0) {
          // if(this.postcodeGrpDataCopy.length>0) {
          //   this.groupPostcodeData = this.postcodeGrpDataCopy;
          // } else
            // let query = this.filterMapping.PostCode.length > 0 ? this.filterMapping.PostCode.join("|") : '';
            // this.selectedGrpPostalCode = query;
            this.filterGroupPostCodes()
        // }
        this.OuterDropdown()
      } 
  }
  isItemSelecetd(postCode) {
    let isExistIndex = this.postCodeIdsSelected.findIndex(x=>x.PostCode == postCode.PostCode);
    if(isExistIndex >-1) {
      return 'ItemSelected'
    } else {
      return '';
    }
  }
  isItemGrpSelecetd(postCode) {
    let isExistIndex = this.postCodeGrpIdsSelected.findIndex(x=>x.PostCode == postCode.PostCode);
    if(isExistIndex > -1) {
      return 'ItemSelected'
    } else {
      return '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: any): void {
    let el = document.getElementById('postcodeDataholder');
    let el2 = document.getElementById('postcodeDataholderGroup');
    let clickedInsideEl1 = el?el.contains(event.target) :false;
    let clickedInsideEl2 = el2? el2.contains(event.target):false;

    // Check if the click target is not within the list or its descendants
    const clickedInside = this.el.nativeElement.contains(event.target);
    if((el && el.id !== 'postcodeDataholder') || !clickedInside || (!clickedInsideEl1 && !clickedInsideEl2)) {
      if(this.status || this.groupDrpdwnstatus) {
        this.showBusyIndicator = true;
        this.applyCasecadingOnList().then(res => {
          this.showBusyIndicator = false;
        });
      }
      this.status = false;
      this.groupDrpdwnstatus =  false;
    }
  }

  OuterDropdown() {
    let el = document.getElementById('postcodeDataholder');
    let el2 = document.getElementById('postcodeDataholderGroup');
  }
  removeTitle(){
    let elemList = Array.from(document.querySelectorAll('.ui-multiselect-label-container'));   
    elemList.forEach((elem)=>{
      elem.setAttribute('title', '');
    });    
  }
}
