import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/api';
import { UserProfileComponent } from '../../../../app/components/profile/user-profile/user-profile.component';
import { HeaderService } from 'src/app/shared/services/header.service';
import { environment } from 'src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { authConfig } from 'src/app/oauth2.config';
import { AuthService } from '../../services/auth-service.service';
@Component({
  selector: 'app-topmenu',
  templateUrl: './topmenu.component.html',
  styleUrls: ['./topmenu.component.scss'],
})
export class TopmenuComponent implements OnInit {
  private imagepath = environment.imagepath;
  @Input()
  displayName: string;
  @Input()
  profileimagurl: string;

  displayName1: string;
  profileimagurl1: string;
  changePasswordUrl =
    environment.changePasswordUrl;
  constructor(
    public router: Router,
    public dialogService: DialogService,
    public headerService: HeaderService,
    private authService: AuthService,
    private oauthService: OAuthService,
  ) {}

  // ngOnChanges()
  // {
  //   this.headerService.displayName.subscribe(data => {
  //     this.displayName1 = data;
  //   });
  //   this.headerService.profileimagurl.subscribe(data => {
  //     this.profileimagurl1 = data;
  //   });
  // }
  userProfile = localStorage.getItem('userProfile');
  userProfileonLoad = localStorage.getItem('userProfileonLoad');

  ngOnInit() {
    this.displayName1 = '';
    this.profileimagurl1 = '';

    // if (this.userProfile != null) {

    // } else {
    this.headerService.displayName.subscribe((data) => {
      this.displayName1 = data;
    });
    this.headerService.profileimagurl.subscribe((data) => {
      this.profileimagurl1 = data;
    });

    if (this.displayName1 == '' || this.profileimagurl1 == '') {
      const userProfileInfo = JSON.parse(this.userProfile);

      if (userProfileInfo != null) {
        this.displayName =
          userProfileInfo.FirstName + ' ' + userProfileInfo.LastName;
        this.profileimagurl = userProfileInfo.PhotoURL;

        this.displayName1 = this.displayName;
        this.profileimagurl1 = this.profileimagurl;
      }

      const userProfileonLoad = JSON.parse(this.userProfileonLoad);

      if (userProfileonLoad != null) {
        this.displayName = userProfileonLoad.displayName;
        this.profileimagurl = userProfileonLoad.PhotoURL;

        this.displayName1 = this.displayName;
        this.profileimagurl1 = this.profileimagurl;
      }
    }
    // }
  }
  clearStorage() {

    this.authService.logout();
  }

  showModal() {
    const ref = this.dialogService.open(UserProfileComponent, {
      data: {
        isMyProfile: true,
      },
      header: 'MY PROFILE',
      width: '70%',
      height: '70% !important',
      baseZIndex: 10000
    });

    ref.onClose.subscribe(() => {
      //alert('No need to reload')
      // alert('yooooooooooooooo')
    });
  }
}
