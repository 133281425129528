import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { ForgotPasswordConfirmationComponent } from './forgot-password-confirmation/forgot-password-confirmation.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LockoutComponent } from './lockout/lockout.component';
import { LogoutComponent } from './logout/logout.component';
import { StatusMessageComponent } from './status-message/status-message.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { LoginWithRecoveryCodeComponent } from './login-with-recovery-code/login-with-recovery-code.component';
import { LoginWithTwofaComponent } from './login-with-twofa/login-with-twofa.component';
import { PasswordChangedSuccessComponent } from './password-changed-success/password-changed-success.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordConfirmationComponent } from './reset-password-confirmation/reset-password-confirmation.component';
import { ResetPasswordSuccessComponent } from './reset-password-success/reset-password-success.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { SignedOutComponent } from './signed-out/signed-out.component';
import { AuthenticationComponent } from './authentication.component';

export const routes: Routes = [
    { path: '',
        component: AuthenticationComponent,
        children: [
        { path: 'change-password', component: ChangePasswordComponent },
        // { path: 'confirm-email', component: ConfirmEmailComponent },
        // { path: 'exiernal-login', component: ExternalLoginComponent },
        { path: 'forgot-password', component: ForgotPasswordComponent },
        { path: 'forgot-password-confirmation', component: ForgotPasswordConfirmationComponent },
        { path: 'lockout', component: LockoutComponent },
        // { path: 'logged-out', component: LoggedOutComponent },
        { path: 'login', component: LoginComponent },
        // { path: 'login-with-recovery-code', component: LoginWithRecoveryCodeComponent },
        // { path: 'login-with-twofa', component: LoginWithTwofaComponent },
        { path: 'logout', component: LogoutComponent },
        { path: 'password-changed-success', component: PasswordChangedSuccessComponent },
        // { path: 'register', component: RegisterComponent },
        { path: 'reset-password', component: ResetPasswordComponent },
        // { path: 'reset-password-confirmation', component: ResetPasswordConfirmationComponent },
        { path: 'reset-password-success', component: ResetPasswordSuccessComponent },
        // { path: 'set-password', component: SetPasswordComponent },
         { path: 'signed-out', component: SignedOutComponent },
        // { path: 'status-message', component: StatusMessageComponent },
        ],
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class AuthenticationRoutingModule { }