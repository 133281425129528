import { Component, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {
  CustomerSupportEmailID : any;
  page: any;
  defaultView: any
  defaultUrl: any
  constructor(private sharedService: SharedService,private headerService: HeaderService,) { }

  async ngOnInit() {
    if(localStorage.getItem('views') && JSON.parse(localStorage.getItem('views')).length > 0) {
      this.defaultView = JSON.parse(localStorage.getItem('views'))
    } else {
      await this.headerService.getDefaultDashboardView().then(res => {
        if(res && res.Response)
          this.defaultView = res.Response
          localStorage.setItem('views',JSON.stringify(this.defaultView))
      })
    }
    if(document.cookie.indexOf("LandingPage")!=-1) {
      this.defaultUrl = this.getCookie('LandingPage')
    } else {
      var userInfo = JSON.parse(localStorage.getItem('userProfileonLoad'));
      this.defaultUrl = userInfo.defaultViewUrl;
    }
    if(this.defaultView) {
      this.defaultView.forEach(element => {
        if(element.ScreenKey ===this.defaultUrl){
          this.page = element.ScreenName
        }
      })
    }
    if(!this.page.includes('Dashboard') && !this.page.includes('Page') && !this.page.includes('View')) {
      this.page = this.page + ' View'
    }
    this.CustomerSupportEmailID = this.sharedService.configuration.CustomerSupportEmailID;
  }

  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

}
