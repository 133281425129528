import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { FIELDS } from './shared/constants/FIELDS';
import { AuthService } from './shared/services/auth-service.service';
import { StorageService } from './shared/services/storage.service';
import { AppInitService } from './shared/services/app-init-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showMainLayout: boolean = true;
  constructor(
    public router: Router,
    public authService: AuthService,
    public storageService: StorageService,
    private appInitService: AppInitService
  ) {

  }

  async ngOnInit() {
    const current_url = window.location.pathname;
    if(!(localStorage.getItem(FIELDS.ACCESS_TOKEN)) && (!current_url.includes('auth'))){
      this.router.navigate(['/auth/login']);
    }else{
      const tokenRemainingTime = this.authService.getTokenRemainingTime();
      if(tokenRemainingTime<=0){
        const refreshToken = this.storageService.getData('refresh_token');
        if(refreshToken){
          await this.authService.refreshToken();
          // Notify that AppComponent has finished its initialization
          this.appInitService.markAppInitComplete();
        }else{
          this.router.navigate(['/auth/login']);
        }
      }else{
        // Notify that AppComponent has finished its initialization
        this.appInitService.markAppInitComplete();
      }
    }
  }
  
}
