import { Injectable, Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import * as Rx from 'rxjs';
import { LostbusinessCustomerService } from 'src/app/components/dashboard/service/lostbusiness-customer.service';
import { FilterService } from 'src/app/shared/services/filter.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public errodatacount = 0;
  public selectLiElement = new Subject<any>();
  public displayName = new Subject<any>();
  public profileimagurl = new Subject<any>();

  public LandingPageSubject = new Rx.BehaviorSubject<string>('TD');
  public LandingPageCode = 'TD';

  private serviceUrl = this.sharedService.configuration.baseUrl;

  public CompPeriodStartDate: any;
  public CompPeriodEndDate: any;

  constructor(private http: HttpClient
    , private sharedService: SharedService
    , private lostbusinessCustomerService: LostbusinessCustomerService
    , private filterService: FilterService) { }

  responseRateExportExcel(parameter) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/ExportToExcel', parameter, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  getDefaultDashboardView() {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.get(serviceUrl + 'api/UserAccess/GetDefaultScreen')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  GetDefaultScreen() {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.get(serviceUrl + 'api/CTLSDashboard/GetCTLSDashboardDefaultView')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  saveDefaultDashboardView(view) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(serviceUrl + 'api/CTLSDashboard/SaveDefaultView', { DefaultView: view })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  GetErrorCount() {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.get(environment.baseUrlDashboard + '/Dashboard/ErrorCount')
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  SaveDefaultLandingPage(code) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/UpdateDefaultDashboard', { 'LandingPage': code })
      .toPromise()
      .then((res: any) => {
        return res;
      });
  }

  createChartExports(filterObj: any | null): Rx.Observable<any> {
    // createChartExports(filterObj: any | null): boolean {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/ChartExporttoPptx', filterObj);
  }

  saveImagesForExport(images: any[]) {
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/SaveImagesForExport', images);
  }

  // for lost business
  public getLBCountlist = (): Rx.Observable<any> => {
    let lostFilterObjClone = _.cloneDeep(this.lostbusinessCustomerService.filterObj1);
    if (lostFilterObjClone.PeriodType != null && lostFilterObjClone.PeriodType != '') {
      // Change comparasion CompPeriodStartDate and CompPeriodEndDate
      this.calculateStartAndEndDate(lostFilterObjClone.PeriodType);
    } else {
      this.CompPeriodStartDate = lostFilterObjClone.CompPeriodStartDate;
      this.CompPeriodEndDate = lostFilterObjClone.CompPeriodEndDate;
    }

    const lostFilterObj = {
      'StartDate': null,
      'EndDate': null,
      'PeriodType': lostFilterObjClone.PeriodType,
      'BACode': this.filterService.filterObj.BACode,
      'FAMCode': this.filterService.filterObj.FAMCode,
      'DivCode': this.filterService.filterObj.DivCode,
      'BLCode': this.filterService.filterObj.BLCode,
      'Country': this.filterService.filterObj.Country,
      'BrandName': this.filterService.filterObj.BrandName,
      'SalesEngineer': this.filterService.filterObj.SalesEngineer,
      'Touchpoint': this.filterService.filterObj.Touchpoint,
      'Influencer': this.filterService.filterObj.Influencer,
      'ServiceTechnician': this.filterService.filterObj.ServiceTechnician,
      'Continent': this.filterService.filterObj.Continent,
      'Region': this.filterService.filterObj.Region,
      'Zone': this.filterService.filterObj.Zone,
      'Branch': this.filterService.filterObj.Branch,
      'PinnedField': this.filterService.filterObj.PinnedField,
      'startBasePeriod': lostFilterObjClone.startBasePeriod,
      'endBasePeriod': lostFilterObjClone.endBasePeriod,
      'CompPeriodStartDate': lostFilterObjClone.CompPeriodStartDate,
      'CompPeriodEndDate': lostFilterObjClone.CompPeriodEndDate,
      'FilterBy': lostFilterObjClone.FilterBy,
      'FilterString': lostFilterObjClone.FilterString,
      'AccountNo': lostFilterObjClone.AccountNo,
      'ViewedBy': null
    };
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/LostBusiness/Sheetcounts', lostFilterObj);
  }

  lostBusinessExportExcel(parameter) {
    let serviceUrl = this.sharedService.configuration.baseUrl;
    return this.http.post(environment.baseUrlDashboard + '/Dashboard/ExportToExcel', parameter, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }

  // for date period selection
  calculateStartAndEndDate(key) {
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = date;

    if (key == 'Q') {
      var quarter = Math.floor((date.getMonth() + 3) / 3);
      if (quarter == 1) {
        firstDay = new Date(y, 0, 1);
        lastDay = new Date(y, 2, 31);
      } else if (quarter == 2) {
        firstDay = new Date(y, 3, 1);
        lastDay = new Date(y, 5, 30);
      }
      else if (quarter == 3) {
        firstDay = new Date(y, 6, 1);
        lastDay = new Date(y, 8, 30);
      }
      else if (quarter == 4) {
        firstDay = new Date(y, 9, 1);
        lastDay = new Date(y, 11, 31);
      }
    }
    if (key == 'H') {
      var hy = Math.floor((date.getMonth() + 6) / 6);
      if (hy == 1) {
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, 5, 30);
      }
      else if (hy == 2) {
        var firstDay = new Date(y, 6, 1);
        var lastDay = new Date(y, 11, 31);
      }

    }
    if (key == 'Y') {
      var firstDay = new Date(y, 0, 1);
      var lastDay = new Date(y, 11, 31);
    }
    this.CompPeriodStartDate = this.changeDateFormate(firstDay);
    this.CompPeriodEndDate = this.changeDateFormate(lastDay);
  }

  public changeDateFormate(date) {
    // Try and parse the passed value.
    var momentDate = moment(date);
    return momentDate.format('DD-MMM-YYYY');
  }

}
