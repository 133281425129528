import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { StartupComponent } from './startup/startup.component';
import { SigninOidcComponent } from './signin-oidc/signin-oidc.component';
import { UserProfileComponent } from './components/profile/user-profile/user-profile.component';
import { AccessDeniedComponent } from './shared/components/Errors/access-denied/access-denied.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      { path: 'user-profile', component: UserProfileComponent, data: { title: 'User Profile' } },
      { path: 'error/accessdenied', component: AccessDeniedComponent, data: { title: 'Error/Accessdenied' } },
      {
        path: 'ctls',
        loadChildren: './Modules/ctls/ctls.module#CtlsModule',
      },
      {
        path: 'falcon',
        loadChildren: './Modules/falcon/falcon.module#FalconModule',
      },
      {
        path: 'currency-master',
        loadChildren: './Modules/currency-master/currency-master.module#CurrencyMasterModule',
      },
      {
        path: 'customer-master',
        loadChildren: './Modules/customer-master/customer-master.module#CustomerMasterModule',
      },
      {
        path: 'distributor-master',
        loadChildren: './Modules/distributor-master/distributor-master.module#DistributorMasterModule',
      },
      {
        path: 'revenue-report',
        loadChildren: './Modules/revenue-report/revenue-report.module#RevenueReportModule',
      },
      {
        path: 'relationship-survey',
        loadChildren: './Modules/relationship-survey/relationship-survey.module#RelationshipSurveyModule',
      },
      {
        path: 'Distributor_Survey',
        loadChildren: './Modules/Distributor_Survey/distributor-survey.module#DistributorSurveyModule',
      },
      //Newly added on 06-06-2023//
      {
        path: 'dashboard',
        loadChildren: './components/dashboard/dashboard.module#DashboardModule',
      },
      //Newly added on 16-06-2023//
      {
        path: 'falcon-setup',
        loadChildren: './components/falcon-setup/falcon-setup.module#FalconSetupModule',
      },
      //Newly added on 19-06-2023
      {
        path: 'fam-management',
        loadChildren: './components/fam-management/fam-management.module#FamManagementModule',
      },
      //Newly added on 20-06-2023
      {
        path: 'surveys',
        loadChildren: './components/surveys/surveys.module#SurveysModule',
      },
    ]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: './Modules/authentication/authentication.module#AuthenticationModule',
  },
  {path:'start-up',component :StartupComponent},
  {path:'signin-oidc',component :SigninOidcComponent},
  { path: '**', redirectTo: '' } // Wildcard route to handle undefined routes
  // Add other routes if necessary
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
