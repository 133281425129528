import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  private appInitComplete = new BehaviorSubject<boolean>(false);

  // Observable to let other components subscribe to the status
  appInitComplete$ = this.appInitComplete.asObservable();

  // Method to update the status
  markAppInitComplete() {
    this.appInitComplete.next(true);
  }
}
