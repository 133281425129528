import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RevenueReportService {

  constructor(private httpClient: HttpClient) { }

  getRevenueReportList(parameters) {
    return this.httpClient.post(environment.baseUrlMDM + '/RevenueMaster/GetRevenueMasterList', parameters)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  saveAsDefaultView(parameters) {
    const serviceUrl = environment.baseUrl;
    console.log('serviceUrl' + serviceUrl);
    return this.httpClient.post(environment.baseUrlMDM + '/RevenueMaster/SaveUserPreferences/' + parameters,null)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  exportDataToExcel(parameters) {
    const serviceUrl = environment.baseUrl;
    console.log('serviceUrl' + serviceUrl);
    return this.httpClient.post(environment.baseUrlMDM + '/RevenueMaster/ExportCompanyRevenue', parameters, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  getCurrencyMasterList(parameters) {
    return this.httpClient.post(environment.baseUrlMDM + '/RevenueMaster/GetCurrencyMasterList', parameters)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  GetModifyColumns(dataObj) {
    return this.httpClient.post(environment.baseUrlMDM + '/MDM/Grid/GetModifyColumns', dataObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  GetUserPreference() {    
    return this.httpClient.get(environment.baseUrlMDM + '/RevenueMaster/GetUserPreferences')
      .toPromise()
      .then((res: any) => res)
      .then(data => data);      
  }

  SaveModifyColumns(dataObj) {
    return this.httpClient.post(environment.baseUrlMDM + '/MDM/Grid/SaveModifyColumns', dataObj)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  uploadFile(file) {
    return this.httpClient.post(environment.baseUrlMDM + '/RevenueMaster/UploadRevenueMasterFileForProcessing', file)
      .toPromise()
      .then((res: any) => res)
      .then(data => data);
  }

  downloadTemplate(filepath) {
    filepath = 'RevenueReport-Add.xlsx';
    return this.httpClient.get(environment.baseUrlMDM + '/DownloadEventFile/DownloadCloneTemplateFileFromBlob/' + filepath, { responseType: 'blob' })
      .toPromise()
      .then((res: any) => res)
      .then(data => {
        return data;
      });
  }
}
