

export const FIELDS = Object.freeze({
    USER_DETAILS: 'userDetails',
    ACCESS_TOKEN: 'access_token',
    SELECTED_SCREEN: 'seclectedScreen'
  });

  export const GraphApi = Object.freeze({
    URL: {
      BASE: 'https://graph.microsoft.com',
      MY_PHOTO: 'https://graph.microsoft.com/v1.0/me/photos/240x240/$value',
    },
    PERMISSIONS: {
      USER_READ: 'User.Read',
    },
  });