import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss']
})
export class ExternalLoginComponent implements OnInit {
  title: string = "Register";
  loginProvider: string = "YourLoginProvider"; // Replace with actual login provider
  returnUrl: string = "/"; // Set to the appropriate return URL
  registerForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit(): void {
    // You can fetch login provider and return URL from a service or route if needed
  }

  onSubmit(): void {
    if (this.registerForm.valid) {
      const email = this.registerForm.value.email;
      // Call your registration service here
      console.log('Email:', email);
      // Redirect to returnUrl or handle the response
    }
  }
}
